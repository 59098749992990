import {
    PackagePayload,
    PackageType,
    UpdatePackagePayload,
} from '~/app/types/package';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const create_package = async (
    packagePayload: PackagePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/packages', {
            ...packagePayload,
            description: `['${packagePayload.description}']`,
            numberOfMonths:
                packagePayload.recurringStatus === 'false'
                    ? null
                    : packagePayload.numberOfMonths,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const update_package = async (
    selected: PackageType,
    packagePayload: UpdatePackagePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/packages/${selected.id}`, {
            name: packagePayload.name || selected.name,
            description: packagePayload.description
                ? `['${packagePayload.description}']`
                : '' || selected.description,
            price: packagePayload.price || selected.price,
            joining_fee: packagePayload.joining_fee || selected.joining_fee,
            categoryId: packagePayload.categoryId || selected.category.id,
            recurringStatus:
                packagePayload.recurringStatus || selected.recurringStatus,
            headline: packagePayload.headline || selected.headline,
            numberOfMonths:
                packagePayload.numberOfMonths || selected.numberOfMonths,
        } as PackagePayload);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const disable_package = async (
    selected: PackageType,
    packagePayload: string
): Promise<any> => {
    console.log(packagePayload);
    try {
        const request = await PRIVATE_API.patch(`/packages/${selected.id}`, {
            name: selected.name,
            description: selected.description,
            price: selected.price,
            joining_fee: selected.joining_fee,
            categoryId: selected.category.id,
            recurringStatus: selected.recurringStatus,
            numberOfMonths: selected.numberOfMonths,
            status: packagePayload,
        } as PackagePayload);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const get_packages = async (
    query?: string
): Promise<PackageType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/packages?${queryString(query)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_package = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/packages/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
