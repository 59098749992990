/* eslint-disable no-unused-vars */
import React, { createContext, ReactNode, useState } from 'react';
import { TransactionType } from '~/app/types';
import { PaginationType } from '~/app/types/pagination';

export const ExportContext = createContext<any>(null);

export type ExportContextType = {
    exportData: string;
    setExportData: (exportData: string) => void;
};

export const ExportContextProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [exportData, setExportData] = useState<string>('');
    return (
        <ExportContext.Provider
            value={{
                exportData,
                setExportData,
            }}
        >
            {children}
        </ExportContext.Provider>
    );
};
