/* eslint-disable no-unused-vars */
import React, { ReactNode, useEffect, useState } from 'react';
import { CardType } from '~/app/types';
import { LocationType } from '~/app/types/location';
import { PackageType } from '~/app/types/package';

export const CommonContext = React.createContext<any>(null);

export type CommonContextType = {
    packages: PackageType[];
    setPackages: (packages: PackageType[]) => void;
    locations: LocationType[];
    setLocations: (locations: LocationType[]) => void;
    cards: CardType[];
    setCards: (cards: CardType[]) => void;
    cardToUpdate: CardType;
    setCardToUpdate: (card: CardType) => void;
    addCardModalIsOpen: boolean;
    setAddCardModalIsOpen: (a: boolean) => void;
};

export const CommonContextProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [packages, setPackages] = useState<PackageType[]>([]);
    const [locations, setLocations] = useState<LocationType[]>([]);
    const [cards, setCards] = useState<CardType[]>([]);
    const [cardToUpdate, setCardToUpdate] = useState<CardType>();
    const [addCardModalIsOpen, setAddCardModalIsOpen] = useState(false);

    return (
        <CommonContext.Provider
            value={{
                packages,
                setPackages,
                locations,
                setLocations,
                cards,
                setCards,
                cardToUpdate,
                setCardToUpdate,
                addCardModalIsOpen,
                setAddCardModalIsOpen,
            }}
        >
            {children}
        </CommonContext.Provider>
    );
};
