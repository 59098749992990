import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { paginationString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const get_member_stats = async () => {
    try {
        const request = await PRIVATE_API.get('/subscription/unpaid-balance');
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const front_desk_get_user_stats = async (user_id: string) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/user/balance/${user_id}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const front_desk_get_user_transactions = async (
    user_id: string,
    pageNumber?: number,
    pageSize?: number
) => {
    try {
        const request = await PRIVATE_API.get(
            `/transactions/user/${user_id}?${paginationString(
                pageNumber ?? 0,
                pageSize ?? 10
            )}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_admin_stats = async (id: string | null) => {
    try {
        const request = await PRIVATE_API.get(id ? `/transactions/reports?packageId=${id}` : '/transactions/reports');
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
