import {
    AssignLocationPayload,
    LocationPayload,
    LocationType,
} from '~/app/types/location';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const create_location = async (
    locationPayload: LocationPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/locations', locationPayload);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const update_location = async (
    selected: LocationType,
    payload: LocationPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/locations/${selected.id}`, {
            name: payload.name || selected.name,
            district: payload.district || selected.district,
            address: payload.address || selected.address,
            street: payload.street || selected.street,
            contact: payload.contact || selected.contact,
            link: payload.link || selected.link
        } as LocationPayload);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_locations = async (
    query?: string
): Promise<LocationPayload[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/locations?${queryString(query)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_location = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/locations/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const assign_location = async (
    locationPayload: AssignLocationPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(
            `/packages/${locationPayload.package_id}/assign-location`,
            locationPayload.locationId
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
