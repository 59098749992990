import { TransactionType } from '~/app/types';
import { exportToCSV, format } from '~/core/utils';

export const exportTransaction = (transactionData: TransactionType[]): string =>
    exportToCSV<TransactionType>(
        [
            {
                label: 'Date Time',
                selector: row =>
                    format.humanDateTimeExcel(
                        row.updated_at
                    ),
            },
            {
                label: 'Name',
                selector: row =>
                    row.user
                        ? `${row.user.firstname} ${row.user.lastname}`
                        : 'Not Available',
            },
            {
                label: 'Email',
                selector: row => (row.user ? row.user.email : 'Not Available'),
            },
            {
                label: 'Telephone',
                selector: row => (row.user ? row.user.phone : 'Not Available'),
            },
            {
                label: 'Location',
                selector: row => row.subscription?.location.address,
            },
            {
                label: 'Payment Mode',
                selector: row => row.payment_mode,
            },
            {
                label: 'Package',
                selector: row => row.subscription?.package.name,
            },
            {
                label: 'Amount',
                selector: row => (row.data ? row.data.order.amount : 'N/A'),
            },
            {
                label: 'Status',
                selector: row => row.status,
            },
        ],
        transactionData
    );
