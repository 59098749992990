/* eslint-disable no-unused-vars */
import { ReactNode } from 'react';
import clsx from 'clsx';
import { Spinner } from '~/components/elements/spinner';

type DashCardProps = {
    count: string | number;
    label: string;
    icon: ReactNode;
    background: string;
    isLoading?: boolean;
    onClick?: (...args: any) => void;
};

export const DashCard = (props: DashCardProps) => {
    const {
        count,
        label,
        icon,
        background,
        isLoading = false,
        onClick,
    } = props;

    return (
        <div
            onClick={onClick}
            className={clsx('rounded-md shadow-lg', background)}
        >
            <div className="flex items-center justify-between px-6 py-8">
                <div>
                    {isLoading ? (
                        <Spinner variant="light" />
                    ) : (
                        <p className="text-3xl font-semibold text-white">
                            {count}
                        </p>
                    )}

                    <p className="mt-2 text-lg text-white">{label}</p>
                </div>

                <div className="text-light">{icon}</div>
            </div>
        </div>
    );
};
