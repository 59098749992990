import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { StoreType } from '~/app/types/redux';
import { storage } from '~/core/utils';
import { ProtectorPropsType } from '../types';

export const MemberRouteProtector = (
    props: ProtectorPropsType
): JSX.Element => {
    const { element } = props;

    const token = storage.getToken();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const role = userAccount.role;

    if (!token) return <Navigate to={'/login'} />;

    if (role && role !== 'member')
        return <Navigate to={`/account/${userAccount.role}`} />;

    return element;
};
