/* eslint-disable no-unused-vars */
import { DataTable, TableColumn } from '~/components/elements';
import { useEffect, useState } from 'react';
import { LeaderBoardType, TransactionType } from '~/app/types';
import { get_leaderboard } from '~/api';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useLocation } from 'react-router-dom';
import { StoreType } from '~/app/types/redux';
import image from '../../../../assets/images/user.png';
import UserImage from '../../../../assets/images/user.png';
import { useSelector } from 'react-redux';
import { StarIcon } from '@heroicons/react/solid';

export const DashFrontDeskLeaderBoard = () => {
    const location = useLocation();
    const query = location.search;

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const [keyword, setKeyword] = useState('');
    const { id } = useSelector((state: StoreType) => state.user.userAccount);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [leaderBoard, setLeaderBoard] =
        useState<PaginationType<LeaderBoardType>>();

    const handleGetleaderBoard = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<LeaderBoardType> = await get_leaderboard(
                query
            );
            setLeaderBoard(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<LeaderBoardType>[] = [
        {
            title: 'User',
            cell: row => (
                <div className="flex flex-row items-center justify-start gap-3">
                    <div className="">
                        <img
                            alt="Honore"
                            src={UserImage}
                            className="h-full w-10 rounded-full"
                        />
                    </div>
                    <div className="flex flex-col">
                        <p className="font-semibold">
                            {row.firstname} {row.lastname}
                        </p>
                        <p>{row.email}</p>
                    </div>
                    { row.id === userAccount.id && <div className="flex flex-col justify-center items-center">
                        <StarIcon className={'w-5 text-yellow-600'} />
                    </div>}
                </div>
            ),
        },
    ];

    useEffect(() => {
        handleGetleaderBoard();
    }, [query, keyword]);
    return (
        <>
            <div className="float-right flex w-full flex-col gap-2  whitespace-nowrap  py-4 text-light ">
                <p className="text-xl text-light">Top Sales</p>
                <div className="flex flex-col justify-between gap-4 lg:flex-row">
                    {leaderBoard?.list
                        .slice(0, 3).reverse()
                        .map((el, index) => (
                            <LeaderCard
                                email={el.email}
                                key={index}
                                id={el.id}
                                name={`${el.firstname} ${el.lastname}`}
                                transactions={el.receivedTransactions}
                            />
                        ))
                        .sort()
                        .reverse()}
                </div>
            </div>
            <div className="w-full py-20 px-1">
                <DataTable
                    startAt={3}
                    columns={columns}
                    data={leaderBoard?.list.slice(3) ?? []}
                    isLoading={isLoading}
                    total={leaderBoard?.total ?? 0}
                    nextPage={leaderBoard?.nextPage ?? 0}
                    lastPage={leaderBoard?.lastPage ?? 0}
                    currentPage={leaderBoard?.currentPage ?? 0}
                    previousPage={leaderBoard?.previousPage ?? 0}
                />
                {/* <div className="">
                    <div className="flex w-full flex-col">
                        <div>
                            <div className="mb-10 flex flex-row justify-evenly border-b border-slate-700">
                                <div className="w-28 py-3 px-2 text-center text-sm font-medium text-white">
                                    #
                                </div>
                                <div className="w-full py-3 px-2 text-left text-sm font-medium text-white">
                                    User
                                </div>
                                <div className="flex w-full items-center justify-center py-3 px-2 text-left text-sm font-medium text-white">
                                    leaderBoard
                                </div>
                                <div className="flex w-full items-center justify-center py-3 px-2 text-left text-sm font-medium text-white">
                                    Location
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="flex flex-col gap-3">
                            <LeaderRow pos={4} />
                            <LeaderRow pos={5} />
                            <LeaderRow pos={6} />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

type LeaderCardProps = {
    name: string;
    email: string;
    id: string;
    transactions: TransactionType[];
};

function LeaderCard(props: LeaderCardProps) {
    const { userAccount } = useSelector((state: StoreType) => state.user);
    return (
        <div className="flex flex-col gap-2 rounded-lg bg-slate-600 p-5 sm:w-96 ">
            <div className="flex flex-row items-center justify-start gap-1">
                <div className="">
                    <img src={image} className="w-16" alt="user" />
                </div>
                <div className="flex flex-col justify-between gap-4">
                    <div className="">
                        <div className="text-xl">{props.name}</div>
                        <div className="font-bold">{props.email}</div>
                    </div>
                </div>
                { props.id === userAccount.id && <div className="flex flex-col justify-center items-center">
                    <StarIcon className={'w-5 text-yellow-600'} />
                </div>}
            </div>
        </div>
    );
}
