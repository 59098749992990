import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { get_card, get_cards } from '~/api';
import { DashMemberAddCard } from '~/components/pages/account/member/DashMemberAddCard';
import { DashMemberCard } from '~/components/pages/account/member/DashMemberCard';
import { DashUpdateCard } from '~/components/pages/account/member/DashUpdateCard';
import { PaginationType } from '~/app/types/pagination';
import { StoreType } from '~/app/types/redux';
import { CommonContext, CommonContextType } from '~/core/context';
import { addCard } from '~/core/redux/slices/card/cardSlice';
import DashMemberStats from '~/components/pages/account/member/DashMemberStats';
import { SubscriptionType } from '~/app/types/subscription';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import DashMemberActiveSubs from '~/components/pages/account/member/DashmemberActiveSubs';
import CreateCard from '~/components/containers/card/create';

export const MemberLayout = () => {
    const [tab, setTab] = useState(0);

    const [open, setOpen] = useState(false);

    const [updateCardModalIsOpen, setUpdateCardModalIsOpen] = useState(false);

    const [loadingFetchingCards, setLoadingFetchingCards] = useState(true);

    const {
        setCards,
        setCardToUpdate,
        addCardModalIsOpen,
        setAddCardModalIsOpen,
    } = useContext<CommonContextType>(CommonContext);

    const dispatch = useDispatch();

    const handleGetCards = async () => {
        try {
            setLoadingFetchingCards(true);
            const cards = await get_card();
            const card = cards.list[0];
            dispatch(addCard(card ? [card] : []));
            setCards(card ? [card] : []);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingFetchingCards(false);
        }
    };

    useEffect(() => {
        handleGetCards();
    }, []);

    const { cardData } = useSelector((state: StoreType) => state.card);

    const links: {
        title: string;
        href: string;
    }[] = [
        {
            title: 'Transactions',
            href: '',
        },
        {
            title: 'Subscriptions',
            href: 'subscriptions',
        },
    ];

    useEffect(() => {
        links.map((link, index) => {
            if (window.location.href.includes(link.href)) setTab(index);
        });
    }, []);
    return (
        <>
            <div className="mt-6 flex w-full flex-wrap justify-between gap-4 xl:justify-start">
                {!loadingFetchingCards && !cardData.length ? (
                    <div className={'flex flex-row justify-between items-center gap-3'}>
                        <div className="text-danger">
                            You don't have any linked card!
                        </div>
                        <div className="">
                            <CreateCard />
                        </div>
                    </div>
                ) : (
                    cardData.map((card, index) => (
                        <div
                            className="w-full cursor-pointer md:w-[430px]"
                            onClick={() => {
                                setCardToUpdate(card);
                                setUpdateCardModalIsOpen(true);
                            }}
                            key={index}
                        >
                            <DashMemberCard
                                card={card}
                                loading={loadingFetchingCards}
                            />
                        </div>
                    ))
                )}
            </div>
            <div className="mt-6">
                <DashMemberStats />
            </div>
            <div
                onClick={() => setOpen(prev => !prev)}
                className="mt-5 flex h-8 w-8 cursor-pointer rounded-md bg-red-600 p-1 sm:hidden    "
            >
                {open ? <XIcon /> : <MenuIcon />}
            </div>
            <div
                className={`${
                    open ? 'flex-col' : ''
                }  mt-4 flex flex-wrap justify-end border-red-600 sm:mt-8 sm:justify-start sm:border-b`}
            >
                {links.map(({ href, title }, index) => (
                    <Link key={index} to={`/account/member/${href}`}>
                        <div
                            className={` ${
                                open
                                    ? 'block w-full rounded-none'
                                    : 'hidden sm:block'
                            } px-2 py-1 sm:rounded-t ${
                                tab == index
                                    ? 'bg-red-600  text-light '
                                    : 'text-light hover:bg-slate-800'
                            }`}
                            onClick={() => setTab(index)}
                        >
                            {title}
                        </div>
                    </Link>
                ))}
            </div>

            <div className="mt-8">
                <Outlet />
            </div>

            <DashUpdateCard
                isOpen={updateCardModalIsOpen}
                onClose={() => setUpdateCardModalIsOpen(false)}
            />

            <DashMemberAddCard
                isOpen={addCardModalIsOpen}
                onClose={() => setAddCardModalIsOpen(false)}
            />
        </>
    );
};
