import { PackageType } from '~/app/types/package';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { paginationString, queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';
import { CategoryPayload } from '~/app/types/category';
import { CronjobPayload, CronjobType } from '~/app/types/cron';

export const get_cronjobs = async (
    query?: string,
    id?: string
): Promise<PackageType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/cronjob${query ? `${queryString(query)}` : ''}${
                id ? `?userId=${id}` : ''
            }`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_cronjobs_user = async (
    id?: string,
    pageNumber?: number,
    pageSize?: number
): Promise<PackageType[] | any> => {
    try {
        const request = await PRIVATE_API.get(`/cronjob?${paginationString(
            pageNumber ?? 0,
            pageSize ?? 10
        )}&userId=${id}`);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_next_cronjob = async (): Promise<any> => {
    try {
        const request = await PRIVATE_API.get('cronjob/next-cron-job');
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_cronjob = async (
    id: string,
    cronjobPayload: CronjobPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/cronjob', {
            ...cronjobPayload,
            scheduled_on: new Date(cronjobPayload.scheduled_on).toISOString(),
            userId: id,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

function formatDate(date: string) {
    const dateObject = new Date(date);
    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const day = String(dateObject.getDate()).padStart(2, '0');

    // Concatenate the components to form the "year-month-day" format
    return `${year}-${month}-${day}`;
}

export const update_cronjob = async (
    id: string,
    selected: CronjobType | undefined,
    cronjobPayload: CronjobPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/cronjob/${selected?.id}`, {
            ...cronjobPayload,
            scheduled_on: formatDate(
                new Date(cronjobPayload.scheduled_on).toISOString()
            ),
            userId: id,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const delete_cronjob = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/cronjob/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const run_cronjob = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(`/cronjob/run-cron/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
