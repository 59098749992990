import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as z from 'zod';
import { delete_card, update_card } from '~/api';
import { CardPayload } from '~/app/types';
import { StoreType } from '~/app/types/redux';
import { Button, Form, InputField, Modal } from '~/components/elements';
import { CommonContext, CommonContextType } from '~/core/context';
import { updateCard } from '~/core/redux/slices/card/cardSlice';
import { format } from '~/core/utils';

type DashUpdateCardProps = {
    isOpen: boolean;
    onClose: () => void;
};

const schema = z.object({
    name_on_card: z.string().min(1, 'Name on card is required'),
    card_number: z.string().min(1, 'Card number is required'),
    expiry_month: z.string().min(1, 'Required'),
    expiry_year: z.string().min(1, 'Required'),
    security_code: z.string().min(1, 'Required'),
});

export const DashUpdateCard = (props: DashUpdateCardProps) => {
    const { isOpen, onClose } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const { cardToUpdate } = useContext<CommonContextType>(CommonContext);

    const dispatch = useDispatch();

    const { cardData } = useSelector((state: StoreType) => state.card);

    const handleSubmit = async (payload: CardPayload) => {
        try {
            setLoading(true);
            await update_card(payload, cardToUpdate.id);
            toast.success('Card updated successfully');
            window.location.reload();
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoading(false);
        }
    };

    const deleteCard = async () => {
        try {
            setLoading(true);
            await delete_card(cardToUpdate.id);
            dispatch(
                updateCard(cardData.filter(card => card.id !== cardToUpdate.id))
            );
            toast.success('Card deleted successfully');
            onClose();
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Modal title="Update Card" isOpen={isOpen} onClose={onClose}>
            <Form<CardPayload, typeof schema>
                schema={schema}
                options={{
                    defaultValues: {
                        expiry_month: format
                            .capitalizeName(
                                cardToUpdate?.data.sourceOfFunds?.provided.card
                                    .expiry ?? ''
                            )
                            ?.slice(0, 2),
                        name_on_card: format.capitalizeName(
                            cardToUpdate?.data.sourceOfFunds?.provided.card
                                .nameOnCard ?? ''
                        ),
                        expiry_year: format
                            .capitalizeName(
                                cardToUpdate?.data.sourceOfFunds?.provided.card
                                    .expiry ?? ''
                            )
                            ?.slice(-2),
                        card_number:
                            cardToUpdate?.data.sourceOfFunds.provided.card
                                .number,
                    },
                }}
                onSubmit={handleSubmit}
            >
                {({ register, formState }) => (
                    <>
                        <div className="flex flex-col gap-4">
                            <InputField
                                label="Card Holder Name"
                                placeholder="Name on Card"
                                error={formState.errors.name_on_card}
                                registration={register('name_on_card')}
                                className="h-12"
                                isLoading={loading}
                            />

                            <InputField
                                type="number"
                                label="Card Number"
                                placeholder="1234-1234-1234-1234"
                                error={formState.errors.card_number}
                                registration={register('card_number')}
                                className="h-12"
                                isLoading={loading}
                            />

                            <div className="flex flex-col gap-4 md:flex-row">
                                <InputField
                                    type="number"
                                    label="Expire Month"
                                    placeholder="01"
                                    error={formState.errors.expiry_month}
                                    registration={register('expiry_month')}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <InputField
                                    type="number"
                                    label="Expire Year"
                                    placeholder="22"
                                    error={formState.errors.expiry_year}
                                    registration={register('expiry_year')}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <InputField
                                    type="number"
                                    label="Security Code"
                                    placeholder="123"
                                    error={formState.errors.security_code}
                                    registration={register('security_code')}
                                    className="h-12"
                                    isLoading={loading}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            variant="btn-danger"
                            className="mt-8 w-full font-semibold"
                            isLoading={loading}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="btn-danger"
                            className="mt-8 w-full font-semibold"
                            isLoading={loading}
                            onClick={deleteCard}
                        >
                            Delete
                        </Button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
