import { CreditCardIcon, PlusIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useDisclosure } from '~/core/hooks';
import { Button, Form, InputField, Modal, Spinner } from '~/components/elements';
import { CardPayload } from '~/app/types';
import * as z from 'zod';
import { create_card } from '~/api';
import { toast } from 'react-toastify';

const schema = z.object({
    name_on_card: z.string().min(1, 'Name on card is required'),
    card_number: z.string().min(1, 'Card number is required'),
    expiry_month: z.string().min(1, 'Required'),
    expiry_year: z.string().min(1, 'Required'),
    security_code: z.string().min(1, 'Required'),
});

export default function CreateCard() {
    const open = useDisclosure();
    const [loading, setIsLoading] = useState(false);

    const handleSubmit = async (payload: CardPayload) => {
        try {
            setIsLoading(true);
            await create_card(payload);
            toast.success('Card Added.');
        } catch (e: any) {
            if(e.response.message.isArray) {
                e.response.message.map((err: string) => toast.error(err));
            } else {
                toast.error(e.response.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="text-light p-1 px-2 cursor-pointer border rounded-md flex flex-row gap-1" onClick={open.onOpen} >
                <PlusIcon className={'w-6'}/> Add Card
            </div>

            <Modal isOpen={open.isOpen} size={'max-w-md w-full'} title={'Add Card'} onClose={open.onClose}>
                <Form<CardPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleSubmit}
                    className="bg-dark-light p-4 md:p-0"
                >
                    {({ register, formState }) => (
                        <>
                            <div className="flex flex-col gap-4">
                                <InputField
                                    label="Card Holder Name"
                                    placeholder="Name on Card"
                                    error={
                                        formState.errors
                                            .name_on_card
                                    }
                                    registration={register(
                                        'name_on_card'
                                    )}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <InputField
                                    type="number"
                                    label="Card Number"
                                    placeholder="1234-1234-1234-1234"
                                    error={
                                        formState.errors
                                            .card_number
                                    }
                                    registration={register(
                                        'card_number'
                                    )}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <div className="flex flex-col items-center gap-4 text-sm md:flex-row">
                                    <InputField
                                        type="number"
                                        label="Expire Month"
                                        placeholder="01"
                                        error={
                                            formState.errors
                                                .expiry_month
                                        }
                                        registration={register(
                                            'expiry_month'
                                        )}
                                        className="h-12"
                                        isLoading={loading}
                                    />

                                    <InputField
                                        type="number"
                                        label="Expire Year"
                                        placeholder="22"
                                        error={
                                            formState.errors
                                                .expiry_year
                                        }
                                        registration={register(
                                            'expiry_year'
                                        )}
                                        className="h-12"
                                        isLoading={loading}
                                    />

                                    <InputField
                                        type="number"
                                        label="Security Code"
                                        placeholder="123"
                                        error={
                                            formState.errors
                                                .security_code
                                        }
                                        registration={register(
                                            'security_code'
                                        )}
                                        className="h-12"
                                        isLoading={loading}
                                    />
                                </div>
                            </div>

                            <Button
                                type="submit"
                                variant="btn-primary"
                                className="mt-8 w-full btn-sm p-2 font-semibold"
                                isLoading={loading}
                            >
                                <CreditCardIcon className=" w-10" />
                                {loading ? (
                                    <Spinner size="sm" />
                                ) : (
                                    'Add Card'
                                )}
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
}