import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storage } from '~/core/utils';
import { check_user } from '~/api';
import { useLogout } from './useLogout';
import { addUser } from '../redux/slices/user/userAccountSlice';

export const CheckUser = () => {
    const token = storage.getToken();
    const dispatch = useDispatch();
    const { logout } = useLogout();

    const fetchUser = async () => {
        if (token) {
            try {
                const user = await check_user();
                dispatch(addUser(user));
            } catch (error) {
                logout();
            }
        } else {
            logout();
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);
};
