/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, DataTable, Form, Modal, SelectField, TableColumn } from '~/components/elements';
import UserImage from '~/assets/images/user.png';
import { toast } from 'react-toastify';
import { SubscriptionType } from '~/app/types/subscription';
import { PauseIcon, PencilAltIcon, PlusCircleIcon, StopIcon } from '@heroicons/react/outline';
import { PaginationType } from '~/app/types/pagination';
import React, { useEffect, useState } from 'react';
import {
    cancelSubscription,
    changeSubscription,
    get_card,
    get_fine,
    get_subscriptions,
    pauseSubscription,
} from '~/api';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { format } from '~/core/utils';
import { useDisclosure } from '~/core/hooks';
import { canOrPauPayload, CardType, changePayload } from '~/app/types';

export const DashMemberSubscription = () => {
    const { userAccount } = useSelector((state: StoreType) => state.user);

    const [cards, setCards] = useState<CardType[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const openCancel = useDisclosure();
    const openPause = useDisclosure();

    const [subscriptions, setSubscriptions] =
        useState<PaginationType<SubscriptionType>>();

    const { search: query } = useLocation();

    const handleGetSubscriptions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<SubscriptionType> =
                await get_subscriptions(userAccount.id, query);
            setSubscriptions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const [lf, setLf] = useState(false);
    const [fee, setFee] = useState<{cancellationFee: number, PausingFee: number} | null>(null);

    const getFee = async () => {
        try {
            setLf(true);
            const data = await get_fine();
            setFee(data);
        } finally {
            setLf(false);
        }
    };

    useEffect(() => {
        getFee();
    }, []);

    const columns: TableColumn<SubscriptionType>[] = [
        {
            title: 'Profile Image',
            cell: () => (
                <div className="h-10 w-10 md:h-12 md:w-12">
                    <img
                        alt="Honore"
                        src={UserImage}
                        className="h-full w-full rounded-full"
                    />
                </div>
            ),
        },
        {
            title: 'Email',
            cell: row => row.user.email,
        },
        {
            title: 'Package',
            cell: row => (
                <>
                    <p>{row.package.name}</p>
                </>
            ),
        },
        {
            title: 'Location',
            cell: row => <p className="capitalize">{`${row.location.name}`}</p>,
        },
        {
            title: 'Amount',
            cell: row => `${row.package.price}`,
        },
        {
            title: 'Card Number',
            cell: row => (
                <p>
                    {row.card?.data?.sourceOfFunds?.provided?.card?.number ??
                        'Hidden'}
                </p>
            ),
        },
        {
            title: 'Start Date',
            cell: row => format.humanDate(row.created_at),
        },
        {
            title: 'End Date',
            cell: row => format.humanDate(row.expires_at),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    <span
                        className={`block rounded-full ${
                            row.status.includes('active')
                                ? 'bg-green-600'
                                : 'bg-red-500'
                        } py-0.5 text-center text-[12px] font-semibold uppercase text-white`}
                    >
                        {row.status}
                    </span>
                </>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-2">
                    <PauseIcon onClick={() => {
                        setSelected(row);
                        setPhone(userAccount.phone);
                        openPause.onOpen();
                    }} className="w-8 cursor-pointer" />
                    <StopIcon onClick={() => {
                        setSelected(row);
                        openCancel.onOpen();
                    }} className="w-8 text-red-600 cursor-pointer" />
                    <PencilAltIcon onClick={() => {
                        setSelected(row);
                        openChange.onOpen();
                    }} className="w-8 cursor-pointer" />
                </div>
            ),
        },
    ];

    useEffect(() => {
        handleGetSubscriptions();
    }, [query, openCancel.onClose, openPause.onClose]);

    const [loadingCreate, setLoadingCreate] = useState(false);
    const [phone, setPhone] = useState('2507');

    const [selected, setSelected] = useState<SubscriptionType | null>(null);

    const pauseSub = async (payload: canOrPauPayload) => {
        console.log(payload);
        if(payload.card_id === '' || payload.paymentMode === '') {
            toast.error('Please Fill All Fields');
        } else if(payload.paymentMode === 'momo') {
            if((phone.startsWith('2507') && phone.length === 12)) {
                try {
                    setLoadingCreate(true);
                    payload.userId = userAccount.id;
                    payload.phoneNumber = phone;
                    payload.subscriptionId = selected?.id ?? '';
                    await pauseSubscription(payload);
                    openPause.onClose();
                    toast.success('Pause Subscription Initiated.');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setLoadingCreate(false);
                }
            } else {
                toast.error('Phone Must start with 2507 and be 12 characters long.');
            }
        } else {
            if(subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id == undefined) {
                return toast.error('Card Not Found.');
            }
            try {

                setLoadingCreate(true);
                payload.subscriptionId = selected?.id ?? '';
                payload.userId = userAccount?.id;
                payload.card_id = subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id ?? '';
                await pauseSubscription(payload);
                openPause.onClose();
                toast.success('Pause Subscription Initiated.');
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setLoadingCreate(false);
            }
        }
    };

    const cancelSub = async (payload: canOrPauPayload) => {
        if(payload.card_id === '' || payload.paymentMode === '') {
            toast.error('Please Fill All Fields');
        } else if(payload.paymentMode === 'momo') {
            if((phone.startsWith('2507') && phone.length === 12)) {
                try {
                    setLoadingCreate(true);
                    payload.subscriptionId = selected?.id ?? '';
                    payload.userId = userAccount.id;
                    payload.phoneNumber = phone;
                    await cancelSubscription(payload);
                    openCancel.onClose();
                    toast.success('Cancel Subscription Initiated.');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setLoadingCreate(false);
                }
            } else {
                toast.error('Phone Must start with 2507 and be 12 characters long.');
            }
        } else {
            if(subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id == undefined) {
                return toast.error('Card Not Found.');
            }
            try {
                setLoadingCreate(true);
                payload.subscriptionId = selected?.id ?? '';
                payload.userId = userAccount?.id;
                payload.card_id = subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id ?? '';
                await cancelSubscription(payload);
                openCancel.onClose();
                toast.success('Cancel Subscription Initiated.');
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setLoadingCreate(false);
            }
        }
    };

    const [change, setChange] = useState(false);

    const openChange = useDisclosure();

    const changeSubMode = async (payload: changePayload) => {
        if(payload.paymentMode == '') {
            toast.error('Select Payment Mode');
        } else {
            if(payload.paymentMode == 'momo') {
                try {
                    setChange(true);
                    payload.subscriptionId = selected?.id ?? '';
                    await changeSubscription(payload);
                    openChange.onClose();
                    toast.success('Subscription Mode Changed To Momo.');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setChange(false);
                }
            } else {
                if(cards.length === 0) {
                    openChange.onClose();
                    return toast.error('Please add cards first.');
                } else {
                    try {
                        setChange(true);
                        payload.subscriptionId = selected?.id ?? '';
                        await changeSubscription(payload);
                        openChange.onClose();
                        toast.success('Subscription Mode Changed To Card.');
                    } catch (error: any) {
                        toast.error(error.message);
                    } finally {
                        setChange(false);
                    }
                }
            }
        }
    };

    const getCards = async () => {
        const all_cards = await get_card();
        const cards = all_cards.list as CardType[];
        setCards(cards);
    };

    useEffect(() => {
        getCards();
    }, []);

    return (
        <>
            <Modal
                title="Change Subscription Mode"
                size="w-full md:w-1/2"
                isOpen={openChange.isOpen}
                onClose={openChange.onClose}
            >
                <Form<changePayload> onSubmit={changeSubMode}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="flex flex-col gap-4">

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />
                            </div>
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Change
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                title="Cancel Subscription"
                size="w-full md:w-1/2"
                isOpen={openCancel.isOpen}
                onClose={openCancel.onClose}
            >
                <Form<canOrPauPayload> onSubmit={cancelSub}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="p-2 text-sm text-light md:text-lg">
                                Are you sure you want to Cancel{' '}
                                <span className="mx-1 font-bold text-red-600">
                                {selected?.package.name}
                            </span>{' '}
                                Subscription? A fee of <span className={'mx-1 font-bold text-red-600'}>
                                {format.currenyWithAmount(fee?.cancellationFee ?? 0)}
                            </span> will be paid first.
                            </div>
                            <div className="flex flex-col gap-4">

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                        { value: 'pos', label: 'POS' },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />

                                {watch('paymentMode') === 'momo' &&
                                    <>
                                        <div
                                            className={' my-2  flex-wrap flex text-light items-center justify-center gap-2 border-y border-slate-700 py-2'}
                                        >
                                            <p>Enter MoMo Phone number</p>
                                            <div className="flex flex-col">
                                                <div className="flex flex-wrap justify-center gap-2">
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        maxLength={12}
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}
                                                        className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Cancel Subscription
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                title="Pause Subscription"
                size="w-full md:w-1/2"
                isOpen={openPause.isOpen}
                onClose={openPause.onClose}
            >
                <Form<canOrPauPayload> onSubmit={pauseSub}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="p-2 text-sm text-light md:text-lg">
                                Are you sure you want to Pause{' '}
                                <span className="mx-1 font-bold text-red-600">
                                {selected?.package.name}
                            </span>{' '}
                                Subscription? A fee of <span className={'mx-1 font-bold text-red-600'}>
                                {format.currenyWithAmount(fee?.PausingFee ?? 0)}
                            </span> will be paid first.
                            </div>
                            <div className="flex flex-col gap-4">

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                        { value: 'pos', label: 'POS' },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />

                                {watch('paymentMode') == 'momo' &&
                                    <>
                                        <div
                                            className={' my-2  flex-wrap flex text-light items-center justify-center gap-2 border-y border-slate-700 py-2'}
                                        >
                                            <p>Enter MoMo Phone number</p>
                                            <div className="flex flex-col">
                                                <div className="flex flex-wrap justify-center gap-2">
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        maxLength={12}
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}
                                                        className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Pause Subscription
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>
            <div className="pb-4">
                <Link to={'/account/member/create/subscription'}>
                    <Button className="btn btn-success btn-md px-3 sm:px-4">
                        <PlusCircleIcon className="icon-md" />
                        <span className="hidden uppercase sm:block">
                            Subscription
                        </span>
                    </Button>
                </Link>
            </div>
            <DataTable
                columns={columns}
                data={subscriptions?.list ?? []}
                isLoading={isLoading}
                total={subscriptions?.total ?? 0}
                lastPage={subscriptions?.lastPage ?? 0}
                nextPage={subscriptions?.nextPage ?? 0}
                currentPage={subscriptions?.currentPage ?? 0}
                previousPage={subscriptions?.previousPage ?? 0}
            />
        </>
    );
};
