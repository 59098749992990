/* eslint-disable indent */
import { DataTable, Modal, TableColumn } from '~/components/elements';
import { format } from '~/core/utils';
import UserImage from '~/assets/images/user.png';
import { TransactionFilterPayload, TransactionType } from '~/app/types';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { get_transactions } from '~/api';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RetryComponent from '../common/RetryComponent';
import { useDisclosure } from '~/core/hooks';
import { DashFilterComponent } from '~/components/pages/account/common/DashAdminFilter';
import { DashClientFilterComponent } from '~/components/pages/account/common/DashUserFilter';

export const DashMemberTransaction = () => {
    const location = useLocation();

    const query = location.search;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selected, setSelected] = useState({ id: '' });
    const [keyword, setKeyword] = useState('');

    const [transactions, setTransactions] =
        useState<PaginationType<TransactionType>>();

    const handleGetTransactions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<TransactionType> =
                await get_transactions(keyword, query);
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<TransactionType>[] = [
        {
            title: 'Profile Image',
            cell: () => (
                <div className="mx-auto h-10 w-10 md:h-12 md:w-12">
                    <img
                        alt="Honore"
                        src={UserImage}
                        className="h-full w-full rounded-full"
                    />
                </div>
            ),
        },
        {
            title: 'Date Time',
            cell: row =>
                format.humanDateTime(
                    row.updated_at
                ),
        },
        {
            title: 'Member',
            cell: row => (
                <>
                    <p className="capitalize">
                        {row.user &&
                            `${row.user.firstname} ${row.user.lastname}`}
                    </p>
                    <p>{row.user && row.user.email}</p>
                </>
            ),
        },
        {
            title: 'Location / Package',
            cell: row => (
                <p className="capitalize">{`${
                    row.subscription?.location.name ?? 'N/A'
                } // ${row.subscription?.package.name ?? 'N/A'}`}</p>
            ),
        },
        {
            title: 'Amount',
            cell: row => format.currenyWithAmount(row.amount),
        },
        {
            title: 'Cron Date',
            cell: row => (<>
                <div className="">{row.cron_date ? format.humanDateTime(row.cron_date) : (
                    <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                )}</div>
            </>),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() !== 'success' && (
                        <span className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
        {
            title: 'ACTION',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' &&
                        row.status.toLowerCase() === 'success' && (
                            <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                        )}
                    {row.status.toLowerCase() !== 'success' && (
                        <button
                            onClick={() => {
                                onOpen();
                                setSelected({
                                    id: row.id,
                                });
                            }}
                            className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white"
                        >
                            Retry
                        </button>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        handleGetTransactions();
    }, [query, keyword]);

    const [filters, setFilters] = useState<TransactionFilterPayload>({
        location_id: '',
        package_id: '',
        from: '',
        to: '',
        status: '',
    });

    return (
        <>
            <div className="flex justify-end py-2 gap-3">
                <DashClientFilterComponent
                    filters={filters}
                    onFilter={(payload: TransactionFilterPayload) => {
                        setFilters(payload);
                    }}
                />
                <input
                    type="search"
                    onChange={e => setKeyword(e.target.value)}
                    placeholder="Search..."
                    // value={keyword}
                    className="rounded-md border-b border-slate-700 bg-transparent p-2 text-light focus:border-slate-700 focus:outline-none focus:ring-0"
                />
            </div>
            <DataTable
                columns={columns}
                data={transactions?.list ?? []}
                isLoading={isLoading}
                total={transactions?.total ?? 0}
                lastPage={transactions?.lastPage ?? 0}
                nextPage={transactions?.nextPage ?? 0}
                currentPage={transactions?.currentPage ?? 0}
                previousPage={transactions?.previousPage ?? 0}
            />
            <div className="">
                <Modal
                    title="Retry"
                    size="text-light md:w-1/2 max-w-[500px] w-11/12 mt-20"
                    onClose={onClose}
                    isOpen={isOpen}
                >
                    <RetryComponent id={selected.id} />
                </Modal>
            </div>
        </>
    );
};
