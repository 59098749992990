import { ReactNode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { ErrorLayout, LoadingLayout } from '~/components/layouts';
import { Provider } from 'react-redux';
import { store, persistor } from '../redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CommonContextProvider } from '../context';

type AppProviderProps = {
    children: ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
    const { children } = props;

    return (
        <Suspense fallback={<LoadingLayout />}>
            <ErrorBoundary FallbackComponent={ErrorLayout}>
                <HelmetProvider>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <CommonContextProvider>
                                <BrowserRouter>{children}</BrowserRouter>
                            </CommonContextProvider>
                        </PersistGate>
                    </Provider>

                    <ToastContainer position="top-right" theme="colored" />
                </HelmetProvider>
            </ErrorBoundary>
        </Suspense>
    );
};
