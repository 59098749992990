/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    Modal,
    SelectField,
    TableColumn,
} from '~/components/elements';
import UserImage from '~/assets/images/user.png';
import { TransactionFilterPayload, UpdateUserPayload, UserFilterPayload, UserPayload, UserType } from '~/app/types';
import { useContext, useEffect, useState } from 'react';
import {
    DocumentIcon,
    DocumentReportIcon,
    EyeIcon,
    PencilAltIcon,
    RefreshIcon,
    StopIcon,
    TrashIcon,
    XCircleIcon,
    XIcon,
} from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import {
    create_user,
    disable_user,
    get_all_users_search,
    update_user_names,
    update_user_password,
} from '~/api';
import { ExportContext, ExportContextType } from './ExportContextProvider';
import { exportUser } from '~/core/helper/csv/user';
import { FrontDeskDisplayUser } from '../frontdesk/modals';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';
import { format } from '~/core/utils';
import { handleExportExcel } from '../common/DashAdminFilter';
import { DashFilterUserComponent } from '~/components/pages/account/common/DashUsersFilter';

const schema = z.object({
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    email: z.string().email({ message: 'Email is invalid' }),
    password: z
        .string()
        .min(1, { message: 'Password is required' })
        .max(16, { message: 'Password is too Long' }),
    phone: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must be inform of 2507........' }
    ),
});

const update_phone_schema = z.object({
    phone: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must be inform of 2507........' }
    ),
});

const update_schema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    phone: z.string().optional(),
});

const update_password_schema = z.object({
    password: z
        .string()
        .min(5, { message: 'Password is too short' })
        .max(16, { message: 'Password is too Long' }),
});

export const DashAdminUsers = () => {
    const location = useLocation();

    const query = location.search;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [users, setUsers] = useState<PaginationType<UserType> | null>();

    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [keyword, setKeyword] = useState('');

    const [isDisplayUserModalOpen, setIsDisplayUserModalOpen] =
        useState<boolean>(false);

    const { setExportData } = useContext<ExportContextType>(ExportContext);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
        handleGetUsers(keyword);
    };

    const handleGetUsers = async (keyword: string) => {
        try {
            setIsLoading(true);
            const data: PaginationType<UserType> = await get_all_users_search(
                query,
                keyword
            );
            setUsers(data);
        } catch (error) {
            console.log(error);
            toast.error('Error getting users');
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<UserType>[] = [
        {
            title: 'Profile Image',
            cell: () => (
                <div className="mx-auto h-10 w-10 md:h-12 md:w-12">
                    <img
                        alt="Honore"
                        src={UserImage}
                        className="h-full w-full rounded-full"
                    />
                </div>
            ),
        },
        {
            title: 'Firstname',
            cell: row => row.firstname,
        },
        {
            title: 'Lastname',
            cell: row => row.lastname,
        },
        {
            title: 'Telephone',
            cell: row => row.phone,
        },
        {
            title: 'Email',
            cell: row => row.email,
        },
        {
            title: 'Status',
            cell: row => (
                <p
                    className={
                        row.is_active ? 'text-green-600' : 'text-red-600'
                    }
                >
                    {row.is_active ? 'Active' : 'Inactive'}
                </p>
            ),
        },
        {
            title: 'Subscription',
            cell: row => (
                <div className={'flex justify-center items-center'}>
                    <p
                        className={`block w-full rounded-full py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white
                    ${row.subscriptionStatus == 'active' ? 'bg-green-600' : row.subscriptionStatus == 'inactive' ? 'bg-red-600' : row.subscriptionStatus == 'expired' ? 'bg-gray' : 'bg-yellow-600'}`}
                    >
                        {row.subscriptionStatus ?? 'INVALID'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-3">
                    <XCircleIcon
                        onClick={() => {
                            setSelectedUser(row);
                            openDisable.onOpen();
                        }}
                        className="w-5 cursor-pointer text-red-600"
                    />
                    <PencilAltIcon
                        className="w-5 cursor-pointer"
                        onClick={() => {
                            setSelectedUser(row);
                            openUpdateUser.onOpen();
                        }}
                    />
                    <EyeIcon
                        className="w-5 cursor-pointer"
                        onClick={() => {
                            setSelectedUser(row);
                            setIsDisplayUserModalOpen(true);
                        }}
                    />
                </div>
            ),
        },
    ];

    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingDisable, setIsLoadingDisable] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] =
        useState<boolean>(false);
    const openDisable = useDisclosure();
    const openCreateUser = useDisclosure();
    const openUpdateUser = useDisclosure();
    const handleCreate = async (e: UserPayload) => {
        try {
            setIsLoadingCreate(true);
            await create_user({ ...e, role: 'member' }).then(() => {
                toast.success('User Created');
                openCreateUser.onClose();
            });
        } catch (error) {
            toast.error(`User not created. ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };
    const handleUpdate = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            await update_user_names(
                selectedUser?.id || '',
                e,
                selectedUser || {}
            ).then(() => {
                openUpdateUser.onClose();
                toast.success('User Updated');
            });
        } catch (error) {
            toast.error(`User not Updated. ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };
    const handleUpdatePassword = async (e: any) => {
        try {
            setIsLoadingUpdatePassword(true);
            await update_user_password(
                selectedUser?.id || '',
                e,
                {

                } || {}
            ).then(() => {
                openUpdateUser.onClose();
                toast.success('User Password Updated');
            });
        } catch (error) {
            toast.error(`User Password not Updated. ${error}`);
        } finally {
            setIsLoadingUpdatePassword(false);
        }
    };
    const handleDisable = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDisable(true);
            await disable_user(selectedUser?.id).then(() => {
                toast.success('User disabled');
                openDisable.onClose();
            });
        } catch (error) {
            toast.error(`User not disabled: ${error}`);
        } finally {
            setIsLoadingDisable(false);
        }
    };

    const [tab, setTab] = useState(0);

    useEffect(() => {
        handleGetUsers(keyword);
    }, [
        query,
        isLoadingDisable,
        isLoadingCreate,
        isLoadingUpdate,
        isLoadingUpdatePassword,
    ]);

    const { exportData } = useContext<ExportContextType>(ExportContext);

    const handleExport = () => {
        const link = document.createElement('a');

        let csvText = exportData;

        if (csvText === null) return;

        const fileName = `WakaPay - users - ${format.todayDate()}.csv`;

        if (!csvText.match(/^data:text\/csv/i))
            csvText = `data:text/csv;charset=utf-8,${csvText}`;

        link.setAttribute('href', encodeURI(csvText));
        link.setAttribute('download', fileName);
        link.click();
    };

    useEffect(() => {
        if (users?.list) {
            setExportData(exportUser(users?.list ?? []));
        }
    }, [users]);

    const [filters, setFilters] = useState<UserFilterPayload>({
        status: '',
        package_id: ''
    });

    return (
        <>
            <div className="float-right flex flex-wrap justify-between  gap-4 whitespace-nowrap px-6 py-4">
                <div className="flex flex-wrap gap-3">
                    {/*<Button*/}
                    {/*    variant="btn-secondary"*/}
                    {/*    className="px-3"*/}
                    {/*    onClick={handleExport}*/}
                    {/*>*/}
                    {/*    <DocumentIcon className="icon-md" />*/}
                    {/*</Button>*/}
                    <DashFilterUserComponent filters={filters}  onFilter={(payload: UserFilterPayload) => {
                        setFilters(payload);
                    }}/>
                    <Button
                        variant="btn-secondary"
                        className="px-3"
                        title="Export to excel"
                        onClick={() =>
                            handleExportExcel(
                                users?.list.map(item => {
                                    return {
                                        Names: `${item.firstname} ${item.lastname}`,
                                        Subscription: item.subscriptionStatus ? item.subscriptionStatus.toUpperCase() : 'INVALID',
                                        Telephone: item.phone,
                                        Email: item.email,
                                        Status: item.subscriptionStatus ? item.subscriptionStatus.toUpperCase() : 'INVALID',
                                    };
                                }) ?? []
                            )
                        }
                    >
                        <DocumentReportIcon className="icon-md" />
                    </Button>
                    <button
                        onClick={() => openCreateUser.onOpen()}
                        className="rounded-md bg-red-600 px-2 py-1 text-light"
                    >
                        Create Client
                    </button>
                    <input
                        type="text"
                        className="flex items-center rounded-md border border-slate-300 bg-slate-200 text-base  font-medium"
                        placeholder="Search..."
                        defaultValue={keyword}
                        id="search"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={users?.list ?? []}
                isLoading={isLoading}
                total={users?.total ?? 0}
                nextPage={users?.nextPage ?? 0}
                lastPage={users?.lastPage ?? 0}
                currentPage={users?.currentPage ?? 0}
                previousPage={users?.previousPage ?? 0}
            />

            <FrontDeskDisplayUser
                isOpen={isDisplayUserModalOpen}
                onClose={() => setIsDisplayUserModalOpen(false)}
                selectedUser={selectedUser}
            />

            <Modal
                title="Create Client"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openCreateUser.isOpen}
                onClose={openCreateUser.onClose}
            >
                <Form<UserPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleCreate}
                >
                    {({ register, watch, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <InputField
                                    type="text"
                                    registration={register('firstName')}
                                    className="h-12"
                                    error={formState.errors.firstName}
                                    label="First Name"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    registration={register('lastName')}
                                    className="h-12"
                                    error={formState.errors.lastName}
                                    label="Last Name"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="email"
                                    registration={register('email')}
                                    className="h-12"
                                    error={formState.errors.email}
                                    label="Email"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    registration={register('phone')}
                                    className="h-12"
                                    error={formState.errors.phone}
                                    label="Phone Number"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="password"
                                    registration={register('password')}
                                    className="h-12"
                                    error={formState.errors.password}
                                    label="Password"
                                    isLoading={isLoadingCreate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingCreate}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            <Modal
                title={`Update ${selectedUser?.firstname} ${selectedUser?.lastname}`}
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateUser.isOpen}
                onClose={openUpdateUser.onClose}
            >
                <div className="mb-2 flex flex-row justify-between border-b border-slate-700 text-light">
                    <div
                        onClick={() => setTab(0)}
                        className={`${
                            tab === 0 && 'bg-slate-700'
                        } w-full cursor-pointer p-2 text-center`}
                    >
                        Names
                    </div>
                    <div
                        onClick={() => setTab(1)}
                        className={`${
                            tab === 1 && 'bg-slate-700'
                        } w-full cursor-pointer border-l border-slate-700 p-2 text-center`}
                    >
                        Password
                    </div>
                </div>
                {tab === 0 && (
                    <Form<UpdateUserPayload, typeof update_schema>
                        schema={update_schema}
                        onSubmit={handleUpdate}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="text"
                                        registration={register('firstName')}
                                        className="h-12"
                                        defaultValue={selectedUser?.firstname}
                                        error={formState.errors.firstName}
                                        label="First Name"
                                        isLoading={isLoadingUpdate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('lastName')}
                                        className="h-12"
                                        defaultValue={selectedUser?.lastname}
                                        error={formState.errors.lastName}
                                        label="Last Name"
                                        isLoading={isLoadingUpdate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('phone')}
                                        className="h-12"
                                        defaultValue={selectedUser?.phone}
                                        error={formState.errors.phone}
                                        label="Phone Number"
                                        isLoading={isLoadingUpdate}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingUpdate}
                                    >
                                        Update Names
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
                {tab === 1 && (
                    <Form<UserPayload, typeof update_password_schema>
                        schema={update_password_schema}
                        onSubmit={handleUpdatePassword}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="password"
                                        registration={register('password')}
                                        className="h-12"
                                        error={formState.errors.password}
                                        label="Password"
                                        isLoading={isLoadingCreate}
                                    />
                                </div>
                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingUpdatePassword}
                                    >
                                        Update Password
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Modal>
            <Modal
                title="Disable User"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDisable.isOpen}
                onClose={openDisable.onClose}
            >
                <form onSubmit={handleDisable}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to disable{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selectedUser?.firstname}{' '}
                                {selectedUser?.lastname}
                            </span>
                            's Account?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDisable}
                            >
                                Disable
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};
