import { useState, useEffect } from 'react';
import { isAxiosError } from 'axios';
import { PRIVATE_API } from '~/api';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

type UseFetchReturnType<T> = [boolean, string | null, T | null];

const useFetch = <T>(
    requestType: RequestType,
    endpoint: string,
    requestBody?: any,
    requestParam?: any
): UseFetchReturnType<T> => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async (): Promise<void> => {
            setLoading(true);

            try {
                let response;

                switch (requestType) {
                    case 'GET':
                        response = await PRIVATE_API.get<T>(endpoint);
                        break;
                    case 'POST':
                        response = await PRIVATE_API.post<T>(
                            endpoint,
                            requestBody
                        );
                        break;
                    case 'PUT':
                        response = await PRIVATE_API.put<T>(
                            endpoint,
                            requestBody
                        );
                        break;
                    case 'PATCH':
                        response = await PRIVATE_API.patch<T>(
                            endpoint,
                            requestBody
                        );
                        break;
                    case 'DELETE':
                        response = await PRIVATE_API.delete<T>(endpoint);
                        break;
                    default:
                        throw new Error(`Invalid request type: ${requestType}`);
                }

                if (isMounted) {
                    setData(response.data);
                    setError(null);
                }
            } catch (error: any) {
                if (isAxiosError(error)) {
                    if (isMounted) {
                        setData(null);
                        if (error.response) {
                            setError(
                                `Request failed with status ${
                                    error.response.status
                                }: ${
                                    error.response.data?.message ??
                                    'Try again later!'
                                }`
                            );
                        } else if (error.request) {
                            setError('No response received from server.');
                        } else {
                            setError(
                                `Error setting up request: ${error.message}`
                            );
                        }
                    }
                } else {
                    if (isMounted) {
                        setData(null);
                        setError(`An error occurred: ${error.message}`);
                    }
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return (): void => {
            isMounted = false;
        };
    }, [requestType, endpoint, requestBody, requestParam]);

    return [loading, error, data];
};

export default useFetch;
