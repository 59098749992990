import { UserType } from '~/app/types';
import { exportToCSV } from '~/core/utils';

export const exportUser = (userData: UserType[]): string =>
    exportToCSV<UserType>(
        [
            {
                label: 'Firstname',
                selector: (row) => row.firstname
            },
            {
                label: 'Lastname',
                selector: (row) => row.lastname
            },
            {
                label: 'Telephone',
                selector: (row) => row.phone
            },
            {
                label: 'Email',
                selector: (row) => row.email
            },
            {
                label: 'Role',
                selector: (row) => row.role
            },
            {
                label: 'Status',
                selector: (row) => (row.is_active ? 'Active' : 'Inactive')
            },
        ],
        userData
    );