import { PackageType } from '~/app/types/package';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';
import { CategoryPayload } from '~/app/types/category';

export const get_categories = async (
    query?: string
): Promise<PackageType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/category${query ? `${queryString(query)}` : ''}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_category = async (
    categoryPayload: CategoryPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/category', categoryPayload);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const update_category = async (
    id: string,
    categoryPayload: CategoryPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(
            `/category/${id}`,
            categoryPayload
        );
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const delete_category = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/category/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
