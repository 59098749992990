import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ExportContextProvider } from '~/components/pages/account/admin/ExportContextProvider';

export const FrontDeskLayout = () => {
    const [open, setOpen] = useState(false);

    const [tab, setTab] = useState(0);

    const links: {
        title: string;
        href: string;
    }[] = [
        {
            title: 'My transactions',
            href: '',
        },
        {
            title: 'Clients',
            href: 'clients',
        },
        {
            title: 'Packages',
            href: 'packages',
        },
        {
            title: 'Leaderboard',
            href: 'leaderboard',
        },
    ];

    useEffect(() => {
        links.map((link, index) => {
            if (window.location.href.includes(link.href)) setTab(index);
        });
    }, []);

    return (
        <ExportContextProvider>
            <div
                onClick={() => setOpen(prev => !prev)}
                className="mt-5 flex h-8 w-8 cursor-pointer rounded-md bg-red-600 p-1 sm:hidden    "
            >
                {open ? <XIcon /> : <MenuIcon />}
            </div>
            <div
                className={`${
                    open ? 'flex-col' : ''
                }  mt-4 flex flex-wrap justify-end border-red-600 sm:mt-8 sm:justify-start sm:border-b`}
            >
                {links.map(({ href, title }, index) => (
                    <Link key={index} to={`/account/frontdesk/${href}`}>
                        <div
                            className={` ${
                                open
                                    ? 'block w-full rounded-none'
                                    : 'hidden sm:block'
                            } px-2 py-1 sm:rounded-t ${
                                tab == index
                                    ? 'bg-red-600  text-light '
                                    : 'text-light hover:bg-slate-800'
                            }`}
                            onClick={() => setTab(index)}
                        >
                            {title}
                        </div>
                    </Link>
                ))}
            </div>
            <div className="mt-8">
                <Outlet />
            </div>

            <div className="mt-4"></div>
        </ExportContextProvider>
    );
};
