import * as z from 'zod';
import { toast } from 'react-toastify';
import { RefreshIcon, LockClosedIcon } from '@heroicons/react/outline';
import { NavBar, Button, Form, InputField } from '~/components/elements';
import { useAuthentication } from '~/core/hooks';
import { useState } from 'react';
import { ChangePasswordPayload } from '~/app/types';
import { change_password } from '~/api';

const schema = z
    .object({
        currentPassword: z.string().min(1, 'Previous password is required'),
        newPassword: z
            .string()
            .min(6, 'New password must be at least 6 characters'),
        confirmPassword: z.string().min(1, 'Confirm Password is required'),
    })
    .refine(data => data.newPassword === data.confirmPassword, {
        message: 'Passwords don\'t match',
        path: ['confirmPassword'],
    });

export type ChangePasswordPayloadApi = {
    oldPassword: string;
    newPassword: string;
};

export const ChangePasswordPage = () => {
    useAuthentication({ isAuth: true });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (payload: ChangePasswordPayload) => {
        try {
            setIsLoading(true);
            await change_password({
                newPassword: payload.newPassword,
                oldPassword: payload.currentPassword,
            });
            toast.success('Password changed successfully');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <NavBar />

            <main className="container py-12">
                <div className="mx-auto lg:w-[80%]">
                    <p className="text-center text-2xl font-bold uppercase text-white">
                        Change Your Password
                    </p>

                    <Form<ChangePasswordPayload, typeof schema>
                        schema={schema}
                        onSubmit={handleSubmit}
                        className="mx-auto mt-8 w-full bg-dark-light p-4 md:w-1/2 md:p-10"
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        placeholder="Enter Your Previous Password"
                                        icon={
                                            <LockClosedIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.currentPassword}
                                        registration={register(
                                            'currentPassword'
                                        )}
                                        className="h-12"
                                        isLoading={isLoading}
                                        type="password"
                                    />
                                    <InputField
                                        placeholder="Enter New Password"
                                        icon={
                                            <LockClosedIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.newPassword}
                                        registration={register('newPassword')}
                                        className="h-12"
                                        isLoading={isLoading}
                                        type="password"
                                    />
                                    <InputField
                                        placeholder="Confirm New Password"
                                        icon={
                                            <LockClosedIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.confirmPassword}
                                        registration={register(
                                            'confirmPassword'
                                        )}
                                        className="h-12"
                                        isLoading={isLoading}
                                        type="password"
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    isLoading={isLoading}
                                    className="mt-8 w-full font-semibold"
                                >
                                    <RefreshIcon className="icon-md" />
                                    <span>Change Password</span>
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            </main>
        </>
    );
};
