import { PRIVATE_API } from '../axios';

export const getPaymentDetails = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.get(
            `transactions/transaction/base64/${id}`
        );
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const pay = async (id: string, phoneNumber: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(
            `transactions/payment-form/${id}/submit`,
            phoneNumber
        );
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
