import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
    FieldWrapperPassThroughProps,
    FieldWrapper,
} from '~/components/elements';

type InputFieldProps = FieldWrapperPassThroughProps & {
    type?: 'text' | 'number' | 'email' | 'password' | 'date';
    placeholder?: string;
    className?: string;
    defaultValue?: string;
    registration?: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
    const {
        type = 'text',
        label,
        icon,
        defaultValue = '',
        placeholder = '',
        error,
        registration,
        isLoading,
        className = '',
    } = props;

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    return (
        <FieldWrapper
            label={label}
            icon={icon}
            error={error}
            isLoading={isLoading}
        >
            <span className="flex w-full flex-row items-center justify-center pr-3">
                <input
                    type={
                        type === 'password'
                            ? passwordVisible
                                ? 'text'
                                : 'password'
                            : type
                    }
                    autoComplete="off"
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    disabled={isLoading}
                    className={clsx(
                        'placeholder-gray-500 block w-full appearance-none rounded-md border-0 bg-slate-100 px-3 text-base font-medium text-dark focus:outline-none focus:ring-0',
                        icon ? 'px-0' : 'pl-3',
                        type === 'date' && 'uppercase',
                        className
                    )}
                    {...registration}
                />
                {type === 'password' &&
                    (passwordVisible ? (
                        <EyeIcon
                            className="icon-md cursor-pointer text-dark"
                            onClick={() => setPasswordVisible(false)}
                        />
                    ) : (
                        <EyeOffIcon
                            className="icon-md cursor-pointer text-dark"
                            onClick={() => setPasswordVisible(true)}
                        />
                    ))}
            </span>
        </FieldWrapper>
    );
};

export const InputTextArea = (props: InputFieldProps) => {
    const {
        type = 'text',
        label,
        icon,
        defaultValue = '',
        placeholder = '',
        error,
        registration,
        isLoading,
        className = '',
    } = props;

    return (
        <FieldWrapper
            label={label}
            icon={icon}
            error={error}
            isLoading={isLoading}
        >
            <span className="flex w-full flex-row items-center justify-center pr-3">
                <textarea
                    autoComplete="off"
                    placeholder={placeholder}
                    disabled={isLoading}
                    defaultValue={defaultValue}
                    className={clsx(
                        'placeholder-gray-500 block h-36 w-full appearance-none rounded-md border-0 bg-slate-100 px-3 text-base font-medium text-dark focus:outline-none focus:ring-0',
                        icon ? 'px-0' : 'pl-3',
                        type === 'date' && 'uppercase',
                        className
                    )}
                    {...registration}
                />
            </span>
        </FieldWrapper>
    );
};
