/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { DashCard } from '~/components/elements';
import { format } from '~/core/utils';
import { get_member_stats } from '~/api/stats/member.stats';

export type StatsDataType = {
    balance: number;
    paid: number;
    total: number;
    unpaid: number;
};

const DashMemberStats = () => {
    const [loading, setLoading] = useState(true);

    const [statsData, setStatsData] = useState<StatsDataType>();

    const handleGetStats = async () => {
        try {
            setLoading(true);
            const data = await get_member_stats();
            setStatsData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetStats();
    }, []);

    const statsCards: {
        title: string;
        amount: number | undefined;
    }[] = [
        {
            title: 'Subscription  Amount ',
            amount: statsData?.total,
        },
        {
            title: 'Paid Amount ',
            amount: statsData?.paid,
        },
        {
            title: 'Balance Amount',
            amount: statsData?.balance,
        },
    ];

    return (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {statsCards.map(({ title, amount }, index) => (
                <DashCard
                    label={title}
                    key={index}
                    background="bg-blue-600/70"
                    count={format.currenyWithAmount(amount ?? 0)}
                    icon={<CreditCardIcon className="icon-xl" />}
                    isLoading={loading}
                />
            ))}
        </div>
    );
};

export default DashMemberStats;
