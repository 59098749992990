import { useEffect, useState } from 'react';
import { PRIVATE_API } from '~/api';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { UserType } from '~/app/types';
import { format } from '~/core/utils';
import { CheckCircleIcon } from '@heroicons/react/outline';


type Notification = {
    id: number;
    title: string;
    content: string;
    isRead: boolean;
    created_at: string; // ISO 8601 date string
    updated_at: string; // ISO 8601 date string
    recipient: UserType;
};

export type NotificationResponse = {
    status: string;
    list: Notification[];
    total: number;
    previousPage: number | null;
    nextPage: number | null;
    lastPage: number;
    currentPage: number;
};

const useGetNotification = () => {
    const [notifications, setNotifications] = useState<null | Notification[]>(null);

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const request = await PRIVATE_API.get(
                    '/notifications'
                );
                console.log(request.data);
                return request.data as NotificationResponse;
            } catch (error: any) {
                throw new CustomError(AxiosErrorHandler(error));
            }
        };

        getNotifications().then((data) => setNotifications(data.list));
    }, []);

    return {
        notifications
    };

};

export const useGetNotificationByAdmin = ({ id, pageSize = 10 } : { pageSize?: number, id: string }) => {
    const getNotifications = async () => {
        try {
            const request = await PRIVATE_API.get(
                `/notifications/admin?userId=${id}&pageSize=${pageSize}`
            );
            console.log(request.data);
            return request.data as NotificationResponse;
        } catch (error: any) {
            throw new CustomError(AxiosErrorHandler(error));
        }
    };

    return {
        getNotifications
    };

};

export default function NotificationsPage() {
    const { notifications } = useGetNotification();
    return (
        <div className={''}>
            <h1 className={'text-xl text-white py-3 border-b border-white/30 mb-5'}>Notifications</h1>
            <div className="">
                {notifications?.map((item, index) => <NotificationBox item={item} key={index} /> )}
            </div>
        </div>
    );
}

export function NotificationBox({ item } : { item: Notification }) {
    return (
        <div className={'p-2 bg-dark-light text-light mb-3 rounded'}>
            <div className="p-2 text-lg border-b mb-3 border-slate-200/20">{item.title}</div>
            <div className="px-3">
                {item.content}
            </div>
            <div className="px-2 mt-5 text-sm">{format.humanDateTime(item.created_at)}</div>
            <div className="px-2 mt-3">
                {item.isRead ? <div className="flex flex-row gap-1 text-green-500 text-sm">
                    <CheckCircleIcon width={10} /> Read
                </div> : <div className="flex flex-row gap-1 text-red-500 text-sm">
                    <CheckCircleIcon width={10} /> Unread
                </div>}
            </div>
        </div>
    );
}
