import { ReactNode, useEffect, useState } from 'react';
import { check_user } from '~/api';
import { useLogout } from '~/core/hooks/useLogout';
import { addUser } from '~/core/redux/slices/user/userAccountSlice';
import { useDispatch } from 'react-redux';
import { Spinner } from '~/components/elements';
import { storage } from '~/core/utils';

type AuthProviderProps = {
    children: ReactNode;
};

const useWhoAmI = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const { logout } = useLogout();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await check_user();
                dispatch(addUser(user));
            } catch (error) {
               logout();
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();

    }, []);

    return {
        isLoading,
    };
};

export const AuthProvider = (props: AuthProviderProps) => {
    const { children } = props;

    const [isReady, setIsReady] = useState(false);
    const { isLoading } = useWhoAmI();

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setIsReady(true);
            }, 100);
        }
    }, [isLoading]);

    return (
        <div>
            {isLoading ? (
                <div className="screen_loader fixed inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated">
                    <Spinner variant="light" />
                </div>
            ) : (
                <>
                    {children}
                </>
            )}
        </div>
    );
};
