import { Link } from 'react-router-dom';
import { IdentificationIcon, LoginIcon } from '@heroicons/react/outline';
import { Profile } from '~/components/elements';

import LogoImage from '~/assets/images/logo-dark.png';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';

export const NavBar = () => {
    const { userAccount } = useSelector((state: StoreType) => state.user);
    // const {setAddCardModalIsOpen, addCardModalIsOpen} = useContext<CommonContextType>(CommonContext);
    return (
        <div className="sticky top-0 z-50 w-full bg-white">
            <div className="container flex h-16 items-center justify-between">
                <div>
                    <Link to={userAccount.email ? '/account' : '/'}>
                        <img
                            alt="Waka Global"
                            src={LogoImage}
                            className="w-36"
                        />
                    </Link>
                </div>

                <div>
                    {!userAccount.username && !window.location.href.includes('') ? (
                        <Link
                            to={`/login`}
                            className="btn btn-danger btn-md"
                        >
                            <>
                                <LoginIcon className="icon-md" />
                                <span>Log In</span>
                            </>
                        </Link>
                    ) : null}

                    {!userAccount.username && (
                        <Link
                            to={`/${
                                window.location.href.includes('login')
                                    ? ''
                                    : 'login'
                            }`}
                            className="btn btn-danger btn-md"
                        >
                            {window.location.href.includes('login') ? (
                                <>
                                    <IdentificationIcon className="icon-md" />
                                    <span>Register</span>
                                </>
                            ) : (
                                <>
                                    <LoginIcon className="icon-md" />
                                    <span>Log In</span>
                                </>
                            )}
                        </Link>
                    )}

                    {userAccount.username && (
                        <div className="flex gap-2 sm:gap-4">
                            {userAccount.role === 'member' && (
                                <>
                                    {/* <Button
                                        className='btn btn-success btn-md px-3 sm:px-4'
                                        onClick={()=> setAddCardModalIsOpen(!addCardModalIsOpen)}
                                    >
                                        <PlusCircleIcon className='icon-md' />
                                        <span className='hidden sm:block'>Add Card</span>
                                    </Button> */}

                                    {/* <Link
                                        to='member/add'
                                        className='btn btn-success btn-md px-3 sm:px-4'
                                    >
                                        <PlusCircleIcon className='icon-md' />
                                        <span className='hidden sm:block'>Add Member</span>
                                    </Link> */}
                                </>
                            )}

                            <Profile />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
