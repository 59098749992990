/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    InputTextArea,
    Modal,
    SelectField,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import {
    CheckCircleIcon,
    EyeIcon,
    LocationMarkerIcon,
    PencilAltIcon,
    TrashIcon,
    XCircleIcon,
} from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useContext, useEffect, useState } from 'react';
import { PackagePayload, PackageType } from '~/app/types/package';
import {
    assign_location,
    create_package,
    delete_package,
    disable_package,
    get_locations,
    get_packages,
    update_package,
} from '~/api';
import { ExportContext, ExportContextType } from './ExportContextProvider';
import { exportPackage } from '~/core/helper/csv/package';
import { useLocation } from 'react-router-dom';
import { AssignLocationPayload, LocationType } from '~/app/types/location';
import { z } from 'zod';
import { get_categories } from '~/api/category';
import { useDisclosure } from '~/core/hooks';
import { CategoryType } from '~/app/types/category';
import { ExtractArrayFromString } from '~/components/containers';

type OptionType = {
    value: string;
    label: string;
};

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required.' }),
    categoryId: z.string().min(1),
    price: z.string().min(1, { message: 'Price is required.' }),
    joining_fee: z.string().optional(),
    description: z.string().optional(),
    recurringStatus: z.string().min(1, { message: 'Recurring Status is required.' }),
    numberOfMonths: z.string().optional(),
    headline: z.string().optional(),
});

const update_schema = z.object({
    name: z.string().optional(),
    categoryId: z.string().optional(),
    price: z.string().optional(),
    joining_fee: z.string().optional(),
    description: z.string().optional(),
    recurringStatus: z.string().optional(),
    numberOfMonths: z.string().optional(),
    headline: z.string().optional(),
});

const assignSchema = z.object({
    locationId: z.string().min(1),
});

export const DashAdminPackage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingAssign, setIsLoadingAssign] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const openCreatePackage = useDisclosure();
    const openEdit = useDisclosure();
    const openDelete = useDisclosure();
    const openViewPackage = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selected, setSelected] = useState<PackageType | null>(null);
    const [packages, setPackages] =
        useState<PaginationType<PackageType> | null>();

    const [locations, setLocations] = useState<LocationType[]>([]);

    const [categories, setCategories] = useState([]);

    const { search: query } = useLocation();

    const { setExportData } = useContext<ExportContextType>(ExportContext);

    const getLocations = async () => {
        try {
            const locs: PaginationType<LocationType> = await get_locations(
                query
            );
            setLocations(locs.list);
        } catch (error: any) {
            toast.error(error.response.message);
        }
    };

    const getCategories = async () => {
        try {
            const categories = await get_categories();
            setCategories(categories.list);
        } catch (error: any) {
            toast.error(error.response.message);
        }
    };

    const formatedCategories: OptionType[] = categories.map(
        (category: CategoryType) => {
            return {
                value: category.id.toString(),
                label: `${category.name}`,
            };
        }
    );

    const formatedLocations: OptionType[] = locations.map(
        (location: LocationType) => {
            return {
                value: location.id.toString(),
                label: `${location.name}`,
            };
        }
    );

    const handleGetPackages = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<PackageType> = await get_packages(query);
            setPackages(data);
            setExportData(exportPackage(data.list ?? []));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<PackageType>[] = [
        {
            title: 'Package Name',
            cell: row => row.name,
        },
        {
            title: 'Price',
            cell: row => format.currenyWithAmount(row.price),
        },
        {
            title: 'Joining Fee',
            cell: row => format.currenyWithAmount(row.joining_fee),
        },
        {
            title: 'Recurring',
            cell: row => (
                <p
                    className={` font-semibold ${
                        row.recurringStatus === 'true'
                            ? 'text-green-600'
                            : 'text-yellow-600'
                    }`}
                >
                    {row.recurringStatus && row.recurringStatus === 'true'
                        ? `${row.numberOfMonths} month${
                              row.numberOfMonths && row.numberOfMonths > 1
                                  ? 's'
                                  : ''
                          }`
                        : row.recurringStatus === 'false'
                        ? 'Not Recurring'
                        : 'N/A'}
                </p>
            ),
        },
        {
            title: 'Status',
            cell: row => row.status,
        },
        // {
        //     title: 'Updated at',
        //     cell: row => row.updated_at,
        // },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-5">
                    <EyeIcon
                        onClick={() => {
                            setSelected(row);
                            openViewPackage.onOpen();
                        }}
                        className="w-5 cursor-pointer"
                    />
                    <TrashIcon
                        className="w-5"
                        onClick={() => {
                            openDelete.onOpen();
                            setSelected(row);
                        }}
                    />
                    <PencilAltIcon
                        className="w-5"
                        onClick={() => {
                            openEdit.onOpen();
                            setSelected(row);
                        }}
                    />
                    <LocationMarkerIcon
                        className="w-5"
                        onClick={() => {
                            onOpen();
                            setSelected(row);
                        }}
                    />
                    {row.status === 'Active' ? (
                        <XCircleIcon
                            className="w-5 text-red-600"
                            onClick={() => {
                                setSelected(row);
                                handleDisable('Inactive');
                            }}
                        />
                    ) : (
                        <CheckCircleIcon
                            className="w-5 text-green-600"
                            onClick={() => {
                                setSelected(row);
                                handleDisable('Active');
                            }}
                        />
                    )}
                </div>
            ),
        },
    ];

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            const data = await create_package(e).then(() => {
                toast.success('Package Created');
                openCreatePackage.onClose();
            });
        } catch (error: any) {
            console.log(error.response);
            toast.error(`Package not created. ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleEdit = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            console.log(e);
            await update_package(selected!, e).then(() => {
                toast.success('Package Updated');
                openEdit.onClose();
            });
        } catch (error) {
            toast.error(`Package not Updated. ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDisable = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            await disable_package(selected!, e).then(() => {
                toast.success(
                    `Package ${
                        selected?.status === 'Active' ? 'Disabled' : 'Enabled'
                    }`
                );
            });
        } catch (error) {
            toast.error(`Package not disabled. ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleAssign = async (e: any) => {
        try {
            setIsLoadingAssign(true);
            await assign_location({
                locationId: e,
                package_id: selected!.id,
            }).then(() => {
                toast.success('Location Assigned');
                onClose();
            });
        } catch (error) {
            toast.error(`Location not assigned. ${error}`);
        } finally {
            setIsLoadingAssign(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_package(selected ? selected.id : '').then(() => {
                toast.success('package deleted');
                openDelete.onClose();
            });
        } catch (error) {
            toast.error(`package not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        getLocations();
        getCategories();
        handleGetPackages();
    }, [
        query,
        isLoadingAssign,
        isLoadingDelete,
        isLoadingCreate,
        isLoadingUpdate,
    ]);

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-end p-2">
                    <button
                        onClick={() => openCreatePackage.onOpen()}
                        className="rounded-md bg-red-600 px-2 py-1 text-light"
                    >
                        Create Package
                    </button>
                </div>
                <Modal
                    title="Create Package"
                    size="md:w-1/2 max-w-[800px] w-11/12"
                    isOpen={openCreatePackage.isOpen}
                    onClose={openCreatePackage.onClose}
                >
                    <Form<PackagePayload, typeof schema>
                        schema={schema}
                        onSubmit={handleCreate}
                    >
                        {({ register, watch, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <div className="grid grid-cols-2 gap-2">
                                        <InputField
                                            type="text"
                                            registration={register('name')}
                                            className="h-12"
                                            error={formState.errors.name}
                                            label="Package Name"
                                            isLoading={isLoadingCreate}
                                        />

                                        <InputField
                                            type="number"
                                            registration={register('price')}
                                            className="h-12"
                                            label="Price"
                                            error={formState.errors.price}
                                            isLoading={isLoadingCreate}
                                        />

                                    </div>

                                    <div className="grid grid-cols-2 gap-2">
                                        <InputField
                                            type="number"
                                            registration={register('joining_fee')}
                                            className="h-12"
                                            label="Joining Fee"
                                            isLoading={isLoadingCreate}
                                            error={formState.errors.joining_fee}
                                        />
                                        <SelectField
                                            label="Category"
                                            options={formatedCategories}
                                            registration={register('categoryId')}
                                            className="h-12"
                                            error={formState.errors.categoryId}
                                            isLoading={isLoadingCreate}
                                        />

                                    </div>

                                    <div className="grid grid-cols-2 gap-2">
                                        <SelectField
                                            placeholder="Choose"
                                            label="Recurring"
                                            options={[
                                                {
                                                    label: 'Yes',
                                                    value: 'true',
                                                },
                                                {
                                                    label: 'No',
                                                    value: 'false',
                                                },
                                            ]}
                                            registration={register(
                                                'recurringStatus'
                                            )}
                                            className="h-12"
                                            error={formState.errors.recurringStatus}
                                            isLoading={isLoadingCreate}
                                        />
                                        <InputField
                                            type="number"
                                            registration={register(
                                                'numberOfMonths'
                                            )}
                                            className="h-12"
                                            label="Number of months"
                                            isLoading={isLoadingCreate}
                                            error={
                                                formState.errors.numberOfMonths
                                            }
                                        />

                                    </div>

                                    <InputField
                                        type="text"
                                        registration={register('headline')}
                                        className="h-12"
                                        label="Headline"
                                        isLoading={isLoadingCreate}
                                        error={formState.errors.headline}
                                    />

                                    <InputTextArea
                                        placeholder="Description"
                                        isLoading={isLoadingCreate}
                                        error={formState.errors.description}
                                        registration={register('description')}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingCreate}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <DataTable
                columns={columns}
                data={packages?.list ?? []}
                isLoading={isLoading}
                total={packages?.total ?? 0}
                lastPage={packages?.lastPage ?? 0}
                nextPage={packages?.nextPage ?? 0}
                currentPage={packages?.currentPage ?? 0}
                previousPage={packages?.previousPage ?? 0}
            />
            <div className="">
                <Modal
                    title={`Assign Location: ${selected?.name}`}
                    size="md:w-1/3 max-w-[500px] w-11/12"
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <Form<AssignLocationPayload, typeof assignSchema>
                        schema={assignSchema}
                        onSubmit={handleAssign}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <SelectField
                                        placeholder="Location"
                                        options={formatedLocations}
                                        registration={register('locationId')}
                                        className="h-12"
                                        error={formState.errors.locationId}
                                        isLoading={isLoadingAssign}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingAssign}
                                    >
                                        Assign
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <div className="">
                <Modal
                    title="Update Package"
                    size="md:w-1/2 max-w-[500px] w-11/12"
                    isOpen={openEdit.isOpen}
                    onClose={openEdit.onClose}
                >
                    <Form<PackagePayload, typeof update_schema>
                        schema={update_schema}
                        onSubmit={handleEdit}
                    >
                        {({ register, watch, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="text"
                                        registration={register('name')}
                                        className="h-12"
                                        error={formState.errors.name}
                                        label="Package Name"
                                        defaultValue={selected?.name}
                                        isLoading={isLoadingUpdate}
                                    />

                                    <InputField
                                        type="number"
                                        registration={register('price')}
                                        className="h-12"
                                        label="Price"
                                        defaultValue={selected?.price.toString()}
                                        error={formState.errors.price}
                                        isLoading={isLoadingUpdate}
                                    />

                                    <InputField
                                        type="number"
                                        registration={register('joining_fee')}
                                        className="h-12"
                                        label="Joining Fee"
                                        defaultValue={selected?.joining_fee.toString()}
                                        isLoading={isLoadingUpdate}
                                        error={formState.errors.joining_fee}
                                    />

                                    <SelectField
                                        label="Category"
                                        options={formatedCategories}
                                        registration={register('categoryId')}
                                        className="h-12"
                                        error={formState.errors.categoryId}
                                        isLoading={isLoadingUpdate}
                                    />

                                    <SelectField
                                        label="Recurring"
                                        placeholder="Choose"
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'true',
                                            },
                                            {
                                                label: 'No',
                                                value: 'false',
                                            },
                                        ]}
                                        registration={register(
                                            'recurringStatus'
                                        )}
                                        className="h-12"
                                        error={formState.errors.recurringStatus}
                                        isLoading={isLoadingCreate}
                                    />
                                    {selected?.recurringStatus === 'true' ||
                                    watch('recurringStatus') === 'true' ? (
                                        <InputField
                                            type="number"
                                            registration={register(
                                                'numberOfMonths'
                                            )}
                                            className="h-12"
                                            label="Number of months"
                                            isLoading={isLoadingCreate}
                                            error={
                                                formState.errors.numberOfMonths
                                            }
                                        />
                                    ) : (
                                        <InputField
                                            type="text"
                                            registration={register('headline')}
                                            className="h-12"
                                            label="Headline"
                                            isLoading={isLoadingUpdate}
                                            defaultValue={selected?.headline}
                                            error={formState.errors.headline}
                                        />
                                    )}

                                    <InputTextArea
                                        label="Description"
                                        defaultValue={extractString(selected?.description ?? '')}
                                        isLoading={isLoadingUpdate}
                                        error={formState.errors.description}
                                        registration={register('description')}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingUpdate}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
                <Modal
                    title="Delete Package"
                    size="md:w-1/2 max-w-[500px] w-11/12"
                    isOpen={openDelete.isOpen}
                    onClose={openDelete.onClose}
                >
                    <form onSubmit={handleDelete}>
                        <div className="min-w-80">
                            <div className=" text-sm text-light md:text-lg">
                                Are you sure you want to delete{' '}
                                <span className="mx-1 font-bold text-red-600">
                                    {selected?.name}
                                </span>{' '}
                                Package?
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingDelete}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div>
            <div className="">
                <Modal
                    title={selected?.name}
                    isOpen={openViewPackage.isOpen}
                    onClose={openViewPackage.onClose}
                    size="w-auto min-w-[300px]"
                >
                    <div className="flex w-full items-center justify-center">
                        <div className="flex w-full flex-col gap-2 border border-slate-700 p-5 text-light">
                            <div className="flex w-full items-center justify-center text-xl font-bold">
                                {selected?.name}
                            </div>
                            <div className="flex flex-row gap-2">
                                <h1 className="font-bold underline">Price:</h1>
                                <p>
                                    {format.currenyWithAmount(
                                        selected?.price || 0
                                    )}
                                </p>
                            </div>
                            {selected?.recurringStatus === 'true' ? (
                                <div className="flex flex-row gap-2">
                                    <h1 className="font-bold underline">
                                        Recurring:
                                    </h1>
                                    <p>
                                        {selected.numberOfMonths} Month
                                        {selected.numberOfMonths
                                            ? selected.numberOfMonths > 1 && 's'
                                            : ''}
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="flex flex-row gap-2">
                                <h1 className="font-bold underline">
                                    Joining Fee:
                                </h1>
                                <p>
                                    {format.currenyWithAmount(
                                        selected?.joining_fee || 0
                                    )}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <h1 className="font-bold underline">
                                    Category:
                                </h1>
                                <p>{selected?.category.name}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <h1 className="font-bold underline">
                                    Headline:
                                </h1>
                                <p>{selected?.headline}</p>
                            </div>
                            <h1 className="font-bold underline">Description</h1>
                            <ul className="max-w-[500px]  p-5 pt-0">
                                {selected?.description &&
                                    ExtractArrayFromString(
                                        selected.description
                                    ).map((elem, item) => (
                                        <li
                                            key={item}
                                            className="my-2 list-disc"
                                        >
                                            {elem}
                                        </li>
                                    ))}
                            </ul>
                            <p></p>
                            {selected?.location.map((locs, index) => (
                                <div
                                    key={index}
                                    className="border-y border-slate-700 py-3 font-semibold"
                                >
                                    <span className="mr-2 text-lg font-bold">
                                        {locs.name}
                                    </span>
                                    {locs.district}, {locs.address}
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

function extractString(input: string): string {
    // Extracting the string between the single quotes
    const startIndex = input.indexOf("'");
    const endIndex = input.lastIndexOf("'");
    return input.substring(startIndex + 1, endIndex);
}
