/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    InputTextArea,
    Modal,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useEffect, useState } from 'react';
import {
    create_category,
    delete_category,
    get_categories,
    update_category,
} from '~/api';
import { CategoryPayload, CategoryType } from '~/app/types/category';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
});

const update_schema = z.object({
    name: z.string(),
    description: z.string(),
});

export const DashAdminCategories = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<CategoryType>({
        name: '',
        status: '',
        description: '',
        created_at: '',
        updated_at: '',
        id: '',
    });
    const openCreateCategory = useDisclosure();
    const openUpdateCategory = useDisclosure();
    const openDeleteCategory = useDisclosure();
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [categories, setCategories] =
        useState<PaginationType<CategoryType> | null>();

    const query = location.search;

    const getCategories = async () => {
        try {
            const categories = await get_categories(query);
            setCategories(categories);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<CategoryType>[] = [
        {
            title: 'Category Name',
            cell: row => row.name,
        },
        {
            title: 'Description',
            cell: row => (
                <p className="scrollbar-hidden max-h-[200px] overflow-y-scroll">
                    {row.description === null ? 'N/A' : row.description}
                </p>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-5">
                    <TrashIcon
                        onClick={() => {
                            setSelected(row);
                            openDeleteCategory.onOpen();
                        }}
                        className="w-5"
                    />
                    <PencilAltIcon
                        onClick={() => {
                            setSelected(row);
                            openUpdateCategory.onOpen();
                        }}
                        className="w-5"
                    />
                </div>
            ),
        },
    ];

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            const data = await create_category(e).then(() => {
                toast.success('Category Created');
                openCreateCategory.onClose();
            });
        } catch (error) {
            toast.error(`Category not created: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            await update_category(selected.id, {
                name: e.name === '' ? selected?.name : e.name,
                description:
                    e.description === '' ? selected.description : e.description,
            }).then(() => {
                toast.success('Category updated');
                openUpdateCategory.onClose();
            });
        } catch (error) {
            toast.error(`Category not updated: ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_category(selected.id).then(() => {
                toast.success('Category deleted');
                openDeleteCategory.onClose();
            });
        } catch (error) {
            toast.error(`Category not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        getCategories();
    }, [isLoadingCreate, isLoadingDelete, isLoadingUpdate]);

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-end p-2">
                    <button
                        onClick={() => openCreateCategory.onOpen()}
                        className="rounded-md bg-red-600 px-2 py-1 text-light"
                    >
                        Create Category
                    </button>
                </div>
                <Modal
                    title="Create Category"
                    size="md:w-1/2 max-w-[500px] w-11/12"
                    isOpen={openCreateCategory.isOpen}
                    onClose={openCreateCategory.onClose}
                >
                    <Form<CategoryPayload, typeof schema>
                        schema={schema}
                        onSubmit={handleCreate}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="text"
                                        registration={register('name')}
                                        className="h-12"
                                        error={formState.errors.name}
                                        label="Category Name"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputTextArea
                                        label="Description"
                                        isLoading={isLoadingCreate}
                                        error={formState.errors.description}
                                        registration={register('description')}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingCreate}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <DataTable
                columns={columns}
                data={categories?.list ?? []}
                isLoading={isLoading}
                total={categories?.total ?? 0}
                lastPage={categories?.lastPage ?? 0}
                nextPage={categories?.nextPage ?? 0}
                currentPage={categories?.currentPage ?? 0}
                previousPage={categories?.previousPage ?? 0}
            />
            <Modal
                title="Delete Category"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDeleteCategory.isOpen}
                onClose={openDeleteCategory.onClose}
            >
                <form onSubmit={handleDelete}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to delete{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selected.name}
                            </span>{' '}
                            category?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal
                title="Update Category"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateCategory.isOpen}
                onClose={openUpdateCategory.onClose}
            >
                <Form<CategoryPayload, typeof update_schema>
                    schema={update_schema}
                    onSubmit={handleUpdate}
                >
                    {({ register, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <InputField
                                    type="text"
                                    registration={register('name')}
                                    className="h-12"
                                    error={formState.errors.name}
                                    label="Update Category Name"
                                    placeholder={selected.name}
                                    isLoading={isLoadingUpdate}
                                />
                                <InputTextArea
                                    label="Description"
                                    isLoading={isLoadingUpdate}
                                    error={formState.errors.description}
                                    registration={register('description')}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingUpdate}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};
