import config from '~/config';

export const storage = {
    getToken: (): string | null => {
        const token = localStorage.getItem(`${config.STORAGE_PREFIX}token`);
        return token ? JSON.parse(token) : null;
    },
    setToken: (value: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem(
                    `${config.STORAGE_PREFIX}token`,
                    JSON.stringify(value)
                );
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    removeToken: (): void => {
        localStorage.removeItem(`${config.STORAGE_PREFIX}token`);
    },
};

