/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DataTable, Modal, TableColumn } from '~/components/elements';
import { format } from '~/core/utils';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useEffect, useState } from 'react';
import { PackageType } from '~/app/types/package';
import { get_packages } from '~/api';
import { useLocation } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/outline';
import { useDisclosure } from '~/core/hooks';
import { ExtractArrayFromString } from '~/components/containers';

export const DashFrontDeskPackage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [packages, setPackages] =
        useState<PaginationType<PackageType> | null>();
    const [selected, setSelected] = useState<PackageType | null>(null);
    const open = useDisclosure();

    const location = useLocation();
    const query = location.search;

    const handleGetPackages = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<PackageType> = await get_packages(query);
            setPackages(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<PackageType>[] = [
        {
            title: 'Package Name',
            cell: row => row.name,
        },
        {
            title: 'Price',
            cell: row => row.price,
        },
        {
            title: 'Recurring',
            cell: row => (
                <p
                    className={` font-semibold ${
                        row.recurringStatus === 'true'
                            ? 'text-green-600'
                            : 'text-yellow-600'
                    }`}
                >
                    {row.recurringStatus && row.recurringStatus === 'true'
                        ? `${row.numberOfMonths} month${
                              row.numberOfMonths && row.numberOfMonths > 1
                                  ? 's'
                                  : ''
                          }`
                        : row.recurringStatus === 'false'
                        ? 'Not Recurring'
                        : 'N/A'}
                </p>
            ),
        },
        {
            title: 'Joining Fee',
            cell: row => format.currenyWithAmount(row.joining_fee),
        },
        {
            title: 'Status',
            cell: row => row.status,
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex items-center justify-center gap-2">
                    <EyeIcon
                        onClick={() => {
                            setSelected(row);
                            open.onOpen();
                        }}
                        className="w-5 cursor-pointer"
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        handleGetPackages();
    }, [query]);

    return (
        <>
            <DataTable
                columns={columns}
                data={packages?.list ?? []}
                isLoading={isLoading}
                total={packages?.total ?? 0}
                lastPage={packages?.lastPage ?? 0}
                nextPage={packages?.nextPage ?? 0}
                currentPage={packages?.currentPage ?? 0}
                previousPage={packages?.previousPage ?? 0}
            />
            <Modal
                title={selected?.name}
                isOpen={open.isOpen}
                onClose={open.onClose}
                size="w-auto"
            >
                <div className="flex items-center justify-center">
                    <div className="flex flex-col gap-2 border border-slate-700 p-5 text-light">
                        <div className="flex w-full items-center justify-center text-xl font-bold">
                            {selected?.name}
                        </div>
                        <div className="flex flex-row gap-2">
                            <h1 className="font-bold underline">Price:</h1>
                            <p>
                                {format.currenyWithAmount(selected?.price || 0)}
                            </p>
                        </div>
                        {selected?.recurringStatus === 'true' ? (
                            <div className="flex flex-row gap-2">
                                <h1 className="font-bold underline">
                                    Recurring:
                                </h1>
                                <p>
                                    {selected.numberOfMonths} Month
                                    {selected.numberOfMonths
                                        ? selected.numberOfMonths > 1 && 's'
                                        : ''}
                                </p>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="flex flex-row gap-2">
                            <h1 className="font-bold underline">
                                Joining Fee:
                            </h1>
                            <p>
                                {format.currenyWithAmount(
                                    selected?.joining_fee || 0
                                )}
                            </p>
                        </div>
                        <div className="flex flex-row gap-2">
                            <h1 className="font-bold underline">Category:</h1>
                            <p>{selected?.category.name}</p>
                        </div>
                        <h1 className="font-bold underline">Description</h1>
                        <ul className="max-w-[500px]  p-5 pt-0">
                            {selected &&
                                ExtractArrayFromString(
                                    selected?.description || ''
                                ).map((elem, item) => (
                                    <li key={item} className="my-2 list-disc">
                                        {elem}
                                    </li>
                                ))}
                        </ul>
                        <p></p>
                        {selected?.location.map((locs, index) => (
                            <div
                                key={index}
                                className="border-y border-slate-700 py-3 font-semibold"
                            >
                                <span className="mr-2 text-lg font-bold">
                                    {locs.name}
                                </span>
                                {locs.district}, {locs.address}
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    );
};
