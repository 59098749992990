import { createSlice } from '@reduxjs/toolkit';

const cardSlice = createSlice({
    name: 'cardData',
    initialState: {
        cardData: [],
    },
    reducers: {
        addCard: (state, action) => {
            state.cardData = action.payload;
        },
        removeCard: (state) => {
            state.cardData = [];
        },
        updateCard: (state, action) => {
            state.cardData = { ...state.cardData, ...action.payload };
        },
    },
});

export const { addCard, removeCard, updateCard } = cardSlice.actions;

export default cardSlice.reducer;
