/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    InputTextArea,
    Modal,
    SelectField,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useEffect, useState } from 'react';
import { PackagePayload, PackageType } from '~/app/types/package';
import {
    create_cronjob,
    delete_cronjob,
    get_cronjobs,
    get_packages,
    update_cronjob,
} from '~/api';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';
import { CronjobPayload, CronjobType } from '~/app/types/cron';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import {
    CronsFilterPayload,
    DashFilterCronsComponent,
} from '../common/DashAdminFilterCron';

type OptionType = {
    value: string;
    label: string;
};

const schema = z.object({
    packageId: z.string().min(1, { message: 'Package is required' }),
    scheduled_on: z.string().min(1, { message: 'Date Scheduled is required' }),
    numberOfMonths: z
        .string()
        .min(1, { message: 'Number of months is required' }),
});

const update_schema = z.object({
    packageId: z.string(),
    scheduled_on: z.string(),
    numberOfMonths: z.string(),
});

export const DashAdminCronJobs = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<CronjobType>();
    const openCreateJob = useDisclosure();
    const openUpdateJob = useDisclosure();
    const openDeleteJob = useDisclosure();
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [cronjobs, setCronjobs] =
        useState<PaginationType<CronjobType> | null>();

    const query = location.search;

    const { id } = useSelector((state: StoreType) => state.user.userAccount);

    const getCronJob = async () => {
        try {
            const crons = await get_cronjobs(query);
            setCronjobs(crons);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<CronjobType>[] = [
        {
            title: 'User',
            cell: row => row.user.firstname,
        },
        {
            title: 'Email',
            cell: row => row.user.email,
        },
        {
            title: 'Scheduled On',
            cell: row => format.humanDate(row.scheduled_on),
        },
        {
            title: 'Completed',
            cell: row => (
                <p
                    className={
                        row.completed ? 'text-green-600' : 'text-yellow-600'
                    }
                >
                    {row.completed ? 'COMPLETED' : 'PENDING'}
                </p>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-5">
                    <TrashIcon
                        onClick={() => {
                            setSelected(row);
                            openDeleteJob.onOpen();
                        }}
                        className="w-5"
                    />
                    <PencilAltIcon
                        onClick={() => {
                            setSelected(row);
                            openUpdateJob.onOpen();
                        }}
                        className="w-5"
                    />
                </div>
            ),
        },
    ];

    const [packages, setPackages] = useState([]);

    const getPackages = async () => {
        try {
            const data = await get_packages();
            setPackages(data.list);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const formattedPackages: OptionType[] = packages?.map(
        (pack: PackageType) => {
            return {
                value: pack.id.toString(),
                label: `${pack.name}`,
            };
        }
    );

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            await create_cronjob(id, e).then(() => {
                toast.success('cronjob Created');
                openCreateJob.onClose();
            });
        } catch (error) {
            toast.error(`cronjob not created: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            await update_cronjob(id, selected, e).then(() => {
                toast.success('cronjob updated');
                openCreateJob.onClose();
            });
        } catch (error) {
            toast.error(`cronjob not updated: ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_cronjob(selected ? selected.id : '').then(() => {
                toast.success('cronjob deleted');
                openDeleteJob.onClose();
            });
        } catch (error) {
            toast.error(`cronjob not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        getCronJob();
        getPackages();
    }, [query, isLoadingCreate, isLoadingDelete, isLoadingUpdate]);

    const [filters, setFilters] = useState<CronsFilterPayload>({
        completed: '',
        from: '',
        to: '',
    });

    return (
        <>
            <div className="flex justify-end p-3">
                <DashFilterCronsComponent
                    filters={filters}
                    onFilter={(payload: CronsFilterPayload) => {
                        setFilters(payload);
                    }}
                />
            </div>
            <DataTable
                columns={columns}
                data={cronjobs?.list ?? []}
                isLoading={isLoading}
                total={cronjobs?.total ?? 0}
                lastPage={cronjobs?.lastPage ?? 0}
                nextPage={cronjobs?.nextPage ?? 0}
                currentPage={cronjobs?.currentPage ?? 0}
                previousPage={cronjobs?.previousPage ?? 0}
            />
            <Modal
                title="Delete CronJob"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDeleteJob.isOpen}
                onClose={openDeleteJob.onClose}
            >
                <form onSubmit={handleDelete}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to delete{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selected?.user.firstname}
                            </span>{' '}
                            cronjob?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal
                title="Update cronjob"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateJob.isOpen}
                onClose={openUpdateJob.onClose}
            >
                <Form<CronjobPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleUpdate}
                >
                    {({ register, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <SelectField
                                    label="Select Package"
                                    options={formattedPackages}
                                    isLoading={isLoadingCreate}
                                    error={formState.errors.packageId}
                                    registration={register('packageId')}
                                />
                                <InputField
                                    type="date"
                                    placeholder={selected?.scheduled_on}
                                    registration={register('scheduled_on')}
                                    className="h-12"
                                    error={formState.errors.scheduled_on}
                                    label="Scheduled On"
                                    isLoading={isLoadingUpdate}
                                />
                                <InputField
                                    type="number"
                                    registration={register('numberOfMonths')}
                                    className="h-12"
                                    error={formState.errors.numberOfMonths}
                                    label="Number of Months"
                                    isLoading={isLoadingUpdate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingUpdate}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};
