import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { StoreType } from '~/app/types/redux';
import { storage } from '~/core/utils';
import { ProtectorPropsType } from '../types';

export const HomeRouteProtector = (): JSX.Element => {

    const token = storage.getToken();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const role = userAccount.role;

    if (!token || !role) return <Outlet />;

    return <Navigate to={`/account/${userAccount.role}`} />;
};
