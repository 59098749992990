import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { StoreType } from '~/app/types/redux';
import { storage } from '~/core/utils';

export const AccountProtector = (): JSX.Element => {
    const token = storage.getToken();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const role = userAccount.role;

    if (!token) return <Navigate to={'/login'} />;

    if (role === 'admin') return <Navigate to={'/account/admin'} />;

    if (role === 'member') return <Navigate to={'/account/member'} />;

    if (role === 'frontdesk') return <Navigate to={'/account/frontdesk'} />;

    return <div className="text-center text-white">Loading</div>;
};
