/* eslint-disable no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    Modal,
    SelectField,
    Spinner,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import { useEffect, useState } from 'react';
import {
    ServiceTransactionPayload,
    ServiceType,
    TransactionFilterPayload,
    TransactionType,
} from '~/app/types';
import {
    add_service_transaction,
    get_frontdesk_transactions,
    get_services,
    get_transactions,
    retry_transaction,
} from '~/api';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useLocation } from 'react-router-dom';
import { DashFilterComponent } from '../common/DashAdminFilter';
import { z } from 'zod';
import RetryComponent from '../common/RetryComponent';
import { useDisclosure } from '~/core/hooks';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { PlusCircleIcon } from '@heroicons/react/outline';

export type RetryTransactionType = {
    id: string;
    loading: boolean;
};

type OptionType = {
    value: string;
    label: string;
};

const createSchema = z.object({
    phoneNumber: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must start with 2507' }
    ),
    serviceId: z.string().min(1, { message: 'Service is required' }),
});

export const DashFrontDeskMyTransactions = () => {
    const location = useLocation();
    const query = location.search;

    const [filters, setFilters] = useState<TransactionFilterPayload>({
        location_id: '',
        package_id: '',
        user_id: '',
        from: '',
        to: '',
        status: '',
    });

    const { isOpen, onClose, onOpen } = useDisclosure();
    const openCreateTransaction = useDisclosure();
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [selected, setSelected] = useState({ id: '' });
    const [keyword, setKeyword] = useState('');
    const { id } = useSelector((state: StoreType) => state.user.userAccount);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [transactions, setTransactions] =
        useState<PaginationType<TransactionType> | null>();

    const handleGetTransactions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<TransactionType> =
                await get_frontdesk_transactions(id, keyword, query);
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<TransactionType>[] = [
        // {
        //     title: '',
        //     cell: () => (
        //         <div className="mx-auto h-10 w-10 md:h-12 md:w-12">
        //             <img
        //                 alt="Honore"
        //                 src={UserImage}
        //                 className="h-full w-full rounded-full"
        //             />
        //         </div>
        //     ),
        // },
        {
            title: 'Date Time',
            cell: row =>
                format.humanDateTime(
                    row.updated_at
                ),
        },
        {
            title: 'Member',
            cell: row => row.data ?(
                <>
                    <p className="capitalize">{`${row.user?.firstname} ${row.user?.lastname}`}</p>
                    <p>{row.user?.email}</p>
                </>
            ) : 'N/A',
        },
        {
            title: 'Package',
            cell: row => (
                <p className="capitalize">{`${row.subscription ? row.subscription.package.name : '-'}`}</p>
            ),
        },
        {
            title: 'Amount',
            cell: row => format.currenyWithAmount(row.amount),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() !== 'success' && (
                        <span className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
        {
            title: 'Message',
            cell: row =>
                row.data == null ? '-' : row.data.response.gatewayCode,
        },
        {
            title: 'Payment Mode',
            cell: row => row.payment_mode.toUpperCase(),
        },
        {
            title: 'ACTION',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' &&
                        row.status.toLowerCase() === 'success' && (
                            <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                        )}
                    {row.status.toLowerCase() !== 'success' && (
                        <button
                            onClick={() => {
                                onOpen();
                                setSelected({
                                    id: row.id,
                                });
                            }}
                            className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white"
                        >
                            Retry
                        </button>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        handleGetTransactions();
        getServices();
    }, [query, keyword]);

    const [services, setServices] = useState<ServiceType[]>([]);

    const getServices = async () => {
        try {
            const serv = await get_services();
            setServices(serv.list);
        } catch (error: any) {
            toast.error(error.response.message);
        }
    };

    const formatedServices: OptionType[] = services.map(
        (service: ServiceType) => {
            return {
                value: service.id.toString(),
                label: `${service.name}`,
            };
        }
    );

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            await add_service_transaction(e).then(() => {
                toast.success('Transaction Created');
                openCreateTransaction.onClose();
            });
        } catch (error) {
            toast.error(`Transaction Error: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    return (
        <>
            <div className="float-right  flex flex-wrap justify-between  gap-4 whitespace-nowrap px-6 py-4">
                <button
                    onClick={() => openCreateTransaction.onOpen()}
                    className="flex gap-2 rounded bg-red-600 px-2 py-2 text-light"
                >
                    <PlusCircleIcon className="w-5" /> Add Transaction
                </button>
            </div>
            <div className="">
                <Modal
                    title="Add transaction"
                    size="md:w-1/3 max-w-[500px] w-11/12"
                    isOpen={openCreateTransaction.isOpen}
                    onClose={openCreateTransaction.onClose}
                >
                    <Form<ServiceTransactionPayload, typeof createSchema>
                        schema={createSchema}
                        onSubmit={handleCreate}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        label="Phone number"
                                        error={formState.errors.phoneNumber}
                                        registration={register('phoneNumber')}
                                    />
                                    <SelectField
                                        label="Till"
                                        placeholder="Till"
                                        options={formatedServices}
                                        registration={register('serviceId')}
                                        className="h-12"
                                        error={formState.errors.serviceId}
                                        isLoading={isLoadingCreate}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingCreate}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <DataTable
                columns={columns}
                data={transactions?.list ?? []}
                isLoading={isLoading}
                total={transactions?.total ?? 0}
                nextPage={transactions?.nextPage ?? 0}
                lastPage={transactions?.lastPage ?? 0}
                currentPage={transactions?.currentPage ?? 0}
                previousPage={transactions?.previousPage ?? 0}
            />
            <div className="">
                <Modal
                    title="Retry"
                    size="text-light md:w-1/2 max-w-[500px] w-11/12 mt-20"
                    onClose={onClose}
                    isOpen={isOpen}
                >
                    <RetryComponent id={selected.id} />
                </Modal>
            </div>
        </>
    );
};
