import * as z from 'zod';
import { toast } from 'react-toastify';
import { UserIcon, RefreshIcon } from '@heroicons/react/outline';
import { NavBar, Button, Form, InputField } from '~/components/elements';
import { useAuthentication } from '~/core/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { reset_password } from '~/api/reset';
import { ResetPayload } from '~/app/types';

const schema = z.object({
    email: z.string().min(1, 'Username is required').email('Invalid email'),
});

export const ResetPasswordPage = () => {
    useAuthentication({ isAuth: false });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmit = async (payload: ResetPayload) => {
        try {
            setIsLoading(true);
            await reset_password(payload.email);
            toast.success('Password in progress! Check your email.');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <NavBar />

            <main className="container py-12">
                <div className="mx-auto lg:w-[80%]">
                    <p className="text-center text-2xl font-bold uppercase text-white">
                        Reset Password
                    </p>

                    <Form<ResetPayload, typeof schema>
                        schema={schema}
                        onSubmit={handleSubmit}
                        className="mx-auto mt-8 w-full bg-dark-light p-4 md:w-1/2 md:p-10"
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        placeholder="Enter Your Email"
                                        icon={
                                            <UserIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.email}
                                        registration={register('email')}
                                        className="h-12"
                                        isLoading={isLoading}
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    isLoading={isLoading}
                                    className="mt-8 w-full font-semibold"
                                >
                                    <RefreshIcon className="icon-md" />
                                    <span>Reset</span>
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            </main>
        </>
    );
};
