import { useState } from 'react';
import clsx from 'clsx';
import {
    LocationMarkerIcon,
    ClipboardCheckIcon,
    IdentificationIcon,
    CreditCardIcon,
} from '@heroicons/react/outline';
import {
    LocationContainer,
    PackageContainer,
    MemberContainer,
    PaymentContainer,
} from '~/components/containers';
import { AuthRegisterPayload } from '~/app/types';

export const RegisterSection = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [location, setLocation] = useState('');
    const [payload, setPayload] = useState<AuthRegisterPayload>({
        location: '',
        package: undefined,
        member: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
        },
        card: {
            card_number: '',
            expiry_month: '',
            expiry_year: '',
            name_on_card: '',
            security_code: '',
        },
    });

    const tabs = [
        // <LocationMarkerIcon className="icon-md text-white" />,
        <ClipboardCheckIcon className="icon-md text-white" />,
        <IdentificationIcon className="icon-md text-white" />,
        <CreditCardIcon className="icon-md text-white" />,
    ];
    return (
        <div className="mx-auto lg:w-[80%]">
            <div>
                <div className="relative top-10 w-full border-b-2 border-dotted border-white"></div>

                <div className="flex justify-between space-x-4">
                    {tabs.map((tab, key) => (
                        <div
                            key={key}
                            className={clsx(
                                'hover z-10 flex h-16 w-16 items-center justify-center rounded-full border border-red-600 md:h-20 md:w-20',
                                key <= tabIndex ? 'bg-red-600' : 'bg-dark',
                                key <= tabIndex && 'cursor-pointer'
                            )}
                            onClick={() =>
                                key <= tabIndex ? setTabIndex(key) : undefined
                            }
                        >
                            {tab}
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-12">
                {/*{tabIndex === 0 && (*/}
                {/*    <LocationContainer*/}
                {/*        onNext={value => {*/}
                {/*            setPayload(prev => {*/}
                {/*                return { ...prev, location: value };*/}
                {/*            });*/}

                {/*            setTabIndex(1);*/}
                {/*        }}*/}
                {/*        setLocation={setLocation}*/}
                {/*    />*/}
                {/*)}*/}

                {tabIndex === 0 && (
                    <PackageContainer
                        onNext={value => {
                            setPayload(prev => {
                                return { ...prev, package: value };
                            });

                            setTabIndex(1);
                        }}
                    />
                )}

                {tabIndex === 1 && (
                    <MemberContainer
                        payload={payload}
                        onNext={member => {
                            setPayload(prev => {
                                return { ...prev, member };
                            });

                            setTabIndex(2);
                        }}
                    />
                )}

                {tabIndex === 2 && <PaymentContainer payload={payload} />}
            </div>
        </div>
    );
};
