import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as z from 'zod';
import { create_card } from '~/api';
import { CardPayload } from '~/app/types';
import { Button, Form, InputField, Modal } from '~/components/elements';
import { CommonContext, CommonContextType } from '~/core/context';

type DashUpdateCardProps = {
    isOpen: boolean;
    onClose: () => void;
};

const schema = z.object({
    name_on_card: z.string().min(1, 'Name on card is required'),
    card_number: z.string().min(1, 'Card number is required'),
    expiry_month: z.string().min(1, 'Required'),
    expiry_year: z.string().min(1, 'Required'),
    security_code: z.string().min(1, 'Required'),
});

export const DashMemberAddCard = (props: DashUpdateCardProps) => {
    const { isOpen, onClose } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const { cards, setCards } = useContext<CommonContextType>(CommonContext);

    const handleSubmit = async (payload: CardPayload) => {
        try {
            setLoading(true);
            const request = await create_card(payload);
            const { card } = request.data;
            setCards([...cards, card]);
            toast.success('Card added successfully');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Modal title="Add Card" isOpen={isOpen} onClose={onClose}>
            <Form<CardPayload, typeof schema>
                schema={schema}
                onSubmit={handleSubmit}
            >
                {({ register, formState }) => (
                    <>
                        <div className="flex flex-col gap-4">
                            <InputField
                                label="Card Holder Name"
                                placeholder="Name on Card"
                                error={formState.errors.name_on_card}
                                registration={register('name_on_card')}
                                className="h-12"
                                isLoading={loading}
                            />

                            <InputField
                                type="number"
                                label="Card Number"
                                placeholder="1234-1234-1234-1234"
                                error={formState.errors.card_number}
                                registration={register('card_number')}
                                className="h-12"
                                isLoading={loading}
                            />

                            <div className="flex flex-col gap-4 md:flex-row">
                                <InputField
                                    type="number"
                                    label="Expire Month"
                                    placeholder="01"
                                    error={formState.errors.expiry_month}
                                    registration={register('expiry_month')}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <InputField
                                    type="number"
                                    label="Expire Year"
                                    placeholder="22"
                                    error={formState.errors.expiry_year}
                                    registration={register('expiry_year')}
                                    className="h-12"
                                    isLoading={loading}
                                />

                                <InputField
                                    type="number"
                                    label="Security Code"
                                    placeholder="123"
                                    error={formState.errors.security_code}
                                    registration={register('security_code')}
                                    className="h-12"
                                    isLoading={loading}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            variant="btn-danger"
                            className="mt-8 w-full font-semibold"
                            isLoading={loading}
                        >
                            CREATE
                        </Button>
                    </>
                )}
            </Form>
        </Modal>
    );
};
