export const ErrorLayout = () => {
    const reload = () => {
        localStorage.removeItem('react_app_token');
        window.location.reload();
    };

    return (
        <div className="h-screen w-screen overflow-hidden">
            <section className="bg-primary relative z-10 h-screen w-screen py-[120px]">
                <div className="container mx-auto">
                    <div className="-mx-4 flex">
                        <div className="w-full px-4">
                            <div className="mx-auto max-w-[400px] text-center">
                                <h2 className="mb-2 text-[50px] font-bold leading-none text-red-500 sm:text-[80px] md:text-[100px]">
                                    500
                                </h2>
                                <h4 className="mb-3 text-[22px] font-semibold leading-tight text-white">
                                    Oops! Unknown error has occured while
                                    loading the application!
                                </h4>
                                <button
                                    className="hover:text-primary inline-block rounded-lg border border-red-500 px-8 py-3 text-center text-base font-semibold text-white transition hover:bg-red-500"
                                    onClick={reload}
                                >
                                    Reload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute top-0 left-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
                    <div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]" />
                    <div className="flex h-full w-1/3">
                        <div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]" />
                        <div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]" />
                    </div>
                    <div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]" />
                </div>
            </section>
        </div>
    );
};
