/* eslint-disable no-unused-vars */
import { ReactNode, Fragment } from 'react';
import clsx from 'clsx';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

interface DialogProps {
    children: ReactNode;
    size?: string;
    centered?: boolean;
    title?: string;
    isOpen: boolean;
    onClose: (...args: any) => void;
}

export const Modal = (props: DialogProps) => {
    const { children, title, isOpen, onClose , size  } = props;


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black/70" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div
                        className={clsx(
                            'flex justify-center px-0 py-0 md:px-4 md:py-8'
                        )}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={clsx(
                                    'transform overflow-hidden bg-dark-light shadow-xl transition-all md:rounded-md', `${size ?? 'w-full max-w-6xl'}`
                                )}
                            >
                                {title && (
                                    <Dialog.Title
                                        as="h3"
                                        className="flex items-center justify-between p-4 text-xl font-medium text-black"
                                    >
                                        <div className="text-white">
                                            {title}
                                        </div>

                                        <div
                                            className="cursor-pointer text-white"
                                            onClick={onClose}
                                        >
                                            <XIcon className="icon-md" />
                                        </div>
                                    </Dialog.Title>
                                )}

                                <div className="px-4 py-6">{children}</div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
