import * as z from 'zod';
import { UserIcon, LockClosedIcon, LoginIcon } from '@heroicons/react/outline';
import { NavBar, Button, Form, InputField } from '~/components/elements';
import { storage } from '~/core/utils';
import { useAuthentication } from '~/core/hooks';
import { useState } from 'react';
import { login_user } from '~/api';
import { AuthLoginPayload } from '~/app/types';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '~/core/redux/slices/user/userAccountSlice';
import { toast } from 'react-toastify';

const schema = z.object({
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
});

export const LoginPage = () => {
    const navigation = useNavigate();

    useAuthentication({ isAuth: false });

    const defaultLoginPayload: AuthLoginPayload = {
        username: '',
        password: '',
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [error, setError] = useState<string>('');

    const dispatch = useDispatch();

    const handleSubmit = async (payload: AuthLoginPayload) => {
        try {
            setIsLoading(true);
            const response = await login_user(payload);
            const { access_token, user } = await response;
            await storage.setToken(access_token);
            dispatch(addUser(user));
            setError('');
            navigation(`/account/${user.role}`);
        } catch (error: any) {
            setError(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <NavBar />

            <main className="container py-12">
                <div className="mx-auto lg:w-[80%]">
                    <p className="text-center text-2xl font-bold uppercase text-white">
                        Log In to Waka Global
                    </p>

                    <Form<AuthLoginPayload, typeof schema>
                        schema={schema}
                        onSubmit={handleSubmit}
                        className="mx-auto mt-8 w-full bg-dark-light p-4 md:w-1/2 md:p-10"
                        options={{ defaultValues: defaultLoginPayload }}
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        placeholder="Enter Your Username or Email"
                                        icon={
                                            <UserIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.username}
                                        registration={register('username')}
                                        className="h-12"
                                        isLoading={isLoading}
                                    />

                                    <InputField
                                        type="password"
                                        placeholder="Enter Your Password"
                                        icon={
                                            <LockClosedIcon className="icon-md text-dark" />
                                        }
                                        error={formState.errors.password}
                                        registration={register('password')}
                                        className="h-12"
                                        isLoading={isLoading}
                                    />
                                    {error && (
                                        <div className="px-3 text-red-500">
                                            {error}
                                        </div>
                                    )}
                                </div>

                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    isLoading={isLoading}
                                    className="mt-8 w-full font-semibold"
                                >
                                    <LoginIcon className="icon-md" />
                                    <span>Log In</span>
                                </Button>
                                <div className="flex items-center justify-center gap-1 pt-3 text-white">
                                    <span>Forgot Password?</span>
                                    <Link
                                        className="text-red-500"
                                        to={'/reset-password'}
                                    >
                                        Reset here
                                    </Link>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </main>
        </>
    );
};
