import { createSlice } from '@reduxjs/toolkit';

const userAccountSlice = createSlice({
    name: 'userAccount',
    initialState: {
        userAccount: {},
    },
    reducers: {
        addUser: (state, action) => {
            state.userAccount = action.payload;
        },
        removeUser: (state) => {
            state.userAccount = {};
        },
        updateUser: (state, action) => {
            state.userAccount = { ...state.userAccount, ...action.payload };
        },
    },
});

export const { addUser, removeUser, updateUser } = userAccountSlice.actions;
export default userAccountSlice.reducer;
