import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { StoreType } from '~/app/types/redux';
import Search from '~/components/containers/search';
import WhatappButton from '~/components/containers/whatsapp';
import { NavBar } from '~/components/elements';
import { useAuthentication } from '~/core/hooks';
import { CheckUser } from '~/core/hooks/useUser';
import { NotificationButton } from '~/components/containers/notifications';

export const AccountLayout = () => {
    useAuthentication({ isAuth: true });

    const { userAccount } = useSelector((state: StoreType) => state.user);

    // CheckUser();

    return (
        <>
            <NavBar />

            <main className="container py-10">
                <div>
                    <div className="flex flex-wrap items-center justify-between gap-3">
                        <h2 className="text-2xl font-medium capitalize text-white">
                            Hi 👋, {userAccount?.firstname}
                        </h2>
                        <div className="flex w-2/3 justify-end items-center flex-row gap-2">
                            <Search />
                            <NotificationButton />
                        </div>
                    </div>
                    <Outlet />
                </div>
                <WhatappButton />
            </main>
        </>
    );
};
