/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as z from 'zod';
import * as XLSX from 'xlsx';
import { DocumentIcon, FilterIcon } from '@heroicons/react/outline';
import {
    Modal,
    Button,
    Form,
    SelectField,
    InputField,
} from '~/components/elements';
import { useDisclosure } from '~/core/hooks';
import { format } from '~/core/utils';
import { useContext, useEffect, useState } from 'react';
import {
    TransactionFilterPayload,
} from '~/app/types';
import {
    ExportContext,
    ExportContextType,
} from '../admin/ExportContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { PackageType } from '~/app/types/package';
import { LocationType } from '~/app/types/location';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_locations, get_packages } from '~/api';
import { PaginationType } from '~/app/types/pagination';
import { toast } from 'react-toastify';
import { addLocation } from '~/core/redux/slices/location/locationSlice';
import { addPackage } from '~/core/redux/slices/package/packageSlice';

const schema = z.object({
    location_id: z.string().optional(),
    package_id: z.string().optional(),
    user_id: z.string().optional(),
    from: z.string().optional(),
    to: z.string().optional(),
    status: z.string().optional(),
    paymentMode: z.string().optional(),
});

type DashFilterProps = {
    filters: TransactionFilterPayload;
    onFilter: (...args: any) => void;
};

type OptionType = {
    value: string;
    label: string;
};

export const defaultTransactionFilterPayload: TransactionFilterPayload = {
    location_id: '',
    package_id: '',
    user_id: '',
    from: '',
    to: '',
    status: '',
};

export const DashClientFilterComponent = (props: DashFilterProps) => {
    const { filters, onFilter } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const { locationData } = useSelector((state: StoreType) => state.location);

    const { packageData } = useSelector((state: StoreType) => state.package);

    const getLocations = async () => {
        try {
            setIsLoading(true);
            const locations: PaginationType<LocationType> =
                await get_locations();
            dispatch(addLocation(locations.list));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };
    const getPackages = async () => {
        try {
            setIsLoading(true);
            const packages: PaginationType<PackageType> = await get_packages();
            dispatch(addPackage(packages.list));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(true);
        }
    };

    const formatedPackages: OptionType[] = packageData.map(
        (currentPackage: PackageType) => {
            return {
                value: currentPackage.id.toString(),
                label: `${currentPackage.name}`,
            };
        }
    );

    const formatedLocations: OptionType[] = locationData.map(
        (currentLocation: LocationType) => {
            return {
                value: currentLocation.id.toString(),
                label: `${currentLocation.name}`,
            };
        }
    );

    const handleOnSubmit = (payload: TransactionFilterPayload) => {
        const searchParams = new URLSearchParams(location.search);

        const {
            from,
            location_id,
            package_id,
            status,
            to,
            user_id,
            paymentMode,
        } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (status) searchParams.set('status', status);
        if (user_id) searchParams.set('user', user_id);
        if (package_id) searchParams.set('packageId', package_id);
        if (location_id) searchParams.set('locationId', location_id);
        if (paymentMode) searchParams.set('paymentMode', paymentMode);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        onClose();
    };

    const resetFilter = () => {
        onFilter(defaultTransactionFilterPayload);
        navigate(`${location.pathname}`);
        onClose();
    };

    useEffect(() => {
        if (!locationData.length) getLocations();
        if (!packageData.length) getPackages();
    }, []);
    return (
        <>
            <div className="flex flex-wrap justify-between gap-4">
                {/* <h2 className="text-2xl font-medium capitalize text-white">
                    Transactions
                </h2> */}

                <div className="flex gap-3">
                    <Button
                        variant="btn-danger"
                        className="px-3"
                        onClick={onOpen}
                    >
                        <FilterIcon className="icon-md" />
                    </Button>
                </div>
            </div>

            <Modal
                title="Filter"
                size="max-w-sm"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Form<TransactionFilterPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleOnSubmit}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <>
                            <div className="flex flex-col gap-4">
                                <SelectField
                                    placeholder="Location"
                                    options={formatedLocations}
                                    error={formState.errors.location_id}
                                    registration={register('location_id')}
                                    className="h-12"
                                    isLoading={isLoading}
                                />

                                <SelectField
                                    placeholder="Package"
                                    options={formatedPackages}
                                    error={formState.errors.package_id}
                                    registration={register('package_id')}
                                    className="h-12"
                                    isLoading={isLoading}
                                />

                                {/* <SelectField
                                    placeholder="Members"
                                    options={formatedMembers}
                                    error={formState.errors.user_id}
                                    registration={register('user_id')}
                                    className="h-12"
                                    isLoading={loadingMembers}
                                /> */}
                                <SelectField
                                    placeholder="Payment Method"
                                    options={[
                                        {
                                            label: 'MoMo',
                                            value: 'momo',
                                        },
                                        {
                                            label: 'Card',
                                            value: 'card',
                                        },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={isLoading}
                                />

                                <div className="flex flex-col justify-between gap-4 md:flex-row">
                                    <InputField
                                        type="date"
                                        error={formState.errors.from}
                                        registration={register('from')}
                                        className="h-12"
                                        label="Start Date"
                                    />

                                    <InputField
                                        type="date"
                                        error={formState.errors.to}
                                        registration={register('to')}
                                        className="h-12"
                                        label="End Date"
                                    />
                                </div>

                                <SelectField
                                    placeholder="Status"
                                    options={[
                                        {
                                            value: 'SUCCESS',
                                            label: 'Succeeded',
                                        },
                                        {
                                            value: 'FAILURE',
                                            label: 'Failed',
                                        },
                                        {
                                            value: 'PENDING',
                                            label: 'Pending',
                                        },
                                        {
                                            value: 'WAITING',
                                            label: 'waiting',
                                        },
                                    ]}
                                    error={formState.errors.status}
                                    registration={register('status')}
                                    className="h-12"
                                />
                            </div>

                            <div className="flex gap-2">
                                <Button
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    onClick={resetFilter}
                                    isLoading={isLoading}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoading}
                                >
                                    Filter
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};