/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as z from 'zod';
import {
    DocumentIcon,
    EyeIcon,
    FilterIcon,
    UserCircleIcon,
} from '@heroicons/react/outline';
import {
    Modal,
    Button,
    Form,
    SelectField,
    InputField,
} from '~/components/elements';
import { useDisclosure } from '~/core/hooks';
import { format } from '~/core/utils';
import { useContext, useEffect, useState } from 'react';
import {
    ExportContext,
    ExportContextType,
} from '../admin/ExportContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaginationType } from '~/app/types/pagination';
import { UserType } from '~/app/types';
import { toast } from 'react-toastify';
import { get_all_users_search } from '~/api';
import { XIcon } from '@heroicons/react/solid';

const schema = z.object({
    from: z.string().optional(),
    to: z.string().optional(),
    completed: z.string().optional(),
});

export type CronsFilterPayload = {
    from?: string;
    to?: string;
    completed?: string;
    user?: string;
};

type DashFilterProps = {
    filters: CronsFilterPayload;
    onFilter: (...args: any) => void;
};

export const defaultCronsFilterPayload: CronsFilterPayload = {
    completed: '',
    from: '',
    to: '',
};

export const DashFilterCronsComponent = (props: DashFilterProps) => {
    const { filters, onFilter } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { exportData } = useContext<ExportContextType>(ExportContext);

    const navigate = useNavigate();
    const location = useLocation();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const handleOnSubmit = (payload: CronsFilterPayload) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, completed, to, user } = payload;
        console.log(payload);
        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (completed) searchParams.set('completed', completed);
        if (selectedUser?.id) searchParams.set('userId', selectedUser?.id);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        onClose();
    };

    const resetFilter = () => {
        onFilter(defaultCronsFilterPayload);
        navigate(`${location.pathname}`);
        onClose();
    };

    const [users, setUsers] = useState<PaginationType<UserType> | null>();
    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [total, setTotal] = useState<number>();
    const [keyword, setKeyword] = useState('');
    const [filteredData, setFilteredData] = useState<UserType[]>([]);

    const handleGetUsers = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<UserType> = await get_all_users_search(
                `?pageNumber=1&pageSize=${total ? total : 0}`,
                ''
            );
            console.log(data);
            setUsers(data);
        } catch (error) {
            toast.error('Error getting users');
        } finally {
            setIsLoading(false);
        }
    };

    const filterData = () => {
        const filtered = users?.list.filter(user =>
            Object.values(user).some(field => {
                if (typeof field === 'string') {
                    return field.toLowerCase().includes(keyword.toLowerCase());
                }
                return false;
            })
        );
        setFilteredData(filtered || []);
    };
    useEffect(() => {
        handleGetUsers();
    }, []);
    return (
        <>
            <div className="flex flex-wrap justify-between gap-4">
                <div className="flex gap-3">
                    <Button
                        variant="btn-danger"
                        className="px-3"
                        onClick={onOpen}
                    >
                        <FilterIcon className="icon-md" />
                    </Button>
                </div>
            </div>

            <Modal
                title="Filter"
                size="max-w-sm"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Form<CronsFilterPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleOnSubmit}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col justify-between gap-4 md:flex-row">
                                    <InputField
                                        type="date"
                                        error={formState.errors.from}
                                        registration={register('from')}
                                        className="h-12"
                                        label="Start Date"
                                    />
                                    <InputField
                                        type="date"
                                        error={formState.errors.to}
                                        registration={register('to')}
                                        className="h-12"
                                        label="End Date"
                                    />
                                </div>
                                <div className="w-full">
                                    <div className="relative inline-block w-full text-light">
                                        <input
                                            type="search"
                                            onChange={e => {
                                                setKeyword(e.target.value);
                                                filterData();
                                                setTotal(users?.total);
                                            }}
                                            placeholder="Search users..."
                                            className={`w-full rounded-md border-b ${
                                                keyword !== ''
                                                    ? 'rounded-b-none'
                                                    : ''
                                            }${
                                                filteredData.length === 0
                                                    ? 'rounded-b'
                                                    : ''
                                            } border-slate-700 bg-transparent p-2 focus:border-slate-700 focus:outline-none focus:ring-0`}
                                        />
                                        {keyword != '' ? (
                                            <div className="border-gray-300 scrollbar-hidden z-[9999] h-80 max-h-80 w-full overflow-y-auto rounded-md rounded-t-none border border-slate-700 bg-slate-900 ">
                                                <div className="flex w-full flex-col">
                                                    {filteredData.map(
                                                        (user, index) => (
                                                            <div
                                                                key={index}
                                                                onClick={() => {
                                                                    setSelectedUser(
                                                                        user
                                                                    );
                                                                    setKeyword(
                                                                        ''
                                                                    );
                                                                }}
                                                                className="mb-3 flex w-full cursor-pointer flex-row items-center justify-between border-b border-slate-700 p-2 hover:bg-slate-600"
                                                            >
                                                                <div className="flex w-[80%] flex-col justify-center px-2">
                                                                    <p>
                                                                        {
                                                                            user.firstname
                                                                        }{' '}
                                                                        {
                                                                            user.lastname
                                                                        }
                                                                    </p>
                                                                    <p className="text-sm font-light">
                                                                        {
                                                                            user.email
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {selectedUser && (
                                        <div className="mt-1 flex flex-row items-center justify-between gap-2 border border-slate-800 p-2 text-light">
                                            <div className="flex w-full flex-row">
                                                <UserCircleIcon className="w-6" />
                                                {selectedUser.firstname}{' '}
                                                {selectedUser.lastname}
                                            </div>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setSelectedUser(undefined);
                                                }}
                                            >
                                                <XIcon className="w-5 text-light" />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <SelectField
                                    placeholder="Completed"
                                    options={[
                                        {
                                            value: 'false',
                                            label: 'PENDING',
                                        },
                                        {
                                            value: 'true',
                                            label: 'COMPLETED',
                                        },
                                    ]}
                                    error={formState.errors.completed}
                                    registration={register('completed')}
                                    className="h-12"
                                />
                            </div>

                            <div className="flex gap-2">
                                <Button
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    onClick={resetFilter}
                                    isLoading={isLoading}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoading}
                                >
                                    Filter
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};
