/* eslint-disable no-unused-vars */
import * as z from 'zod';
import {
    UserIcon,
    PhoneIcon,
    AtSymbolIcon,
    LockClosedIcon,
} from '@heroicons/react/outline';
import { Button, Form, InputField } from '~/components/elements';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { register_user } from '~/api';
import { storage } from '~/core/utils';
import { AuthRegisterPayload, MemberPayload } from '~/app/types';

const schema = z
    .object({
        firstName: z.string().min(1, 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        phone: z.string().min(1, 'Phone number is required'),
        email: z.string().min(1, 'Email address is required').email(),
        password: z
            .string()
            .min(6, 'Password must be at least 6 characters long'),
        confirmPassword: z.string().min(1, 'Confirm password is required'),
    })
    .refine(data => data.password === data.confirmPassword, {
        message: "Passwords don't match",
        path: ['confirmPassword'],
    });

type MemberContainerProps = {
    payload: AuthRegisterPayload;
    onNext: (...args: any) => void;
};

export const MemberContainer = (props: MemberContainerProps) => {
    const { payload, onNext } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (payload: MemberPayload) => {
        try {
            setIsLoading(true);
            const response = await register_user(payload);
            const { access_token } = response;
            storage.setToken(access_token);
            toast.success('Your account has been created successfully!');
            onNext(payload);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <p className="text-center text-2xl font-bold uppercase text-white">
                Member Details
            </p>

            <Form<MemberPayload, typeof schema>
                schema={schema}
                onSubmit={handleSubmit}
                options={{ defaultValues: payload.member }}
                className="mx-auto mt-8 w-full bg-dark-light p-4 md:w-1/2 md:p-10"
            >
                {({ register, formState }) => (
                    <>
                        <div className="flex flex-col gap-4">
                            <InputField
                                placeholder="Enter Your First Name"
                                icon={
                                    <UserIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.firstName}
                                registration={register('firstName')}
                                className="h-12"
                                isLoading={isLoading}
                            />

                            <InputField
                                placeholder="Enter Your Last Name"
                                icon={
                                    <UserIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.lastName}
                                registration={register('lastName')}
                                className="h-12"
                                isLoading={isLoading}
                            />

                            <InputField
                                placeholder="Enter Your Phone Number"
                                icon={
                                    <PhoneIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.phone}
                                registration={register('phone')}
                                className="h-12"
                                isLoading={isLoading}
                            />

                            <InputField
                                placeholder="Enter Your Email Address"
                                icon={
                                    <AtSymbolIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.email}
                                registration={register('email')}
                                className="h-12"
                                isLoading={isLoading}
                            />

                            <InputField
                                placeholder="Enter Your Password"
                                icon={
                                    <LockClosedIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.password}
                                registration={register('password')}
                                className="h-12"
                                isLoading={isLoading}
                                type="password"
                            />

                            <InputField
                                placeholder="Confirm Your Password"
                                icon={
                                    <LockClosedIcon className="icon-md text-dark" />
                                }
                                error={formState.errors.confirmPassword}
                                registration={register('confirmPassword')}
                                className="h-12"
                                isLoading={isLoading}
                                type="password"
                            />
                        </div>

                        <Button
                            type="submit"
                            variant="btn-danger"
                            className="mt-8 w-full font-semibold"
                            isLoading={isLoading}
                        >
                            Register
                        </Button>
                    </>
                )}
            </Form>
        </div>
    );
};
