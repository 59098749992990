import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import car_free from '~/assets/images/car-free.jpg';
import cross_fit from '~/assets/images/crossfit.jpg';
import hike from '~/assets/images/hike.jpg';
import running from '~/assets/images/running.jpg';
import intro from '~/assets/images/intro.jpg';

import { ChevronLeftIcon, ChevronRightIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import LogoImage from '~/assets/images/logo.png';
import { LocationType } from '~/app/types/location';
import { get_locations } from '~/api';
import { PaginationType } from '~/app/types/pagination';
import { FaFacebook, FaInstagram, FaLinkedin, FaX, FaYoutube } from 'react-icons/fa6';

const App: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [locations, setLocation] = useState<PaginationType<LocationType> | null>(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const images = [
        { url: intro, text: 'Visit WAKA Today' },
        { url: running, text: 'Running Club', subtext: '#jointhetribe' },
        { url: cross_fit, text: 'Functional Fitness', subtext: '#jointhetribe ' },
        { url: car_free, text: 'Car-Free Day', subtext: '#jointhetribe  ' },
        { url: hike, text: 'Hikes', subtext: '#jointhetribe   ' }
    ];

    const handleNavigate = (direction: 'prev' | 'next') => {
        setCurrentIndex(prevIndex =>
            direction === 'prev'
                ? (prevIndex === 0 ? images.length - 1 : prevIndex - 1)
                : (prevIndex === images.length - 1 ? 0 : prevIndex + 1)
        );
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNavigate('next');
        }, 5000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    useEffect(() => {
        const getLocations = async () => {
            try {
                const loc = await get_locations();
                setLocation(loc);
            } catch (e) {
                console.log(e);
            }
        };

        getLocations().then(() => null);
    }, []);

    useEffect(() => {
        const preloadImages = async () => {
            const imagePromises = images.map(img => {
                return new Promise<void>((resolve) => {
                    const imgElement = new Image();
                    imgElement.src = img.url;
                    imgElement.onload = () => resolve();
                });
            });
            await Promise.all(imagePromises);
            setImagesLoaded(true);
        };

        if ('requestIdleCallback' in window) {
            requestIdleCallback(preloadImages);
        } else {
            preloadImages();
        }
    }, [images]);

    if (!imagesLoaded) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="spinner">Loading...</div>
            </div>
        );
    }

    return (
        <>
            <div className=" absolute w-full gap-5 z-20 items-center text-white p-3 flex justify-between px-10">
                <div className="">
                    <img
                        alt="Waka Global"
                        src={LogoImage}
                        className="w-[15rem]"
                    />
                </div>

                <div className="gap-5 flex text-xl items-center flex-row mb-2">
                    <Link to={'/home'} className={'btn border border-light/30 btn-secondary btn-md'}>Register</Link>
                    <Link to={'/login'} className={'btn btn-md btn-danger'}>Login</Link>
                </div>

            </div>
            <div className="absolute left-10 bottom-10">
                <h3
                    className="text-white text-2xl font-semibold text-center z-10"
                >
                    {images[currentIndex].subtext}
                </h3>
            </div>
            <div className="h-screen w-full flex justify-center items-center overflow-hidden relative">
                <motion.div
                    key={images[currentIndex].text}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="absolute w-full h-full flex items-center justify-center"
                />
                <motion.img
                    src={images[currentIndex].url}
                    alt={`Slide ${images[currentIndex].text}`}
                    initial={{ opacity: 0.3, scale: 1 }}
                    animate={{ opacity: 0.4, scale: 1.1 }}
                    transition={{ duration: 10, repeat: Infinity }}
                    className="w-full h-screen max-h-full object-cover"
                />
                <div className="flex absolute flex-col ga-2">
                    <motion.h1
                        key={images[currentIndex].text}
                        className="text-white md:text-[80px] text-[50px] font-bold text-center z-10"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        {images[currentIndex].text}
                    </motion.h1>
                </div>
                <div className="absolute px-2 bottom-1/2 w-full justify-between flex space-x-4 z-10">
                    <button
                        className="bg-white/10 text-light p-4 rounded-full shadow-md"
                        onClick={() => handleNavigate('prev')}
                    >
                        <ChevronLeftIcon width={20} />
                    </button>

                    <button
                        className="bg-white/10 text-light p-4 rounded-full shadow-md"
                        onClick={() => handleNavigate('next')}
                    >
                        <ChevronRightIcon width={20} />
                    </button>
                </div>
            </div>
            <div className="p-4 px-10 bg-dark-light text-light">
                <div className="flex flex-col gap-3">
                    {locations?.list.map((item, index) =>
                        <a href={item.link} target={'_blank'} key={index}
                           className="flex text-xl flex-row gap-1"><LocationMarkerIcon
                            className={'w-5'} /> {item.name} ,{' '}{item.address},{' '}{item.contact}</a>
                    )}
                    <div className="flex py-2 text-xl flex-row gap-4">
                        <a target={'_blank'} href="https://www.instagram.com/wakaglobal/">
                            <FaInstagram />
                        </a>
                        <a target={'_blank'} href="https://x.com/Waka_Global?s=20">
                            <FaX />
                        </a>
                        <a target={'_blank'} href="https://www.linkedin.com/company/waka-global/">
                            <FaLinkedin />
                        </a>
                        <a target={'_blank'} href="https://www.youtube.com/channel/UC7CdeFyY6kQlMlELWIPSVow">
                            <FaYoutube />
                        </a>
                        <a target={'_blank'} href="https://www.facebook.com/wakarwanda/">
                            <FaFacebook />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default App;
