/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {CreditCardIcon, UserIcon} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { DashCard } from '~/components/elements';
import {get_admin_stats, get_member_stats} from '~/api/stats/member.stats';
import {CashIcon} from '@heroicons/react/solid';
import { useLocation, useParams } from 'react-router-dom';

export type DashFrontDeskStatsType = {
    // totalMembers: number;
    // totalDownTownMembers: number;
    // totalKimihururaMembers: number;
    activeSubscriptions: number,
    inactiveSubscriptions: number,
    totalSubscriptions: number,
    totalTransactions: number,
    totalSuccessfulTransactions: number,
    totalFailedTransactions: number,
    totalAmountOfSuccessfulTransactions: number
};

export const DashFrontDeskStats = () => {
    const [loading, setLoading] = useState(false);

    const [stats, setStats] = useState<DashFrontDeskStatsType>({
        activeSubscriptions: 0,
        inactiveSubscriptions: 0,
        totalSubscriptions: 0,
        totalTransactions: 0,
        totalSuccessfulTransactions: 0,
        totalFailedTransactions: 0,
        totalAmountOfSuccessfulTransactions: 0
    });

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const all = useQuery();
    const packageId = all.get('packageId');

    const handleGetStats = async () => {
        try {
            setLoading(true);
            const data = await get_admin_stats(packageId);
            setStats(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetStats();
    }, [packageId]);

    return (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <DashCard
                label="Active Subscriptions"
                background="bg-light/10"
                count={stats?.activeSubscriptions}
                icon={<UserIcon className="icon-xl" />}
                isLoading={loading}
            />

            <DashCard
                label="Inactive Subscriptions"
                background="bg-light/10"
                count={stats?.inactiveSubscriptions}
                icon={<UserIcon className="icon-xl" />}
                isLoading={loading}
            />

            <DashCard
                label="Total Subscriptions"
                background="bg-light/10"
                count={stats?.totalSubscriptions}
                icon={<UserIcon className="icon-xl" />}
                isLoading={loading}
            />

            <DashCard
                label="Successfully Transactions"
                background="bg-green-600/70"
                count={stats?.totalSuccessfulTransactions}
                icon={<CashIcon className="icon-xl" />}
                isLoading={loading}
            />

            <DashCard
                label="Failed Transactions"
                background="bg-red-600/70"
                count={stats?.totalFailedTransactions}
                icon={<CashIcon className="icon-xl" />}
                isLoading={loading}
            />

            <DashCard
                label="Total Transactions"
                background="bg-blue-600/70"
                count={stats?.totalTransactions}
                icon={<CashIcon className="icon-xl" />}
                isLoading={loading}
            />
        </div>
    );
};
