import { PackageType } from '~/app/types/package';
import { exportToCSV, format } from '~/core/utils';

export const exportPackage = (packageData: PackageType[]): string =>
    exportToCSV<PackageType>([
        {
            label: 'Package Name',
            selector: row => row.name,
        },
        {
            label: 'Price',
            selector: row => format.currenyWithAmount(row.price),
        },
        {
            label: 'Joining Fee',
            selector: row => format.currenyWithAmount(row.joining_fee),
        },
        {
            label: 'Status',
            selector: row => row.status,
        },
        {
            label: 'Created at',
            selector: row => format.humanDate(row.created_at),
        },
        {
            label: 'Updated at',
            selector: row => row.updated_at,
        },
    ], packageData
    );