/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    InputTextArea,
    Modal,
    SelectField,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useEffect, useState } from 'react';
import {
    create_service,
    delete_service,
    get_locations,
    get_services,
    update_service,
} from '~/api';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';
import { ServicePayload, ServiceType } from '~/app/types';
import { LocationType } from '~/app/types/location';

type OptionType = {
    value: string;
    label: string;
};

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    price: z.string().min(1, { message: 'Price is required' }),
    quantity: z.string().min(1, { message: 'Quantity is required' }),
    locationId: z.string().min(1, { message: 'Location is required' }),
});

const update_schema = z.object({
    name: z.string().optional(),
    price: z.string().optional(),
    quantity: z.string().optional(),
    locationId: z.string().optional(),
});

export const DashAdminServices = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<ServiceType>({
        name: '',
        price: 0,
        quantity: 0,
        location: {
            id: '',
            name: '',
            created_at: '',
            contact: '',
            updated_at: '',
            district: '',
            address: '',
            status: '',
            street: '',
            link: ''
        },
        id: '',
        created_at: '',
        updated_at: '',
    });
    const openCreateService = useDisclosure();
    const openUpdateService = useDisclosure();
    const openDeleteService = useDisclosure();
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [services, setServices] =
        useState<PaginationType<ServiceType> | null>();

    const query = location.search;

    const getServices = async () => {
        try {
            const services = await get_services(query);
            setServices(services);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<ServiceType>[] = [
        {
            title: 'Service Name',
            cell: row => row.name,
        },
        {
            title: 'Price',
            cell: row => format.currenyWithAmount(row.price),
        },
        {
            title: 'Quantity',
            cell: row => <p>{row.quantity === null ? 'N/A' : row.quantity}</p>,
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-5">
                    <TrashIcon
                        onClick={() => {
                            setSelected(row);
                            openDeleteService.onOpen();
                        }}
                        className="w-5"
                    />
                    <PencilAltIcon
                        onClick={() => {
                            setSelected(row);
                            openUpdateService.onOpen();
                        }}
                        className="w-5"
                    />
                </div>
            ),
        },
    ];

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            await create_service(e).then(() => {
                toast.success('Service Created');
                openCreateService.onClose();
            });
        } catch (error) {
            toast.error(`Service not created: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (e: ServicePayload) => {
        try {
            setIsLoadingUpdate(true);
            await update_service(selected.id, {
                name: e.name === '' ? selected?.name : e.name,
                price:
                    e.price.toString() === ''
                        ? selected.price
                        : Number(e.price),
                locationId:
                    e.locationId === '' ? selected.location.id : e.locationId,
                quantity:
                    e.quantity.toString() === ''
                        ? selected.quantity
                        : Number(e.quantity),
            }).then(() => {
                toast.success('Service updated');
                openUpdateService.onClose();
            });
        } catch (error) {
            toast.error(`Service not updated: ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_service(selected.id).then(() => {
                toast.success('Service deleted');
                openDeleteService.onClose();
            });
        } catch (error) {
            toast.error(`Service not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        getServices();
        getLocations();
    }, [isLoadingCreate, isLoadingDelete, isLoadingUpdate]);

    const [locations, setLocations] = useState<LocationType[]>([]);
    const getLocations = async () => {
        try {
            const locs: PaginationType<LocationType> = await get_locations(
                query
            );
            setLocations(locs.list);
        } catch (error: any) {
            toast.error(error.response.message);
        }
    };

    const formatedLocations: OptionType[] = locations.map(
        (location: LocationType) => {
            return {
                value: location.id.toString(),
                label: `${location.name}`,
            };
        }
    );

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-end p-2">
                    <button
                        onClick={() => openCreateService.onOpen()}
                        className="rounded-md bg-red-600 px-2 py-1 text-light"
                    >
                        Create Till
                    </button>
                </div>
                <Modal
                    title="Create Till"
                    size="md:w-1/2 max-w-[500px] w-11/12"
                    isOpen={openCreateService.isOpen}
                    onClose={openCreateService.onClose}
                >
                    <Form<ServicePayload, typeof schema>
                        schema={schema}
                        onSubmit={handleCreate}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="text"
                                        registration={register('name')}
                                        className="h-12"
                                        error={formState.errors.name}
                                        label="Service Name"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        label="Price"
                                        type="number"
                                        isLoading={isLoadingCreate}
                                        error={formState.errors.price}
                                        registration={register('price')}
                                    />
                                    <InputField
                                        label="Quantity"
                                        type="number"
                                        isLoading={isLoadingCreate}
                                        error={formState.errors.quantity}
                                        registration={register('quantity')}
                                    />
                                    <SelectField
                                        placeholder="Select Location"
                                        label="Location"
                                        options={formatedLocations}
                                        registration={register('locationId')}
                                        className="h-12"
                                        error={formState.errors.locationId}
                                        isLoading={isLoadingCreate}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingCreate}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <DataTable
                columns={columns}
                data={services?.list ?? []}
                isLoading={isLoading}
                total={services?.total ?? 0}
                lastPage={services?.lastPage ?? 0}
                nextPage={services?.nextPage ?? 0}
                currentPage={services?.currentPage ?? 0}
                previousPage={services?.previousPage ?? 0}
            />
            <Modal
                title="Delete Service"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDeleteService.isOpen}
                onClose={openDeleteService.onClose}
            >
                <form onSubmit={handleDelete}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to delete{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selected.name}
                            </span>{' '}
                            service?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal
                title="Update Service"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateService.isOpen}
                onClose={openUpdateService.onClose}
            >
                <Form<ServicePayload, typeof update_schema>
                    schema={update_schema}
                    onSubmit={handleUpdate}
                >
                    {({ register, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <InputField
                                    type="text"
                                    registration={register('name')}
                                    className="h-12"
                                    error={formState.errors.name}
                                    label="Update Service Name"
                                    placeholder={selected.name}
                                    isLoading={isLoadingUpdate}
                                />
                                <InputField
                                    label="Price"
                                    type="number"
                                    isLoading={isLoadingUpdate}
                                    placeholder={selected.price.toString()}
                                    error={formState.errors.price}
                                    registration={register('price')}
                                />
                                <InputField
                                    label="Quantity"
                                    type="number"
                                    isLoading={isLoadingUpdate}
                                    placeholder={selected.quantity.toString()}
                                    error={formState.errors.quantity}
                                    registration={register('quantity')}
                                />
                                <SelectField
                                    placeholder={selected.location.name}
                                    label="Location"
                                    options={formatedLocations}
                                    registration={register('locationId')}
                                    className="h-12"
                                    error={formState.errors.locationId}
                                    isLoading={isLoadingUpdate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingUpdate}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};
