import {
    Button,
    DataTable,
    Modal,
    TableColumn,
} from '~/components/elements';
import { TransactionFilterPayload } from '~/app/types';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import React, { useContext, useEffect, useState } from 'react';
import { get_all_subscriptions } from '~/api';
import { ExportContext, ExportContextType } from './ExportContextProvider';
import { useLocation } from 'react-router-dom';
import {
    handleExportExcel,
} from '../common/DashAdminFilter';
import RetryComponent from '../common/RetryComponent';
import { useDisclosure } from '~/core/hooks';
import { DocumentReportIcon } from '@heroicons/react/outline';
import { SubscriptionType } from '~/app/types/subscription';
import { exportSubscription } from '~/core/helper/csv/subscription';
import { DashFilterSubComponent } from '~/components/pages/account/common/DashSubsFilter';
import { format } from '~/core/utils';

export const DashAdminSubscription = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filters, setFilters] = useState<TransactionFilterPayload>({
        location_id: '',
        package_id: '',
        user_id: '',
        from: '',
        to: '',
        status: '',
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selected, setSelected] = useState({ id: '' });

    const location = useLocation();

    const query = location.search;

    const { setExportData } = useContext<ExportContextType>(ExportContext);

    const [transactions, setTransactions] =
        useState<PaginationType<SubscriptionType> | null>();
    const [keyword, setKeyword] = useState('');

    const handleGetTransactions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<SubscriptionType> =
                await get_all_subscriptions(query);
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<SubscriptionType>[] = [
        {
            title: 'Member',
            cell: row => (
                <>
                    <p className="capitalize">
                        {row.user &&
                            `${row.user.firstname} ${row.user.lastname}`}
                    </p>
                    <p>{row.user && row.user.email}</p>
                </>
            ),
        },
        {
            title: 'Telephone',
            cell: row => (
                <>
                    <p>{row.user && row.user.phone}</p>
                </>
            ),
        },
        {
            title: 'Package',
            cell: row => (
                <p className="capitalize">{`${
                    row.package?.name ?? 'N/A'
                }`}</p>
            ),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'active' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() === 'inactive' && (
                        <span className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() === 'expired' && (
                        <span className="block w-full rounded-full bg-gray py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() === 'onhold' && (
                        <span className="block w-full rounded-full bg-yellow-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        handleGetTransactions();
    }, [query, keyword]);

    useEffect(() => {
        if (transactions?.list) {
            setExportData(exportSubscription(transactions?.list ?? []));
        }
    }, [transactions]);

    return (
        <>
            <div className="float-right flex flex-wrap justify-between  gap-4 whitespace-nowrap px-6 py-4">
                <DashFilterSubComponent
                    filters={filters}
                    onFilter={(payload: TransactionFilterPayload) => {
                        setFilters(payload);
                    }}
                />
                <Button
                    variant="btn-secondary"
                    className="px-3"
                    title="Export to excel"
                    onClick={() =>
                        handleExportExcel(
                            transactions?.list.map(item => {
                                return {
                                    Member: `${item.user?.firstname} ${item.user?.lastname}`,
                                    DateTime: item.onholdDate ? format.humanDateTime(item.onholdDate) : 'N/A',
                                    Telephone: item.user?.phone,
                                    Location: item.location.name,
                                    OnHoldDate: item.onholdDate ? format.humanDateTime(item.onholdDate) : 'N/A',
                                    Status: item.status,
                                    Joined: format.humanDateTime(item.created_at),
                                };
                            }) ?? []
                        )
                    }
                >
                    <DocumentReportIcon className="icon-md" />
                </Button>
                {/*<input*/}
                {/*    type="search"*/}
                {/*    onChange={e => setKeyword(e.target.value)}*/}
                {/*    placeholder="Search..."*/}
                {/*    // value={keyword}*/}
                {/*    className="rounded-md border-b border-slate-700 bg-transparent p-2 text-light focus:border-slate-700 focus:outline-none focus:ring-0"*/}
                {/*/>*/}
            </div>
            <DataTable
                columns={columns}
                data={transactions?.list ?? []}
                isLoading={isLoading}
                total={transactions?.total ?? 0}
                nextPage={transactions?.nextPage ?? 0}
                lastPage={transactions?.lastPage ?? 0}
                currentPage={transactions?.currentPage ?? 0}
                previousPage={transactions?.previousPage ?? 0}
            />
            <div className="">
                <Modal
                    title="Retry"
                    size="text-light md:w-1/2 max-w-[500px] w-11/12 mt-20"
                    onClose={onClose}
                    isOpen={isOpen}
                >
                    <RetryComponent id={selected.id} />
                </Modal>
            </div>
        </>
    );
};
