import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TransactionType, UserType } from '~/app/types';
import { get_transactions, get_user } from '~/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import image from '~/assets/images/user.png';
import { StarIcon } from '@heroicons/react/solid';
import { DataTable, TableColumn } from '~/components/elements';
import { format } from '~/core/utils';
import { PaginationType } from '~/app/types/pagination';

const useGetUser = (id : string) => {
    const [user, setUser] = useState<UserType | null>(null);

    useEffect(() => {
        const fetchUser = async () => {
          try {
              const u = await get_user(id);
              setUser(u);
          }  catch (e) {
              toast.error('User not found');
          }
        };

        fetchUser().then(() => null);
    }, []);

    return { user };

};

export default function DashAdminFrontDeskTransactions() {
    const { id } = useParams();


    if (!id) return <Navigate to={'/account/admin/leaderboard'} />;

    const { user } = useGetUser(id);

    const columns: TableColumn<TransactionType>[] = [
        {
            title: 'Date Time',
            cell: row =>
                format.humanDateTime(
                    row.updated_at
                ),
        },
        {
            title: 'Member',
            cell: row => row.data ?(
                <>
                    <p className="capitalize">{`${row.user?.firstname} ${row.user?.lastname}`}</p>
                    <p>{row.user?.email}</p>
                </>
            ) : 'N/A',
        },
        {
            title: 'Package',
            cell: row => (
                <p className="capitalize">{`${row.subscription ? row.subscription.package.name : '-'}`}</p>
            ),
        },
        {
            title: 'Amount',
            cell: row => format.currenyWithAmount(row.amount),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() !== 'success' && (
                        <span className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
        {
            title: 'Message',
            cell: row =>
                row.data == null ? '-' : row.data.response.gatewayCode,
        },
        {
            title: 'Payment Mode',
            cell: row => row.payment_mode.toUpperCase(),
        },
    ];

    const location = useLocation();
    const query = location.search;

    const [transactions, setTransactions] =
        useState<PaginationType<TransactionType> | null>();
    const [keyword, setKeyword] = useState('');
    const [loading, setIsLoading] = useState(false);

    const handleGetTransactions = async () => {
        try {
            setIsLoading(true);
            console.log(query);
            const data: PaginationType<TransactionType> =
                await get_transactions(keyword, `${query}${query ? `&receivedBy=${id}` : `?receivedBy=${id}`}`);
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetTransactions().then(() => null);
    }, [query]);



    return (
        <div className={'w-full'}>
            { user &&
                <LeaderCard name={`${user?.firstname} ${user?.lastname}`} email={user?.email} id={user.id}/>
            }
            <div className="mb-3"></div>
            <DataTable
                columns={columns}
                data={transactions?.list ?? []}
                isLoading={loading}
                total={transactions?.total ?? 0}
                nextPage={transactions?.nextPage ?? 0}
                lastPage={transactions?.lastPage ?? 0}
                currentPage={transactions?.currentPage ?? 0}
                previousPage={transactions?.previousPage ?? 0}
            />
        </div>
    );
}

type LeaderCardProps = {
    name: string;
    email: string;
    id: string;
};

function LeaderCard(props: LeaderCardProps) {
    return (
        <div className="flex text-light w-full flex-col gap-2 rounded-lg bg-slate-600 p-5 ">
            <div className="flex w-full flex-row items-center justify-start gap-3">
                <div className="">
                    <img src={image} className="w-16" alt="user" />
                </div>
                <div className="flex flex-col justify-between gap-4">
                    <div className="">
                        <div className="text-xl">{props.name}</div>
                        <div className="font-bold">{props.email}</div>
                    </div>
                </div>
            </div>
            {/*<div className="flex flex-row justify-between">*/}
            {/*    <div className="flex flex-col gap-1 font-semibold text-green-500">*/}
            {/*        <p className="text-light">Successful </p>*/}
            {/*        <p className="text-lg">*/}
            {/*            {*/}
            {/*                props.transactions.filter(*/}
            {/*                    value => value.status === 'SUCCESS'*/}
            {/*                ).length*/}
            {/*            }*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className="flex flex-col gap-1 font-semibold text-red-500">*/}
            {/*        <p className="text-light">Failed </p>*/}
            {/*        {*/}
            {/*            props.transactions.filter(*/}
            {/*                value => value.status === 'FAILURE'*/}
            {/*            ).length*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
