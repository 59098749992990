import { useDispatch } from 'react-redux';
import { storage } from '~/core/utils';
import { removeUser } from '~/core/redux/slices/user/userAccountSlice';
import { removeCard } from '../redux/slices/card/cardSlice';
import { removeLocation } from '../redux/slices/location/locationSlice';
import { removePackage } from '../redux/slices/package/packageSlice';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        storage.removeToken();

        dispatch(removeUser());
        dispatch(removeCard());
        dispatch(removeLocation());
        dispatch(removePackage());

        navigate('/home');
    };

    return { logout };
};
