import {
    SubscriptionPayload,
    SubscriptionPayloadMember,
} from '~/app/types/subscription';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';
import { AddTransactionPayload, canOrPauPayload, changePayload, TransactionType } from '~/app/types';

export const create_subscription = async (
    subscriptionPayload: SubscriptionPayload
) => {
    try {
        const request = await PRIVATE_API.post(
            '/subscription',
            subscriptionPayload
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_all_subscriptions = async (query?: string) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/all${queryString(query)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_subscriptions = async (user_id: string, query?: string) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/user/${user_id}/subscription?${queryString(query)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_subscriptions_by_id = async (
    user_id: string,
    query?: string
) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/user/${user_id}/subscription?${queryString(query)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_subscriptions_balance = async (
    id: string,
) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/subscription-balance/${id}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_fine = async () => {
    try {
        const request = await PRIVATE_API.get(
            'subscription/pause-and-cancel-amount'
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const pause_subscription = async (id: string) => {
    try {
        const request = await PRIVATE_API.put(`/subscription/user/${id}/pause`);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const pauseSubscription = async (
    payload: canOrPauPayload
): Promise<TransactionType | any> => {
    try {
        console.log(payload);
        const request = await PRIVATE_API.post('/subscription/pause', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const cancelSubscription = async (
    payload: canOrPauPayload
): Promise<TransactionType | any> => {
    try {
        console.log(payload);
        const request = await PRIVATE_API.post('/subscription/cancel', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const changeSubscription = async (
    payload: changePayload
): Promise<TransactionType | any> => {
    try {
        console.log(payload);
        const request = await PRIVATE_API.post('/subscription/change-subscription-payment-mode', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const cancel_subscription = async (id: string) => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription/user/${id}/cancel`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_member_subscription = async (
    payload: SubscriptionPayloadMember
) => {
    try {
        const request = await PRIVATE_API.post('/subscription/member', payload);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
