import {
    AddTransactionPayload,
    LeaderBoardType,
    TransactionPayload,
    TransactionPayloadPos,
    TransactionType,
    UserType,
} from '~/app/types';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const get_transactions = async (
    keyword: string,
    query?: string
): Promise<TransactionType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/report/transaction${queryString(query)}${
                keyword != '' ? `&search=${keyword}` : ''
            }`
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_frontdesk_transactions = async (
    id: string,
    keyword: string,
    query?: string
): Promise<TransactionType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/transactions/frontdesk?frontdesk_id=${id}`
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_leaderboard = async (
    query?: string
): Promise<LeaderBoardType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/users/frontdesk/leaderboard/${queryString(query)}`
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_leaderboard_admin = async (
): Promise<LeaderBoardType[] | any> => {
    try {
        const request = await PRIVATE_API.get('/users/frontdesk/leaderboard');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const retry_transaction = async (
    payload: TransactionPayload
): Promise<TransactionType | any> => {
    try {
        const request = await PRIVATE_API.post(
            `/transactions/${payload.id}/retry`,
            {
                phoneNumber: payload.phoneNumber,
                paymentMode: payload.paymentMode,
            }
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const retry_transaction_pos = async (
    payload: TransactionPayloadPos
): Promise<TransactionType | any> => {
    try {
        const request = await PRIVATE_API.post(
            `/transactions/${payload.id}/retry`,
            {
                pos_reference_id: payload.pos_reference_id,
                paymentMode: payload.paymentMode,
            }
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const add_transaction = async (
    addTransactionPayload: AddTransactionPayload
): Promise<TransactionType | any> => {
    try {
        const request = await PRIVATE_API.post(
            '/transactions',
            addTransactionPayload
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const add_service_transaction = async (
    addTransactionPayload: AddTransactionPayload
): Promise<TransactionType | any> => {
    try {
        const request = await PRIVATE_API.post(
            '/transactions/service-transaction',
            addTransactionPayload
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
