// src/components/TermsAndConditions.tsx

import React from 'react';

const TermsAndConditionsSpecial: React.FC = () => {
    return (
        <div className="container mx-auto p-6 text-justify">
            <h1 className="text-3xl font-bold mb-4">WAKA Pay Membership Terms and Conditions</h1>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                <p>These Terms and Conditions (the "Terms") govern your membership with WAKA Fitness (the "Gym") under the WAKA Pay subscription program (the "Membership"). By enrolling in the WAKA Pay Membership, you agree to be bound by these Terms.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">2. Membership Terms and Fees</h2>
                <p>2.1. The WAKA Pay Membership is a commitment to a yearly subscription paid in monthly installments of 35,000 RWF each for a total of RWF420,000.</p>
                <p>2.2. You will be charged an upfront fee of 110,000 RWF. This covers:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>First month's membership fee (35,000 RWF)</li>
                    <li className={'ml-3'}>Membership card (5,000 RWF)</li>
                    <li className={'ml-3'}>Loyalty Benefit (70,000 RWF)</li>
                </ul>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">3. Loyalty Benefit</h2>
                <p>The 70,000 RWF Loyalty Benefit is non-refundable. However, you can choose one of the following options:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>Extend your membership for 2 additional months after your initial year is complete.</li>
                    <li className={'ml-3'}>Transfer the benefit to a friend or family member to enjoy 2 months of free membership at WAKA Fitness.</li>
                </ul>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">4. Cancellation and Termination</h2>
                <p>4.1. The Gym may terminate your Membership for the following reasons:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>Violation of gym rules and code of conduct.</li>
                    <li className={'ml-3'}>Missing two consecutive monthly payments.</li>
                </ul>
                <p>4.2. If you miss your first monthly payment, your membership will be paused until the following occurs:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>Payment of the missed monthly fee (35,000 RWF).</li>
                    <li className={'ml-3'}>Payment of a 10,000 RWF penalty fee.</li>
                    <li className={'ml-3'}>The total amount due for that missed month will be 45,000 RWF. WAKA reserves the right to immediately cancel your membership if this fee is not paid.</li>
                </ul>
                <p>4.3. Missing a second consecutive monthly payment will result in automatic cancellation of your membership. The Loyalty Benefit will not be reinstated.</p>
                <p>4.4. You may voluntarily terminate your membership by providing written notice to info@wakafitness.com at least 15 days before your next automatic debit.</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>No refunds will be issued for any unused portion of the membership if your card has already been charged.</li>
                    <li className={'ml-3'}>Cancellations will take up to 48 hours to process. Cancellations cannot be submitted within 48 hours of your card being charged.</li>
                </ul>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">5. Membership Privileges</h2>
                <p>5.1. WAKA Pay Membership includes access to:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>Gym facilities at 2 locations:</li>
                    <ul className="list-disc pl-10">
                        <li className={'ml-3'}>WAKA Town</li>
                        <li className={'ml-3'}>WAKA Kimihurura</li>
                    </ul>
                    <li className={'ml-3'}>Over 20 fitness classes.</li>
                    <li className={'ml-3'}>WAKA Hike community access.</li>
                    <li className={'ml-3'}>WAKA Running Club</li>
                </ul>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">6. Guest Privileges</h2>
                <p>Guest privileges are not included with the WAKA Pay Membership.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">7. Payment Processing</h2>
                <p>7.1. You are responsible for ensuring sufficient funds are available in your debit or credit card account to cover the monthly membership fee.</p>
                <p>7.2. Late or declined payments may result in a late fee and/or membership suspension until the outstanding balance is paid.</p>
                <p>7.3. In case of a missed payment or card issue, you have the option to validate your membership through Momo payments at the front desk.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">8. Use of Equipment and Facilities</h2>
                <p>8.1. You are responsible for using the gym equipment and facilities safely and in accordance with all posted instructions.</p>
                <p>8.2. You may be required to complete an orientation or training session before using certain equipment.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">9. Member Conduct and Safety</h2>
                <p>9.1. You agree to abide by all gym rules and regulations displayed on the gym floor.</p>
                <p>9.2. The Gym reserves the right to terminate your membership for violations of these rules.</p>
                <p>9.3. The Gym has established emergency procedures in case of accidents or injuries.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">10. Liability and Disclaimer</h2>
                <p>10.1. You agree to waive and release the Gym from any liability for injuries or damages sustained while using the gym facilities.</p>
                <p>10.2. The Gym disclaims any warranty regarding the effectiveness of exercise programs or fitness advice.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">11. Medical Considerations</h2>
                <p>It is recommended that you consult with a healthcare professional before starting a new exercise program.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">12. Client Information</h2>
                <p>12.1. We collect your debit or credit card information for secure automatic payment processing.</p>
                <p>12.2. We utilize industry-standard security measures to protect your information, including encryption and secure storage practices.</p>
                <p>12.3. We will only use your information for membership management purposes and will not share it with third parties without your consent, except as required by law.</p>
                <p>12.4. You have the right to access and update your information at any time. Please contact <span className="font-bold">info@wakafitness.com</span>  for any requests.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">13. Entire Agreement</h2>
                <p>These Terms constitute the entire agreement between you and the Gym regarding the WAKA Pay Membership.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">14. Governing Law and Jurisdiction</h2>
                <p>These Terms will be governed by and construed in accordance with the laws of Rwanda.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">15. Severability</h2>
                <p>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">16. Changes to Terms</h2>
                <p>The Gym reserves the right to modify these Terms and Conditions at any time. We will provide reasonable notice of any changes by posting them on the gym website and/or sending an email notification to members.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">17. Data Processing and Cyber Security</h2>
                <p>17.1. We comply with all applicable government of Rwanda’s data processing requirements and cyber security laws.</p>
                <p>17.2. We will retain your information for the duration of your membership and for a reasonable period thereafter for administrative purposes.</p>
                <p>17.3. You have the right to request that we delete your information upon termination of your membership, subject to any legal or regulatory requirements.</p>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">18. Contact Information</h2>
                <p>If you have any questions regarding these Terms and Conditions, please contact us at:</p>
                <ul className="list-disc pl-5">
                    <li className={'ml-3'}>Email: info@wakafitness.com</li>
                    <li className={'ml-3'}>Phone: (+250) 783 189 029</li>
                    <li className={'ml-3'}>In Person: DOWNTOWN , YYUSSA Complex</li>
                </ul>
            </section>

            <section className="mb-8 gap-2 flex flex-col">
                <h2 className="text-2xl font-semibold mb-2">19. Agreement</h2>
                <p>By enrolling in WAKA Pay, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
            </section>
        </div>
    );
};

export default TermsAndConditionsSpecial;
