/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get_categories, get_packages } from '~/api';
import { CategoryType } from '~/app/types/category';
import { PackageType } from '~/app/types/package';
import { StoreType } from '~/app/types/redux';
import { Button } from '~/components/elements';
import { format } from '~/core/utils';
import { PaginationType } from '~/app/types/pagination';

type PackageContainerProps = {
    onNext: (...args: any) => void;
};

export const ExtractArrayFromString = (input: string): Array<string> => {
    try {
        // Use regex to extract content between the first and last single quote
        const match = input.match(/^\['(.*)'\]$/);

        if (match && match[1]) {
            // Convert the matched content to a JSON-compatible format and parse it
            return JSON.parse(`["${match[1].replace(/"/g, '\\"')}"]`);
        }

        return ['null'];
    } catch (e) {
        console.log(e);
        return ['null'];
    }
};

export const PackageContainer = (props: PackageContainerProps) => {
    const { onNext } = props;

    const handleOnClick = (value: PackageType) => {
        onNext(value);
    };
    const [categories, setCategories] = useState<CategoryType[] | undefined>();

    const [tab, setTab] = useState<string>('');

    const [paginate, setPaginate] = useState(1);

    const [total, setTotal] = useState(0);

    const [start, setStart] = useState<number>(0);

    const [end, setEnd] = useState<number>(6);

    const handlePaginate = (page: number) => {
        setPaginate(page);
        const pageSize = 3; // Number of items per page

        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        setStart(startIndex);
        setEnd(endIndex);
    };

    const [show, setShow] = useState<PackageType[]>();

    const [packages, setPackages] =
        useState<PackageType[] | null>();

    const handleGetPackages = async () => {
        try {
            const data: PaginationType<PackageType> = await get_packages('pageSize=100');
            setPackages(data.list.filter((it) => it.status.toLowerCase() === 'active'));
        } catch (error: any) {
            console.log(error.response.message);
        }
    };

    useEffect(() => {
        handleGetPackages();
    }, []);


    const getCategories = async () => {
        try {
            const categories = await get_categories();
            setCategories(
                (categories.list as CategoryType[]).filter(
                    obj => obj.status === 'Active'
                )
            );
            setTab(
                categories
                    ? (categories.list as CategoryType[]).filter(
                          obj => obj.status === 'Active'
                      )[0].id
                    : ''
            );
        } catch (error: any) {
            toast.error(error.response.message);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6; // Number of items per page

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(
            startIndex + itemsPerPage,
            show ? show.length : 0
        );
        return show?.slice(startIndex, endIndex);
    };

    useEffect(() => {
        setShow(
            packages?.filter(obj => obj.category.id === tab)
        );
        if (packages) {
            setTotal(
                Math.ceil(
                    packages.filter(obj => obj.category.id === tab).length /
                        itemsPerPage
                )
            );
        }
    }, [tab, packages]);

    console.log(total);

    return (
        <div>
            <p className="text-center text-2xl font-bold uppercase text-white">
                Our Pricing
            </p>
            <div className="mt-3 w-full border-b border-red-600 text-light">
                <ul className="flex flex-row justify-around">
                    {categories
                        ?.filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .map((cat, index) => (
                            <li
                                key={index}
                                onClick={() => setTab(cat.id)}
                                className={`${
                                    tab === cat.id
                                        ? 'bg-red-600'
                                        : 'hover:bg-slate-700'
                                } w-1/3 cursor-pointer rounded-t p-2 text-center hover:text-light`}
                            >
                                {cat.name}
                            </li>
                        ))}
                </ul>
            </div>
            {!packages?.length ? (
                <div className="flex items-center justify-center py-5 text-white">
                    Loading available package....{' '}
                </div>
            ) : (
                <div className="mt-5 grid gap-2 sm:grid-cols-2 md:grid-cols-3">
                    {packages &&
                        getCurrentPageData()?.map((price, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    // console.log(price.);
                                    handleOnClick(price);
                                }}
                                className="hover mt-3 flex cursor-pointer flex-col justify-around border border-dark-light bg-dark-light hover:border-red-600"
                            >
                                <div className="border-b border-slate-700 p-5">
                                    <p className="text-center text-xl font-semibold uppercase text-danger">
                                        {format.currenyWithAmount(price.price)}{' '}
                                        {price.recurringStatus === 'true'
                                            ? '/ Month'
                                            : ''}
                                    </p>
                                </div>

                                <div className="p-2">
                                    <div className="text-center text-sm text-white">
                                        {price.recurringStatus === 'true' ? (
                                            <p>
                                                Waka {price.numberOfMonths}{' '}
                                                Month
                                                {price.numberOfMonths &&
                                                price.numberOfMonths > 1
                                                    ? 's'
                                                    : ''}{' '}
                                                Subscription
                                            </p>
                                        ) : (
                                            <p>{price.headline}</p>
                                        )}
                                    </div>
                                    <ul className="scrollbar-hidden mt-4 h-[150px] list-disc overflow-scroll px-4 transition-opacity md:px-6">
                                        {ExtractArrayFromString(
                                            price.description
                                        ).map((value: string, key: number) => (
                                            <li
                                                key={key}
                                                className="my-3 text-justify text-base leading-6 text-gray md:text-sm"
                                            >
                                                {value}
                                            </li>
                                        ))}
                                    </ul>

                                    <Button
                                        onClick={() => handleOnClick(price)}
                                        variant="btn-danger"
                                        className="mx-auto mt-8 w-full font-medium uppercase md:w-1/2"
                                    >
                                        Select
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>
            )}
            {packages && packages?.filter(obj => obj.category.id === tab)
                .slice(start, end).length < 3 ? null : (
                <div className="flex items-center justify-center gap-3 pt-5">
                    {Array.from({ length: total }, (_, index) => (
                        <button
                            key={index}
                            className={`duration-50 h-10 w-10 rounded-full px-2 py-2 duration-100 ${
                                currentPage === index + 1
                                    ? 'bg-danger text-white'
                                    : 'bg-white text-black'
                            }`}
                            onClick={() => onPageChange(index + 1)}
                            disabled={currentPage === index + 1}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
