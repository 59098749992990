import { AppProvider } from '~/core/providers';
import { Routes } from '~/Routes';
import WhatappButton from './components/containers/whatsapp';

const App = () => {
    return (
        <AppProvider>
            <Routes />
            <WhatappButton />
        </AppProvider>
    );
};

export default App;
