import { UpdateUserPayload, UserPayload, UserType } from '~/app/types';
import { ChangePasswordPayloadApi } from '~/components/pages/change-password';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString, searchString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const check_user = async (): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get('/users/check');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_user = async (userPayload: UserPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('users/registerusersbyadmin', {
            ...userPayload,
            username: userPayload.email,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const update_user_names = async (
    id: string,
    userPayload: UserPayload,
    selectedPayload: UpdateUserPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/users/${id}`, {
            email: selectedPayload.email,
            phone: userPayload.phone || selectedPayload.phone,
            firstname: userPayload.firstName || selectedPayload.firstName,
            lastname: userPayload.lastName || selectedPayload.lastName,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const update_user_password = async (
    id: string,
    userPayload: UserPayload,
    selectedPayload: UpdateUserPayload
): Promise<any> => {
    try {
        console.log({
            ...selectedPayload,
            password: userPayload.password || selectedPayload.password,
        });
        const request = await PRIVATE_API.patch(`/users/${id}`, {
            ...selectedPayload,
            password: userPayload.password || selectedPayload.password,
        });
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const disable_user = async (userId?: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/users/${userId}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const get_user = async (query: string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get(`/users/${query}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_all_users_search = async (
    query: string,
    keyword?: string,
    role = 'member',
    notRole?: string,
): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/users${queryString(query)}${
                query === '' ? '?' : '&'
            }${searchString(keyword)}${notRole ? `&notRole=${notRole}` : `&role=${role}`}`
        );
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const change_password = async (payload: ChangePasswordPayloadApi) => {
    try {
        const request = await PRIVATE_API.post(
            '/users/update-password',
            payload
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
