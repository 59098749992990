import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { Spinner } from '~/components/elements';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode;
    variant?:
        | 'btn-default'
        | 'btn-primary'
        | 'btn-secondary'
        | 'btn-success'
        | 'btn-danger';
    size?: 'btn-sm' | 'btn-md' | 'btn-lg';
    isLoading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        const {
            children,
            type = 'button',
            variant = 'btn-primary',
            size = 'btn-md',
            className,
            isLoading = false,
            ...rest
        } = props;

        return (
            <button
                ref={ref}
                type={type}
                disabled={isLoading}
                className={clsx('btn', variant, size, className)}
                {...rest}
            >
                {type === 'submit' ? (
                    isLoading ? (
                        <Spinner size="sm" variant="light" />
                    ) : (
                        children
                    )
                ) : (
                    children
                )}
            </button>
        );
    }
);
