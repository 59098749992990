import { PackageType } from '~/app/types/package';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { queryString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';
import { ServicePayload } from '~/app/types';

export const get_services = async (
    query?: string
): Promise<PackageType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/service${query ? `${queryString(query)}` : ''}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const create_service = async (
    servicePayload: ServicePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/service', servicePayload);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const update_service = async (
    id: string,
    servicePayload: ServicePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(
            `/service/${id}`,
            servicePayload
        );
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const delete_service = async (id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.delete(`/service/${id}`);
        return request.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
