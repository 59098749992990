/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    Modal,
    TableColumn,
} from '~/components/elements';
import UserImage from '~/assets/images/user.png';
import { UserFilterPayload, UserPayload, UserType } from '~/app/types';
import React, { useEffect, useState } from 'react';
import { EyeIcon, PencilAltIcon, XCircleIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { create_user, get_all_users_search } from '~/api';
import { FrontDeskDisplayUser } from './modals/user/FrontDeskDisplayUserModal';
import { format } from '~/core/utils';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';
import { DashFilterUserComponent } from '~/components/pages/account/common/DashUsersFilter';

const schema = z.object({
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    email: z.string().email({ message: 'Email is invalid' }),
    password: z
        .string()
        .min(1, { message: 'Password is required' })
        .max(16, { message: 'Password is too Long' }),
    phone: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must be inform of 2507........' }
    ),
});

export const DashFrontDeskUsers = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const location = useLocation();
    const query = location.search;

    const [isDisplayUserModalOpen, setIsDisplayUserModalOpen] =
        useState<boolean>(false);

    const [users, setUsers] = useState<PaginationType<UserType> | null>();

    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [keyword, setKeyword] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleGetUsers(keyword);
        setKeyword(e.target.value);
    };

    const handleGetUsers = async (keyword = '') => {
        try {
            setIsLoading(true);
            const data: PaginationType<UserType> = await get_all_users_search(
                query,
                keyword
            );
            setUsers(data);
        } catch (error: any) {
            // toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const openCreateUser = useDisclosure();
    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            await create_user({ ...e, role: 'member' }).then(() => {
                toast.success('User Created');
                openCreateUser.onClose();
            });
        } catch (error) {
            toast.error(`User not created. ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const columns: TableColumn<UserType>[] = [
        {
            title: 'Profile Image',
            cell: () => (
                <div className="mx-auto h-10 w-10 md:h-12 md:w-12">
                    <img
                        alt="Honore"
                        src={UserImage}
                        className="h-full w-full rounded-full"
                    />
                </div>
            ),
        },
        {
            title: 'Firstname',
            cell: row => row.firstname,
        },
        {
            title: 'Lastname',
            cell: row => row.lastname,
        },
        {
            title: 'Telephone',
            cell: row => row.phone,
        },
        {
            title: 'Email',
            cell: row => row.email,
        },
        {
            title: 'Status',
            cell: row => (
                <p
                    className={
                        row.is_active ? 'text-green-600' : 'text-red-600'
                    }
                >
                    {row.is_active ? 'Active' : 'Inactive'}
                </p>
            ),
        },
        {
            title: 'Subscription',
            cell: row => (
                <div className={'flex justify-center items-center'}>
                    <p
                        className={`block w-full rounded-full py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white
                    ${row.subscriptionStatus == 'active' ? 'bg-green-600' : row.subscriptionStatus == 'inactive' ? 'bg-red-600' : 'bg-yellow-600'}`}
                    >
                        {row.subscriptionStatus ?? 'INVALID'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-3">
                    <EyeIcon
                        className="w-5 cursor-pointer"
                        onClick={() => {
                            setSelectedUser(row);
                            setIsDisplayUserModalOpen(true);
                        }}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        handleGetUsers();
    }, [query]);

    const [filters, setFilters] = useState<UserFilterPayload>({
        status: '',
    });

    return (
        <>
            <div className="float-right flex flex-wrap justify-between  gap-4 whitespace-nowrap px-6 py-4">
                <div className="flex flex-wrap gap-3">
                    <DashFilterUserComponent filters={filters}  onFilter={(payload: UserFilterPayload) => {
                        setFilters(payload);
                    }}/>
                    <button
                        onClick={() => openCreateUser.onOpen()}
                        className="rounded-md bg-red-600 p-2 text-light"
                    >
                        Create Client
                    </button>
                    <input
                        type="text"
                        className="flex items-center rounded-md border border-slate-300 bg-slate-200 text-base  font-medium"
                        placeholder="Search..."
                        defaultValue={keyword}
                        id="search"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={users?.list ?? []}
                isLoading={isLoading}
                total={users?.total ?? 0}
                nextPage={users?.nextPage ?? 0}
                lastPage={users?.lastPage ?? 0}
                currentPage={users?.currentPage ?? 0}
                previousPage={users?.previousPage ?? 0}
            />

            <FrontDeskDisplayUser
                isOpen={isDisplayUserModalOpen}
                onClose={() => setIsDisplayUserModalOpen(false)}
                selectedUser={selectedUser}
            />

            <Modal
                title="Create Client"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openCreateUser.isOpen}
                onClose={openCreateUser.onClose}
            >
                <Form<UserPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleCreate}
                >
                    {({ register, watch, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <InputField
                                    type="text"
                                    registration={register('firstName')}
                                    className="h-12"
                                    error={formState.errors.firstName}
                                    label="First Name"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    registration={register('lastName')}
                                    className="h-12"
                                    error={formState.errors.lastName}
                                    label="Last Name"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="email"
                                    registration={register('email')}
                                    className="h-12"
                                    error={formState.errors.email}
                                    label="Email"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    registration={register('phone')}
                                    className="h-12"
                                    error={formState.errors.phone}
                                    label="Phone Number"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="password"
                                    registration={register('password')}
                                    className="h-12"
                                    error={formState.errors.password}
                                    label="Password"
                                    isLoading={isLoadingCreate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingCreate}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};
