/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    DataTable,
    Form,
    InputField,
    Modal,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import { LocationPayload, LocationType } from '~/app/types/location';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import { useContext, useEffect, useState } from 'react';
import {
    create_location,
    delete_location,
    get_locations,
    update_location,
} from '~/api';
import { ExportContext, ExportContextType } from './ExportContextProvider';
import { exportLocation } from '~/core/helper/csv/location';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '~/core/hooks';
import { z } from 'zod';

const schema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    district: z.string().min(1, { message: 'District is required' }),
    street: z.string().min(1, { message: 'Street is required' }),
    contact: z.string().min(1, { message: 'District is required' }),
    address: z.string().min(1, { message: 'Address is required' }),
    link: z.string().min(1, { message: 'Location is required' }),
});

const update_schema = z.object({
    name: z.string(),
    district: z.string(),
    street: z.string(),
    contact: z.string(),
    link: z.string(),
    address: z.string(),
});

export const DashAdminLocation = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

    const openCreateLocation = useDisclosure();
    const openUpdateLocation = useDisclosure();
    const openDeleteLocation = useDisclosure();

    const location = useLocation();
    const query = location.search;

    const [selected, setSelected] = useState<LocationType | undefined>();

    const [locations, setLocations] =
        useState<PaginationType<LocationType> | null>();

    const { setExportData } = useContext<ExportContextType>(ExportContext);

    const handleGetLocations = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<LocationType> = await get_locations(
                query
            );
            setLocations(data);
            setExportData(exportLocation(data?.list ?? []));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<LocationType>[] = [
        {
            title: 'Location Name',
            cell: row => row.name,
        },
        {
            title: 'District',
            cell: row => row.district,
        },
        {
            title: 'Address',
            cell: row => row.address,
        },
        {
            title: 'Street',
            cell: row => row.street,
        },
        {
            title: 'Contact',
            cell: row => row.contact,
        },
        // {
        //     title: 'Created at',
        //     cell: row => format.humanDate(row.created_at),
        // },
        // {
        //     title: 'Updated at',
        //     cell: row => format.humanDate(row.updated_at),
        // },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-5">
                    <TrashIcon
                        className="w-5"
                        onClick={() => {
                            setSelected(row);
                            openDeleteLocation.onOpen();
                        }}
                    />
                    <PencilAltIcon
                        className="w-5"
                        onClick={() => {
                            setSelected(row);
                            openUpdateLocation.onOpen();
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            const data = await create_location(e).then(() => {
                toast.success('Location Created');
                openCreateLocation.onClose();
            });
        } catch (error) {
            toast.error(`Location not created: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (e: LocationPayload) => {
        try {
            setIsLoadingUpdate(true);
            await update_location(selected!, e).then(() => {
                toast.success('Location updated');
                openUpdateLocation.onClose();
            });
        } catch (error) {
            toast.error(`Location not updated: ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_location(selected!.id).then(() => {
                toast.success('Location deleted');
                openDeleteLocation.onClose();
            });
        } catch (error) {
            toast.error(`Location not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    useEffect(() => {
        handleGetLocations();
    }, [query, isLoadingCreate, isLoadingDelete, isLoadingUpdate]);

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-end p-2">
                    <button
                        onClick={() => openCreateLocation.onOpen()}
                        className="rounded-md bg-red-600 px-2 py-1 text-light"
                    >
                        Create Location
                    </button>
                </div>
                <Modal
                    title="Create Location"
                    size="md:w-1/2 max-w-[500px] w-11/12"
                    isOpen={openCreateLocation.isOpen}
                    onClose={openCreateLocation.onClose}
                >
                    <Form<LocationPayload, typeof schema>
                        schema={schema}
                        onSubmit={handleCreate}
                    >
                        {({ register, formState }) => (
                            <div className="min-w-80">
                                <div className="flex flex-col gap-4">
                                    <InputField
                                        type="text"
                                        registration={register('name')}
                                        className="h-12"
                                        error={formState.errors.name}
                                        label="Location Name"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('district')}
                                        className="h-12"
                                        error={formState.errors.district}
                                        label="District"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('address')}
                                        className="h-12"
                                        error={formState.errors.address}
                                        label="Address"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('link')}
                                        className="h-12"
                                        error={formState.errors.link}
                                        label="Link"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('contact')}
                                        className="h-12"
                                        error={formState.errors.contact}
                                        label="Contact"
                                        isLoading={isLoadingCreate}
                                    />
                                    <InputField
                                        type="text"
                                        registration={register('street')}
                                        className="h-12"
                                        error={formState.errors.street}
                                        label="Street"
                                        isLoading={isLoadingCreate}
                                    />
                                </div>

                                <div className="flex-end flex gap-2">
                                    <Button
                                        type="submit"
                                        variant="btn-danger"
                                        className="mt-6 w-full"
                                        isLoading={isLoadingCreate}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
            </div>
            <DataTable
                columns={columns}
                data={locations?.list ?? []}
                isLoading={isLoading}
                total={locations?.total ?? 0}
                lastPage={locations?.lastPage ?? 0}
                nextPage={locations?.nextPage ?? 0}
                currentPage={locations?.currentPage ?? 0}
                previousPage={locations?.previousPage ?? 0}
            />
            <Modal
                title="Update Location"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateLocation.isOpen}
                onClose={openUpdateLocation.onClose}
            >
                <Form<LocationPayload, typeof update_schema>
                    schema={update_schema}
                    onSubmit={handleUpdate}
                >
                    {({ register, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <InputField
                                    type="text"
                                    defaultValue={selected?.name}
                                    registration={register('name')}
                                    className="h-12"
                                    error={formState.errors.name}
                                    label="Location Name"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    defaultValue={selected?.district}
                                    registration={register('district')}
                                    className="h-12"
                                    error={formState.errors.district}
                                    label="District"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    defaultValue={selected?.address}
                                    registration={register('address')}
                                    className="h-12"
                                    error={formState.errors.address}
                                    label="Address"
                                    isLoading={isLoadingUpdate}
                                />
                                <InputField
                                    type="text"
                                    defaultValue={selected?.link}
                                    registration={register('link')}
                                    className="h-12"
                                    error={formState.errors.link}
                                    label="Link"
                                    isLoading={isLoadingCreate}
                                />
                                <InputField
                                    type="text"
                                    defaultValue={selected?.contact}
                                    registration={register('contact')}
                                    className="h-12"
                                    error={formState.errors.contact}
                                    label="Contact"
                                    isLoading={isLoadingUpdate}
                                />
                                <InputField
                                    type="text"
                                    defaultValue={selected?.street}
                                    registration={register('street')}
                                    className="h-12"
                                    error={formState.errors.street}
                                    label="Street"
                                    isLoading={isLoadingCreate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoadingCreate}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            <Modal
                title="Delete Locations"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDeleteLocation.isOpen}
                onClose={openDeleteLocation.onClose}
            >
                <form onSubmit={handleDelete}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to delete{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selected?.name}
                            </span>{' '}
                            location?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};
