import { useSelector } from 'react-redux';
import chat from '../../../../src/assets/images/whatsapp-circle.svg';
import { StoreType } from '~/app/types/redux';

export default function WhatappButton() {
    return (
        <div className="fixed right-5 bottom-3 rounded-full bg-green-600 p-4">
            <a href="http://wa.me/+250794106015" target="_blank">
                <img src={chat} alt="chat" className=" w-10" />
            </a>
        </div>
    );
}
