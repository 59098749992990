import { useState, useCallback } from 'react';

export const useDisclosure = (initial = false) => {
    const [isOpen, setIsOpen] = useState<boolean>(initial);

    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleToggle = useCallback(() => setIsOpen(prev => !prev), []);

    return {
        isOpen,
        onOpen: handleOpen,
        onClose: handleClose,
        onToggle: handleToggle,
    };
};
