import { BellIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { useEffect, useState } from 'react';
import config from '~/config';
import { PRIVATE_API } from '~/api';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { storage } from '~/core/utils';

function extractUnreadCount(input: string): number {
    // Extract the JSON part using regex
    const jsonMatch = input.match(/data:\s*(\{.*\})/);

    // If the JSON part is found, parse it
    if (jsonMatch && jsonMatch[1]) {
        try {
            const data = JSON.parse(jsonMatch[1]);
            // Return the unreadCount as a number
            return data.unreadCount ?? 0;
        } catch (error) {
            console.error("Failed to parse JSON:", error);
        }
    }

    // Return 0 if unreadCount is not found or any error occurs
    return 0;
}

const useGetNotificationCount = () => {
    const [notificationsCount, setNotificationsCount] = useState<number>(0);

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const request = await PRIVATE_API.get(
                    '/notifications/count'
                , {
                        headers: {
                            'Accept': 'text/event-stream',
                        },
                        onDownloadProgress: (evt) => {
                            let data = evt.event.target.responseText;
                            setNotificationsCount(extractUnreadCount(data));
                        }
                    });
            } catch (error: any) {
                throw new CustomError(AxiosErrorHandler(error));
            }
        };

        getNotifications().then(() => null);
    }, []);

    return {
        notificationsCount,
    };

};

export function NotificationButton() {
    const { role } = useSelector((state: StoreType) => state.user.userAccount);
    const { notificationsCount } = useGetNotificationCount();

    if (role === 'admin' || role === 'frontdesk') {
        return null;
    }

    return (
        <Link to={'notifications'} className={'p-3 relative bg-dark-light border border-white/20 rounded-2xl cursor-pointer'}>
            {notificationsCount > 0 && (
                <div className="p-1 absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 border-white/30 border text-xs flex justify-center items-center">
                    {notificationsCount > 99 ? '+99' : notificationsCount}
                </div>
            )}
            <BellIcon className={'w-5 text-white'} />
        </Link>
    );
}
