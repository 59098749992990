import { createSlice } from '@reduxjs/toolkit';

const packageSlice = createSlice({
    name: 'packageData',
    initialState: {
        packageData: [],
    },
    reducers: {
        addPackage: (state, action) => {
            state.packageData = action.payload;
        },
        removePackage: state => {
            state.packageData = [];
        },
        updatePackage: (state, action) => {
            state.packageData = { ...state.packageData, ...action.payload };
        },
    },
});

export const { addPackage, removePackage, updatePackage } =
    packageSlice.actions;

export default packageSlice.reducer;
