import { NavBar } from '~/components/elements';
import { useAuthentication } from '~/core/hooks';
import { RegisterSection } from '~/components/sections';
import { CheckUser } from '~/core/hooks/useUser';

export const HomePage = () => {
    useAuthentication({ isAuth: false });

    CheckUser();

    return (
        <>
            <NavBar />

            <main className="container py-12">
                <RegisterSection />
            </main>
        </>
    );
};
