/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as z from 'zod';
import * as XLSX from 'xlsx';
import { DocumentIcon, FilterIcon } from '@heroicons/react/outline';
import {
    Modal,
    Button,
    Form,
    SelectField,
    InputField,
} from '~/components/elements';
import { useDisclosure } from '~/core/hooks';
import { format } from '~/core/utils';
import { useContext, useEffect, useState } from 'react';
import {
    UserFilterPayload,
    UserType,
} from '~/app/types';
import {
    ExportContext,
    ExportContextType,
} from '../admin/ExportContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { PackageType } from '~/app/types/package';
import { LocationType } from '~/app/types/location';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_locations, get_packages } from '~/api';
import useFetch from '~/core/hooks/useFetch';
import { PaginationType } from '~/app/types/pagination';
import { toast } from 'react-toastify';
import { paginationString } from '~/core/utils/pagination.str';
import { addLocation } from '~/core/redux/slices/location/locationSlice';
import { addPackage } from '~/core/redux/slices/package/packageSlice';

const schema = z.object({
    location_id: z.string().optional(),
    package_id: z.string().optional(),
    user_id: z.string().optional(),
    from: z.string().optional(),
    to: z.string().optional(),
    status: z.string().optional(),
    paymentMode: z.string().optional(),
});

type DashFilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
};

type OptionType = {
    value: string;
    label: string;
};

export const defaultTransactionFilterPayload: UserFilterPayload = {
    status: '',
};

export const DashFilterUserComponent = (props: DashFilterProps) => {
    const { filters, onFilter } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { exportData } = useContext<ExportContextType>(ExportContext);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { userAccount } = useSelector((state: StoreType) => state.user);

    const { locationData } = useSelector((state: StoreType) => state.location);

    const { packageData } = useSelector((state: StoreType) => state.package);

    const [loadingMembers, error, members] = useFetch<PaginationType<UserType>>(
        'GET',
        `/users?${paginationString(0, 10000)}`
    );

    const getLocations = async () => {
        try {
            setIsLoading(true);
            const locations: PaginationType<LocationType> =
                await get_locations();
            dispatch(addLocation(locations.list));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };
    const getPackages = async () => {
        try {
            setIsLoading(true);
            const packages: PaginationType<PackageType> = await get_packages();
            dispatch(addPackage(packages.list));
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(true);
        }
    };

    const handleOnSubmit = (payload: UserFilterPayload) => {
        const searchParams = new URLSearchParams(location.search);

        const {
            status,
            package_id
        } = payload;

        if (status) searchParams.set('subscriptionStatus', status);
        if (package_id) searchParams.set('packageId', package_id);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        onClose();
    };

    const resetFilter = () => {
        onFilter(defaultTransactionFilterPayload);
        navigate(`${location.pathname}`);
        onClose();
    };

    const handleExport = () => {
        const link = document.createElement('a');

        let csvText = exportData;

        if (csvText === null) return;

        const fileName = `WakaPay - ${format.todayDate()}.csv`;

        if (!csvText.match(/^data:text\/csv/i))
            csvText = `data:text/csv;charset=utf-8,${csvText}`;

        link.setAttribute('href', encodeURI(csvText));
        link.setAttribute('download', fileName);
        link.click();
    };

    const formatedPackages: OptionType[] = packageData.map(
        (currentPackage: PackageType) => {
            return {
                value: currentPackage.id.toString(),
                label: `${currentPackage.name}`,
            };
        }
    );

    useEffect(() => {
        if (!locationData.length) getLocations();
        if (!packageData.length) getPackages();
    }, []);
    return (
        <>
            <div className="flex flex-wrap justify-between gap-4">
                {/* <h2 className="text-2xl font-medium capitalize text-white">
                    Transactions
                </h2> */}

                <div className="flex gap-3">
                    <Button
                        variant="btn-danger"
                        className="px-3"
                        onClick={onOpen}
                    >
                        <FilterIcon className="icon-md" />
                    </Button>
                </div>
            </div>

            <Modal
                title="Filter"
                size="max-w-sm"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Form<UserFilterPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleOnSubmit}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <>
                            <div className="flex flex-col gap-4">
                                <SelectField
                                    placeholder="Subscription Status"
                                    options={[
                                        {
                                            value: 'active',
                                            label: 'Active',
                                        },
                                        {
                                            value: 'inactive',
                                            label: 'Inactive',
                                        },
                                        {
                                            value: 'onhold',
                                            label: 'Onhold',
                                        },
                                        {
                                            value: 'expired',
                                            label: 'Expired',
                                        },
                                    ]}
                                    error={formState.errors.status}
                                    registration={register('status')}
                                    className="h-12"
                                />
                                <SelectField
                                    placeholder="Package"
                                    options={formatedPackages}
                                    error={formState.errors.package_id}
                                    registration={register('package_id')}
                                    className="h-12"
                                    isLoading={isLoading}
                                />
                            </div>

                            <div className="flex gap-2">
                                <Button
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    onClick={resetFilter}
                                    isLoading={isLoading}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={isLoading}
                                >
                                    Filter
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export const handleExportExcel = (exportData: any[]) => {
    console.log(exportData);
    const workbook = XLSX.utils.book_new();

    // Assuming exportData is an array of arrays representing rows and columns of data
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Create a blob from workbook
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const fileName = `WakaPay - ${format.todayDate()}.xlsx`;

    const data = new Blob([excelBuffer], {
        type: 'application/octet-stream',
    });

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = fileName;
    link.click();
};
