import { AuthLoginPayload, MemberPayload } from '~/app/types';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { PRIVATE_API } from '../axios';

export type RegisterUserPayload = {
    access_token: string;
};

export type LoginUserPayload = {
    access_token: string;
};

export const register_user = async (
    memberPayload: MemberPayload
): Promise<RegisterUserPayload | any> => {
    try {
        const request = await PRIVATE_API.post('/users/register', {
            ...memberPayload,
            username: memberPayload.email,
        });
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const login_user = async (
    userCredentials: AuthLoginPayload
): Promise<LoginUserPayload | any> => {
    try {
        const request = await PRIVATE_API.post('/users/login', userCredentials);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
