import { useRoutes } from 'react-router-dom';
import { DashMemberSubscription } from './components/pages/account/member/DashMemberSubscription';
import { DashMemberTransaction } from './components/pages/account/member/DashMemberTransaction';
import NotFoundPage from './components/pages/notfound';
import HomeRoute from './app/routes/home';
import LoginRoute from './app/routes/login';
import ResetPasswordRoute from './app/routes/reset';
import {
    AccountLayout,
    AdminLayout,
    FrontDeskLayout,
    MemberLayout,
} from '~/components/layouts';
import { ChangePasswordPage } from './components/pages/change-password';
import {
    AdminRouteProtector,
    FrontDeskRouteProtector,
    MemberRouteProtector,
} from './core/protector';
import { HomeRouteProtector } from './core/protector/home';
import { DashFrontDeskTransaction } from './components/pages/account/frontdesk/DashFrontDeskTransaction';
import { DashFrontDeskLocation } from './components/pages/account/frontdesk/DashFrontDeskLocations';
import { DashFrontDeskPackage } from './components/pages/account/frontdesk/DashFrontDeskPackages';
import { DashAdminTransaction } from './components/pages/account/admin/DashAdminTransaction';
import { DashAdminLocation } from './components/pages/account/admin/DashAdminLocations';
import { DashAdminPackage } from './components/pages/account/admin/DashAdminPackages';
import { AccountProtector } from './core/protector/account';
import DashMemberCreateSubscription from './components/pages/account/member/DashMemberCreateSubscription';
import { DashAdminUsers } from './components/pages/account/admin/DashAdminUsers';
import { DashFrontDeskUsers } from './components/pages/account/frontdesk/DashFrontDeskUsers';
import { DashAdminCategories } from './components/pages/account/admin/DashAdminCategories';
import { DashAdminCronJobs } from './components/pages/account/admin/DashAdminCronJobs';
import Payment from './components/pages/payment';
import { DashFrontDeskMyTransactions } from './components/pages/account/frontdesk/DashFrontDeskMyTransactions';
import { DashFrontDeskLeaderBoard } from './components/pages/account/frontdesk/DashFrontDeskLeaderboard';
import { DashAdminServices } from './components/pages/account/admin/DashAdminServices';
import { DashAdminLeaderBoard } from './components/pages/account/admin/DashAdminLeaderboard';
import { AuthProvider } from '~/components/layouts/auth/authprovider';
import { DashAdminAdmins } from '~/components/pages/account/admin/DashAdminAdmins';
import { DashAdminFrontDesks } from '~/components/pages/account/admin/DashAdminFrontDesks';
import { DashAdminSubscription } from '~/components/pages/account/admin/DashAdminSubscription';
import LandingPage from '~/components/pages/landing';
import NotificationsPage from '~/components/containers/notifications/notifications_page';
import DashAdminFrontDeskTransactions from '~/components/pages/account/admin/DashAdminFrontDeskTransactions';

export const Routes = () => {
    return useRoutes([
        // { path: '/', element: <LoginRoute /> },
        {
            path: '/',
            element: <HomeRouteProtector /> ,
            children: [
                {
                    index: true,
                    element: <LandingPage />
                },
                {
                    path: 'home',
                    element: <HomeRoute />
                }
            ],
        },
        { path: 'login', element: <LoginRoute /> },
        { path: 'reset-password', element: <ResetPasswordRoute /> },
        {
            path: 'change-password',
            element: <ChangePasswordPage />,
        },
        {
            path: 'account',
            element: <AuthProvider><AccountLayout /></AuthProvider> ,
            caseSensitive: true,
            children: [
                {
                    index: true,
                    element: <AccountProtector />,
                },
                {
                    path: 'admin',
                    element: <AdminRouteProtector element={<AdminLayout />} />,
                    children: [
                        {
                            index: true,
                            element: <DashAdminTransaction />,
                        },
                        { path: 'clients', element: <DashAdminUsers /> },
                        { path: 'users', element: <DashAdminFrontDesks /> },
                        { path: 'subscriptions', element: <DashAdminSubscription /> },
                        {
                            path: 'locations',
                            element: <DashAdminLocation />,
                        },
                        {
                            path: 'packages',
                            element: <DashAdminPackage />,
                        },
                        {
                            path: 'categories',
                            element: <DashAdminCategories />,
                        },
                        {
                            path: 'services',
                            element: <DashAdminServices />,
                        },
                        {
                            path: 'cronjobs',
                            element: <DashAdminCronJobs />,
                        },
                        {
                            path: 'leaderboard',
                            element: <DashAdminLeaderBoard />,
                        },
                        {
                            path: 'leaderboard/frontdesk/:id',
                            element: <DashAdminFrontDeskTransactions />
                        }
                    ],
                },
                {
                    path: 'member',
                    element: (
                        <MemberRouteProtector element={<MemberLayout />} />
                    ),
                    children: [
                        {
                            index: true,
                            element: <DashMemberTransaction />,
                        },
                        {
                            path: 'subscriptions',
                            element: <DashMemberSubscription />,
                        },
                        {
                            path: 'create',
                            children: [
                                {
                                    path: 'subscription',
                                    element: <DashMemberCreateSubscription />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'frontdesk',
                    element: (
                        <FrontDeskRouteProtector
                            element={<FrontDeskLayout />}
                        />
                    ),
                    children: [
                        {
                            path: 'clients',
                            element: <DashFrontDeskUsers />,
                        },
                        // {
                        //     path: 'transactions',
                        //     element: <DashFrontDeskTransaction />,
                        // },
                        {
                            index: true,
                            element: <DashFrontDeskMyTransactions />,
                        },
                        {
                            path: 'leaderboard',
                            element: <DashFrontDeskLeaderBoard />,
                        },
                        // {
                        //     path: 'locations',
                        //     element: <DashFrontDeskLocation />,
                        // },
                        {
                            path: 'packages',
                            element: <DashFrontDeskPackage />,
                        },
                    ],
                },
                {
                    path: 'notifications',
                    element: <NotificationsPage />
                },
            ],
        },
        {
            path: '/payment/:id',
            element: <Payment />,
        },
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ]);
};
