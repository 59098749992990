import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
    name: 'locationData',
    initialState: {
        locationData: [],
    },
    reducers: {
        addLocation: (state, action) => {
            state.locationData = action.payload;
        },
        removeLocation: state => {
            state.locationData = [];
        },
        updateLocation: (state, action) => {
            state.locationData = { ...state.locationData, ...action.payload };
        },
    },
});

export const { addLocation, removeLocation, updateLocation } =
    locationSlice.actions;

export default locationSlice.reducer;
