import axios, { AxiosInstance } from 'axios';
import config from '~/config';
import { storage } from '~/core/utils';

export const PRIVATE_API: AxiosInstance = axios.create({
    baseURL: `${config.BASE_URI}/api/v1/`,
});

PRIVATE_API.interceptors.request.use(
    async (config) => {
        const token = storage.getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);