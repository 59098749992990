import { exportToCSV, format } from '~/core/utils';
import { SubscriptionType } from '~/app/types/subscription';

export const exportSubscription = (transactionData: SubscriptionType[]): string =>
    exportToCSV<SubscriptionType>(
        [
            {
                label: 'Name',
                selector: row =>
                    row.user
                        ? `${row.user.firstname} ${row.user.lastname}`
                        : 'Not Available',
            },
            {
                label: 'Email',
                selector: row => (row.user ? row.user.email : 'Not Available'),
            },
            {
                label: 'Telephone',
                selector: row => (row.user ? row.user.phone : 'Not Available'),
            },
            {
                label: 'Location',
                selector: row => row.location.address,
            },
            {
                label: 'Package',
                selector: row => row.package.name,
            },
            {
                label: 'Status',
                selector: row => row.status,
            },
        ],
        transactionData
    );
