/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    CheckCircleIcon,
    CreditCardIcon,
    DocumentReportIcon,
    ExclamationIcon, EyeIcon,
    PauseIcon,
    PencilAltIcon, PlayIcon,
    TrashIcon,
} from '@heroicons/react/outline';
import {
    Button,
    DashCard,
    DataTable,
    Form,
    InputField,
    Modal,
    SelectField,
    Spinner,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import {
    front_desk_get_user_stats,
    front_desk_get_user_transactions,
} from '~/api/stats/member.stats';
import { PaginationType } from '~/app/types/pagination';
import {
    AddTransactionPayload, canOrPauPayload,
    CronjobPayload,
    CronjobType,
    TransactionType,
} from '~/app/types';
import {
    add_transaction,
    cancel_subscription, cancelSubscription,
    create_cronjob,
    delete_cronjob,
    get_cronjobs, get_cronjobs_user,
    get_packages, get_subscriptions_balance,
    get_subscriptions_by_id,
    pause_subscription, pauseSubscription,
    retry_transaction, run_cronjob,
    update_cronjob,
} from '~/api';
import { toast } from 'react-toastify';
import { useDisclosure } from '~/core/hooks';
import { useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { SubscriptionType } from '~/app/types/subscription';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, StopIcon } from '@heroicons/react/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '~/assets/icons';
import RetryComponent from '../../../common/RetryComponent';
import { z } from 'zod';
import { PackageType } from '~/app/types/package';
import { handleExportExcel } from '../../../common/DashAdminFilter';
import { DataTableModal } from '~/components/elements/datatable/index_two';
import { Autoplay } from 'swiper';
import {
    NotificationBox,
    NotificationResponse,
    useGetNotificationByAdmin,
} from '~/components/containers/notifications/notifications_page';

export type StatsDataType = {
    balance: number;
    paid: number;
    total: number;
    unpaid: number;
    failure: number;
    success: number;
    totalTransaction: number;
};

export type DisplayUserFrontDeskStats = {
    user_id: string;
};

export type DashUserTransactionStats = {
    total: number;
    succeeded: number;
    failed: number;
};

type OptionType = {
    value: string;
    label: string;
};

const schema = z.object({
    packageId: z.string().min(1, { message: 'Package is required' }),
    scheduled_on: z.string().min(1, { message: 'Date Scheduled is required' }),
    numberOfMonths: z
        .string()
        .min(1, { message: 'Number of months is required' }),
});

export const DashFrontDeskDisplayUserStats = (
    props: DisplayUserFrontDeskStats
) => {
    const { user_id } = props;

    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const retryActions = useDisclosure();
    const openCreateJob = useDisclosure();
    const openUpdateJob = useDisclosure();
    const openDeleteJob = useDisclosure();
    const openReRunJob = useDisclosure();
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isLoadingReRun, setIsLoadingReRun] = useState<boolean>(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const { userAccount } = useSelector((state: StoreType) => state.user);
    const [statsData, setStatsData] = useState<StatsDataType>();
    const [statsDataSingle, setStatsDataSingle] = useState<StatsDataType>();
    const [transactions, setTransactions] =
        useState<PaginationType<TransactionType> | null>();
    const [subscriptions, setSubscriptions] =
        useState<PaginationType<SubscriptionType>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [transactionStats, setTransactionStats] =
        useState<DashUserTransactionStats>({
            total: 0,
            succeeded: 0,
            failed: 0,
        });

    const [paginate, setPaginate] = useState({
        pageNumber: 1,
        pageSize: 10,
    });

    const [showTable, setShowTable] = useState<number>(1);

    const [retryTransactionLoading, setRetryTransactionLoading] = useState<{
        id: string | null;
        loading: boolean;
    }>({
        id: null,
        loading: false,
    });

    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleGetStats = async () => {
        try {
            setLoading(true);
            const data = await front_desk_get_user_stats(user_id);
            setStatsData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleGetTransactionsList = async (
        pageNumber: number,
        pageSize: number
    ) => {
        try {
            setIsLoading(true);
            const data: PaginationType<TransactionType> =
                await front_desk_get_user_transactions(
                    user_id,
                    pageNumber,
                    pageSize
                );
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const AddTransactionOnSubmit = async (payload: AddTransactionPayload) => {
        if(payload.type === '' || payload.amount.toString() === '' || payload.payment_method === '') {
            toast.error('Please Fill All Fields');
        } else if(payload.payment_method === 'momo') {
            if((phone.startsWith('2507') && phone.length === 12)) {
                try {
                    setLoadingCreate(true);
                    // @ts-ignore
                    payload.amount = parseInt(payload.amount, 10);
                    payload.received_by_id = userAccount.id;
                    payload.user_id = user_id;
                    payload.phoneNumber = phone;
                    await add_transaction(payload);
                    onClose();
                    toast.success('Transaction Added successfully');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setLoadingCreate(false);
                }
            } else {
                toast.error('Phone Must start with 2507 and be 12 characters long.');
            }
        } else {
            try {
                setLoadingCreate(true);
                payload.received_by_id = userAccount.id;
                payload.user_id = user_id;
                payload.card_id = subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id ?? '';
                await add_transaction(payload);
                onClose();
                toast.success('Transaction Added successfully');
            } catch (error: any) {
                if (
                    error instanceof TypeError &&
                    error.message.includes('Cannot read properties of null (reading \'id\')')
                ) {
                    toast.error('Card not found.');
                } else {
                toast.error(error.message);

                }
            } finally {
                setLoadingCreate(false);
            }
        }
    };

    const pauseSub = async (payload: canOrPauPayload) => {
        console.log(payload);
        if(payload.card_id === '' || payload.paymentMode === '') {
            toast.error('Please Fill All Fields');
        } else if(payload.paymentMode === 'momo') {
            if((phone.startsWith('2507') && phone.length === 12)) {
                try {
                    setLoadingCreate(true);
                    payload.userId = user_id;
                    payload.phoneNumber = phone;
                    payload.subscriptionId = currentSub;
                    await pauseSubscription(payload);
                    openPause.onClose();
                    toast.success('Pause Subscription Initiated.');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setLoadingCreate(false);
                }
            } else {
                toast.error('Phone Must start with 2507 and be 12 characters long.');
            }
        } else {
            if(subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id == undefined) {
                return toast.error('Card Not Found.');
            }
            try {

                setLoadingCreate(true);
                payload.userId = user_id;
                payload.subscriptionId = currentSub;
                payload.card_id = subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id ?? '';
                await pauseSubscription(payload);
                openPause.onClose();
                toast.success('Pause Subscription Initiated.');
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setLoadingCreate(false);
            }
        }
    };

    const cancelSub = async (payload: canOrPauPayload) => {
        if(payload.card_id === '' || payload.paymentMode === '') {
            toast.error('Please Fill All Fields');
        } else if(payload.paymentMode === 'momo') {
            if((phone.startsWith('2507') && phone.length === 12)) {
                try {
                    setLoadingCreate(true);
                    payload.subscriptionId = currentSub;
                    payload.userId = user_id;
                    payload.phoneNumber = phone;
                    await cancelSubscription(payload);
                    openCancel.onClose();
                    toast.success('Cancel Subscription Initiated.');
                } catch (error: any) {
                    toast.error(error.message);
                } finally {
                    setLoadingCreate(false);
                }
            } else {
                toast.error('Phone Must start with 2507 and be 12 characters long.');
            }
        } else {
            try {
                setLoadingCreate(true);
                payload.subscriptionId = currentSub;
                payload.userId = user_id;
                payload.card_id = subscriptions?.list.find(obj => obj.id === payload.subscriptionId)?.card.id ?? '';
                await cancelSubscription(payload);
                openCancel.onClose();
                toast.success('Cancel Subscription Initiated.');
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setLoadingCreate(false);
            }
        }
    };

    const retryTransaction = async (id: string) => {
        try {
            setRetryTransactionLoading({
                id,
                loading: false,
            });
            await retry_transaction({
                id,
                phoneNumber: '',
                paymentMode: 'card',
            });
            toast.success('Transaction successfully retried');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setRetryTransactionLoading({
                id: '',
                loading: false,
            });
        }
    };

    useEffect(() => {
        handleGetStats();
    }, []);

    useEffect(() => {
        handleGetTransactionsList(paginate.pageNumber, paginate.pageSize);
    }, [paginate]);

    useEffect(() => {
        getCronJob(paginate.pageNumber, paginate.pageSize,);
    }, [paginate]);

    const statsCards: {
        title: string;
        amount: number | undefined;
    }[] = [
        {
            title: 'Subscription  Amount ',
            amount: statsData?.total,
        },
        {
            title: 'Paid Amount ',
            amount: statsData?.paid,
        },
        {
            title: 'Balance Amount',
            amount: statsData?.balance,
        },
    ];

    const statsCardsSingle: {
        title: string;
        amount: number | undefined;
    }[] = [
        {
            title: 'Subscription  Amount ',
            amount: statsDataSingle?.total,
        },
        {
            title: 'Paid Amount ',
            amount: statsDataSingle?.paid,
        },
        {
            title: 'UnPaid Amount ',
            amount: statsDataSingle?.unpaid,
        },
        {
            title: 'Balance Amount',
            amount: statsDataSingle?.balance,
        },
    ];

    const cron_columns: TableColumn<CronjobType>[] = [
        {
            title: 'User',
            cell: row => row.user.firstname,
        },
        {
            title: 'Email',
            cell: row => row.user.email,
        },
        {
            title: 'Scheduled On',
            cell: row => format.humanDate(row.scheduled_on),
        },
        {
            title: 'Completed',
            cell: row => (
                <p
                    className={
                        row.completed ? 'text-green-600' : 'text-yellow-600'
                    }
                >
                    {row.completed ? 'COMPLETED' : 'PENDING'}
                </p>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-2 justify-center items-center">
                    {!row.completed && <PlayIcon
                        onClick={() => {
                            setSelectedCron(row);
                            openReRunJob.onOpen();
                        }}
                        className="w-5"
                    />}
                    <TrashIcon
                        onClick={() => {
                            setSelectedCron(row);
                            openDeleteJob.onOpen();
                        }}
                        className="w-5"
                    />
                    {!row.completed && <PencilAltIcon
                        onClick={() => {
                            setSelectedCron(row);
                            openUpdateJob.onOpen();
                        }}
                        className="w-5"
                    />}
                </div>
            ),
        },
    ];

    const transactions_columns: TableColumn<TransactionType>[] = [
        {
            title: 'Date Time',
            cell: row =>
                format.humanDateTime(
                     row.updated_at
                ),
        },
        {
            title: 'Member',
            cell: row => (
                <>
                    <p className="capitalize">
                        {row.user &&
                            `${row.user.firstname} ${row.user.lastname}`}
                    </p>
                    <p>{row.user?.email}</p>
                </>
            ),
        },
        {
            title: 'Package',
            cell: row => (
                <p className="capitalize">{`${row.subscription.package.name}`}</p>
            ),
        },
        {
            title: 'Amount',
            cell: row => format.currenyWithAmount(row.amount),
        },
        {
            title: 'Cron Date',
            cell: row => (<>
                 <div className="">{row.cron_date ? format.humanDateTime(row.cron_date) : (
                     <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                 )}</div>
            </>),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() !== 'success' && (
                        <span
                            className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white"
                            onClick={() => retryTransaction(row.id)}
                        >
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
        {
            title: 'Message',
            cell: row =>
                row.data
                    ? row.data.response.gatewayCode
                    : row.subscription.package.name,
        },
        {
            title: 'Payment Mode',
            cell: row => row.payment_mode.toUpperCase(),
        },
        {
            title: 'ACTION',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' &&
                        row.status.toLowerCase() === 'success' && (
                            <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                        )}
                    {row.status.toLowerCase() !== 'success' && (
                        <button
                            onClick={() => {
                                retryActions.onOpen();
                                setSelected({
                                    id: row.id,
                                });
                            }}
                            className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white"
                        >
                            Retry
                        </button>
                    )}
                </>
            ),
        },
    ];

    const { search: query } = useLocation();

    const handleGetSubscriptions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<SubscriptionType> =
                await get_subscriptions_by_id(user_id, `pageNumber=${paginate.pageNumber}&pageSize=${paginate.pageSize}`);
            setSubscriptions(data);
        } catch (error: any) {
            toast.error(error.response.message);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const [cronjobs, setCronjobs] =
        useState<PaginationType<CronjobType> | null>();

    const getCronJob = async (pageNumber: number,pageSize: number) => {
        try {
            const crons = await get_cronjobs_user(user_id, pageNumber, pageSize);
            setCronjobs(crons);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreate = async (e: any) => {
        try {
            setIsLoadingCreate(true);
            await create_cronjob(user_id, e).then(() => {
                toast.success('cronjob Created');
                openCreateJob.onClose();
            });
        } catch (error) {
            toast.error(`cronjob not created: ${error}`);
        } finally {
            setIsLoadingCreate(false);
        }
    };

    const handleUpdate = async (e: any) => {
        try {
            setIsLoadingUpdate(true);
            await update_cronjob(user_id, selectedCron, e).then(() => {
                toast.success('cronjob updated');
                openCreateJob.onClose();
            });
        } catch (error) {
            toast.error(`cronjob not updated: ${error}`);
        } finally {
            setIsLoadingUpdate(false);
        }
    };

    const handleDelete = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await delete_cronjob(selectedCron ? selectedCron.id : '').then(
                () => {
                    toast.success('cronjob deleted');
                    openDeleteJob.onClose();
                }
            );
        } catch (error) {
            toast.error(`cronjob not deleted: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    const handleReRun = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoadingDelete(true);
            await run_cronjob(selectedCron ? selectedCron.id : '').then(
                () => {
                    toast.success('cronjob run');
                    openReRunJob.onClose();
                }
            );
        } catch (error) {
            toast.error(`cronjob not run: ${error}`);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    const [currentSub, setCurrentSub] = useState<string>('');

    const subscription_columns: TableColumn<SubscriptionType>[] = [
        {
            title: 'Date Time',
            cell: row => format.humanDateTime(row?.updated_at),
        },
        {
            title: 'Email',
            cell: row => row?.user?.email ?? userAccount.email,
        },
        {
            title: 'Package',
            cell: row => (
                <>
                    <p>{row?.package?.name ?? 'N/A'}</p>
                </>
            ),
        },
        {
            title: 'Amount',
            cell: row => `${row?.package?.price ?? 'N/A'}`,
        },
        {
            title: 'Card Number',
            cell: row => (
                <p>
                    {row?.card?.data?.sourceOfFunds?.provided?.card?.number ??
                        'N/A'}
                </p>
            ),
        },
        {
            title: 'Start Date',
            cell: row => format.humanDate(row.created_at),
        },
        {
            title: 'End Date',
            cell: row => format.humanDate(row.expires_at),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    <span
                        className={`block rounded-full ${
                            row?.status.includes('active')
                                ? 'bg-green-600'
                                : 'bg-red-500'
                        } py-0.5 text-center text-[12px] font-semibold uppercase text-white`}
                    >
                        {row?.status}
                    </span>
                </>
            ),
        },
        {
            title: 'Actions',
            cell: row => (
                <div className="flex gap-2">
                    {subLoadin && currentSub === row?.id ? (
                        <Spinner size="sm" />
                    ) : (
                        <>
                            <EyeIcon
                                className="w-8 cursor-pointer"
                                onClick={() => {
                                    setCurrentSub(row?.id);
                                    getSubBalance(row.id);
                                    openView.onOpen();
                                }}
                            />
                            <PauseIcon
                                className="w-8 cursor-pointer"
                                onClick={() => {
                                    setCurrentSub(row?.id);
                                    openPause.onOpen();
                                }}
                            />

                            <img
                                src={ICONS.CancelIcon}
                                alt={''}
                                className="w-7 cursor-pointer"
                                onClick={() => {
                                    setCurrentSub(row?.id);
                                    openCancel.onOpen();
                                }}
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    const [packages, setPackages] = useState([]);

    const getPackages = async () => {
        try {
            const data = await get_packages();
            setPackages(data.list);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const formattedPackages: OptionType[] = packages?.map(
        (pack: PackageType) => {
            return {
                value: pack.id.toString(),
                label: `${pack.name}`,
            };
        }
    );

    const formattedSubscriptions: OptionType[] | undefined =
        subscriptions?.list?.map((pack: SubscriptionType) => {
            return {
                value: pack.id.toString(),
                label: `${pack.package.name} // ${
                    pack.package.price
                } // ${new Date(pack.created_at).toDateString()}`,
            };
        });

    useEffect(() => {
        handleGetSubscriptions();
    }, [paginate]);

    useEffect(() => {
        getPackages();
    }, [onOpen]);

    const navigate = useNavigate();

    const [subLoadin, setSubLoading] = useState<boolean>(false);

    const [selected, setSelected] = useState({ id: '' });
    const [selectedCron, setSelectedCron] = useState<CronjobType>();

    const [phone, setPhone] = useState('2507');

    const openPause = useDisclosure();
    const openView = useDisclosure();
    const openCancel = useDisclosure();

    const [lsb, setLsb] = useState(false);
    const getSubBalance = async (id: string) => {
        try {
            setLsb(true);
            console.log(currentSub);
            const data = await get_subscriptions_balance(id);
            setStatsDataSingle(data);
            console.log(data);
        } catch (r) {
            toast.error('Failed to get balance');
        } finally {
            setLsb(false);
        }
    };

    const [count, setCount] = useState(10);
    const [notifications, setNotifications] = useState<null | NotificationResponse>(null);
    const { getNotifications } = useGetNotificationByAdmin({ id: user_id, pageSize: count });

    useEffect(() => {
        getNotifications().then((data) => setNotifications(data));
    }, [count]);

    return (

        <div className="flex flex-col gap-2">
            <div className="grid grid-cols-3 gap-4">
                {statsCards.map(({ title, amount }, index) => (
                    <DashCard
                        label={title}
                        key={index}
                        background={'bg-blue-600/70'}
                        count={format.currenyWithAmount(amount ?? 0)}
                        icon={<CreditCardIcon className="icon-xl" />}
                        isLoading={loading}
                    />
                ))}
            </div>
            <div className="grid grid-cols-3 gap-4">
                <DashCard
                    label="Total Transactions"
                    background={'bg-light/10'}
                    count={statsData?.totalTransaction ?? 0}
                    icon={<CreditCardIcon className="icon-xl" />}
                    isLoading={loading}
                />

                <DashCard
                    label="Succeeded Transactions"
                    background={'bg-green-600/70'}
                    count={statsData?.success ?? 0}
                    icon={<CheckCircleIcon className="icon-xl" />}
                    isLoading={loading}
                />

                <DashCard
                    label="Failed Transactions"
                    background={'bg-red-600/70'}
                    count={statsData?.failure ?? 0}
                    icon={<ExclamationIcon className="icon-xl" />}
                    isLoading={loading}
                />
            </div>

            <div className="float-right  flex  gap-4 whitespace-nowrap px-6 py-4">
                <div className="flex gap-3">
                    <Button
                        className={`btn btn-danger btn-md ${
                            showTable === 1 ? 'bg-danger' : 'bg-transparent'
                        }`}
                        variant="btn-danger"
                        onClick={() => {
                            setShowTable(1);
                            setPaginate({
                                pageNumber: 1,
                                pageSize: 10
                            });
                        }}
                    >
                        Transactions
                    </Button>
                    <Button
                        className={`btn btn-danger btn-md ${
                            showTable === 2 ? 'bg-danger' : 'bg-transparent'
                        }`}
                        variant="btn-danger"
                        onClick={() => {
                            setShowTable(2);
                            setPaginate({
                                pageNumber: 1,
                                pageSize: 10
                            });
                        }}
                    >
                        Subscriptions
                    </Button>
                    <Button
                        className="btn btn-danger btn-md bg-transparent hover:bg-danger"
                        variant="btn-danger"
                        onClick={onOpen}
                    >
                        Add Transaction
                    </Button>
                    {userAccount.role === 'admin' ? (
                        <Button
                            className={`btn btn-danger btn-md ${
                                showTable === 3 ? 'bg-danger' : 'bg-transparent'
                            }`}
                            variant="btn-danger"
                            onClick={() => {
                                setShowTable(3);
                                setPaginate({
                                    pageNumber: 1,
                                    pageSize: 10
                                });
                            }}
                        >
                            Cron Jobs
                        </Button>
                    ) : null}

                    {userAccount.role === 'admin' ? (
                        <Button
                            className={`btn btn-danger btn-md ${
                                showTable === 4 ? 'bg-danger' : 'bg-transparent'
                            }`}
                            variant="btn-danger"
                            onClick={() => {
                                setShowTable(4);
                                setPaginate({
                                    pageNumber: 1,
                                    pageSize: 10
                                });
                            }}
                        >
                            Notifications
                        </Button>
                    ) : null}
                </div>
            </div>

            {showTable === 1 && userAccount.role === 'admin' && (
                <div className="flex justify-end">
                    <Button
                        variant="btn-secondary"
                        className="px-3"
                        title="Export to excel"
                        onClick={() =>
                            handleExportExcel(
                                transactions?.list.map(item => {
                                    return {
                                        Member: `${item.user?.firstname} ${item.user?.lastname}`,
                                        DateTime: item.created_at,
                                        Telephone: item.user?.phone,
                                        Package: item.subscription.package.name,
                                        PaymentMethod:
                                            item.payment_mode.toString(),
                                        Amount: item.amount,
                                        Status: item.status,
                                    };
                                }) ?? []
                            )
                        }
                    >
                        <DocumentReportIcon className="icon-md" />
                        Export Excel
                    </Button>
                </div>
            )}

            {showTable === 1 && (
                <DataTableModal
                    columns={transactions_columns}
                    data={transactions?.list ?? []}
                    isLoading={isLoading}
                    total={transactions?.total ?? 0}
                    nextPage={transactions?.nextPage ?? 0}
                    lastPage={transactions?.lastPage ?? 0}
                    currentPage={transactions?.currentPage ?? 0}
                    previousPage={transactions?.previousPage ?? 0}
                    paginate={paginate}
                    setPaginate={setPaginate}
                />
            )}

            {showTable === 2 && (
                <DataTableModal
                    paginate={paginate}
                    setPaginate={setPaginate}
                    columns={subscription_columns}
                    data={subscriptions?.list ?? []}
                    isLoading={isLoading}
                    total={subscriptions?.total ?? 0}
                    nextPage={subscriptions?.nextPage ?? 0}
                    lastPage={subscriptions?.lastPage ?? 0}
                    currentPage={subscriptions?.currentPage ?? 0}
                    previousPage={subscriptions?.previousPage ?? 0}
                />
            )}

            {showTable === 3 && userAccount.role === 'admin' ? (
                <>
                    <div className="">
                        <div className="flex items-center justify-end p-2">
                            <button
                                onClick={() => openCreateJob.onOpen()}
                                className="rounded-md bg-red-600 px-2 py-1 text-light"
                            >
                                Create Job
                            </button>
                        </div>
                        <Modal
                            title="Create cronjob"
                            size="md:w-1/2 max-w-[500px] w-11/12"
                            isOpen={openCreateJob.isOpen}
                            onClose={openCreateJob.onClose}
                        >
                            <Form<CronjobPayload, typeof schema>
                                schema={schema}
                                onSubmit={handleCreate}
                            >
                                {({ register, formState }) => (
                                    <div className="min-w-80">
                                        <div className="flex flex-col gap-4">
                                            <SelectField
                                                label="Select Package"
                                                options={formattedPackages}
                                                isLoading={isLoadingCreate}
                                                error={
                                                    formState.errors.packageId
                                                }
                                                registration={register(
                                                    'packageId'
                                                )}
                                            />
                                            <InputField
                                                type="date"
                                                registration={register(
                                                    'scheduled_on'
                                                )}
                                                className="h-12"
                                                error={
                                                    formState.errors
                                                        .scheduled_on
                                                }
                                                label="Scheduled On"
                                                isLoading={isLoadingCreate}
                                            />
                                            <InputField
                                                type="number"
                                                registration={register(
                                                    'numberOfMonths'
                                                )}
                                                className="h-12"
                                                error={
                                                    formState.errors
                                                        .numberOfMonths
                                                }
                                                label="Number of Months"
                                                isLoading={isLoadingCreate}
                                            />
                                        </div>

                                        <div className="flex-end flex gap-2">
                                            <Button
                                                type="submit"
                                                variant="btn-danger"
                                                className="mt-6 w-full"
                                                isLoading={isLoadingCreate}
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </Modal>
                    </div>
                    <DataTableModal
                        paginate={paginate}
                        setPaginate={setPaginate}
                        columns={cron_columns}
                        data={cronjobs?.list ?? []}
                        isLoading={isLoading}
                        total={cronjobs?.total ?? 0}
                        lastPage={cronjobs?.lastPage ?? 0}
                        nextPage={cronjobs?.nextPage ?? 0}
                        currentPage={cronjobs?.currentPage ?? 0}
                        previousPage={cronjobs?.previousPage ?? 0}
                    />
                </>
            ) : null}

            {showTable === 4 && (
                <div className={'w-full bg-slate-700 p-3 max-h-[500px] overflow-y-scroll scrollbar-hidden'}>
                    {notifications?.list.map((not, index) => <NotificationBox key={index} item={not} />)}

                    {notifications && notifications?.list.length > 10 && <div className="w-full justify-center items-center flex">
                        <button onClick={() => {
                            console.log('he');
                            setCount(prev => prev + 10);
                        }} className={'text-light underline'}>Load More
                        </button>
                    </div>}

                    {notifications && notifications.list.length === 0 && <div className={'w-full justify-center items-center flex'}>
                        <p className={'text-light'}>No Notifications.</p>
                    </div>}
                </div>
            )}

            <Modal
                title="Add Transaction"
                size="w-full md:w-1/2"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Form<AddTransactionPayload> onSubmit={AddTransactionOnSubmit}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="flex flex-col gap-4">
                                <SelectField
                                    placeholder="Type"
                                    options={
                                    [
                                        {
                                            value: 'recording',
                                            label: 'Record'
                                        },
                                        {
                                            value: 'initiate',
                                            label: 'Initiate'
                                        }
                                    ]
                                    }
                                    error={formState.errors.type}
                                    registration={register('type')}
                                    className="h-12"
                                />
                                <InputField
                                    type="number"
                                    placeholder="Amount"
                                    className="h-12"
                                    error={formState.errors.amount}
                                    registration={register('amount')}
                                />

                                <SelectField
                                    placeholder="Subscription"
                                    options={
                                        formattedSubscriptions
                                            ? formattedSubscriptions
                                            : []
                                    }
                                    error={formState.errors.subscriptionId}
                                    registration={register('subscriptionId')}
                                    className="h-12"
                                />

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                        { value: 'pos', label: 'POS' },
                                    ]}
                                    error={formState.errors.payment_method}
                                    registration={register('payment_method')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />

                                {watch('payment_method') === 'momo' &&
                                    <>
                                    <div
                                        className={' my-2  flex-wrap flex text-light items-center justify-center gap-2 border-y border-slate-700 py-2'}
                                    >
                                        <p>Enter MoMo Phone number</p>
                                        <div className="flex flex-col">
                                            <div className="flex flex-wrap justify-center gap-2">
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    maxLength={12}
                                                    value={phone}
                                                    onChange={e => setPhone(e.target.value)}
                                                    className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }

                            </div>

                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>

            <div className="">
                <Modal
                    title="Retry"
                    size="text-light md:w-1/2 max-w-[500px] w-11/12 mt-20"
                    onClose={retryActions.onClose}
                    isOpen={retryActions.isOpen}
                >
                    <RetryComponent id={selected.id} />
                </Modal>
            </div>

            <Modal
                title="Delete CronJob"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openDeleteJob.isOpen}
                onClose={openDeleteJob.onClose}
            >
                <form onSubmit={handleDelete}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to delete{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selectedCron?.user.firstname}
                            </span>{' '}
                            cronjob?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                title="Re-Run CronJob"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openReRunJob.isOpen}
                onClose={openReRunJob.onClose}
            >
                <form onSubmit={handleReRun}>
                    <div className="min-w-80">
                        <div className=" text-sm text-light md:text-lg">
                            Are you sure you want to Re-Run{' '}
                            <span className="mx-1 font-bold text-red-600">
                                {selectedCron?.user.firstname}
                            </span>{' '}
                            cronjob?
                        </div>

                        <div className="flex-end flex gap-2">
                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={isLoadingReRun}
                            >
                                Re-Run
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                title="Update cronjob"
                size="md:w-1/2 max-w-[500px] w-11/12"
                isOpen={openUpdateJob.isOpen}
                onClose={openUpdateJob.onClose}
            >
                <Form<CronjobPayload, typeof schema>
                    schema={schema}
                    onSubmit={handleUpdate}
                >
                    {({ register, formState }) => (
                        <div className="min-w-80">
                            <div className="flex flex-col gap-4">
                                <SelectField
                                    label="Select Package"
                                    options={formattedPackages}
                                    isLoading={isLoadingUpdate}
                                    error={formState.errors.packageId}
                                    registration={register('packageId')}
                                />
                                <InputField
                                    type="date"
                                    placeholder={selectedCron?.scheduled_on}
                                    registration={register('scheduled_on')}
                                    className="h-12"
                                    error={formState.errors.scheduled_on}
                                    label="Scheduled On"
                                    isLoading={isLoadingUpdate}
                                />
                                <InputField
                                    type="number"
                                    registration={register('numberOfMonths')}
                                    className="h-12"
                                    error={formState.errors.numberOfMonths}
                                    label="Number of Months"
                                    isLoading={isLoadingUpdate}
                                />
                            </div>

                            <div className="flex-end flex gap-2">
                                <Button
                                    type="submit"
                                    variant="btn-danger"
                                    className="mt-6 w-full"
                                    isLoading={loadingCreate}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>


            <Modal
                title="Cancel Subscription"
                size="w-full md:w-1/2"
                isOpen={openCancel.isOpen}
                onClose={openCancel.onClose}
            >
                <Form<canOrPauPayload> onSubmit={cancelSub}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="flex flex-col gap-4">

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                        { value: 'pos', label: 'POS' },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />

                                {watch('paymentMode') === 'momo' &&
                                    <>
                                        <div
                                            className={' my-2  flex-wrap flex text-light items-center justify-center gap-2 border-y border-slate-700 py-2'}
                                        >
                                            <p>Enter MoMo Phone number</p>
                                            <div className="flex flex-col">
                                                <div className="flex flex-wrap justify-center gap-2">
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        maxLength={12}
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}
                                                        className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Cancel Subscription
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                title="Pause Subscription"
                size="w-full md:w-1/2"
                isOpen={openPause.isOpen}
                onClose={openPause.onClose}
            >
                <Form<canOrPauPayload> onSubmit={pauseSub}>
                    {({ register, formState, watch }) => (
                        <>
                            <div className="flex flex-col gap-4">

                                <SelectField
                                    placeholder="Payment Mode"
                                    options={[
                                        { value: 'momo', label: 'MOMO' },
                                        { value: 'card', label: 'CARD' },
                                        { value: 'pos', label: 'POS' },
                                    ]}
                                    error={formState.errors.paymentMode}
                                    registration={register('paymentMode')}
                                    className="h-12"
                                    isLoading={loadingCreate}
                                />

                                {watch('paymentMode') == 'momo' &&
                                    <>
                                        <div
                                            className={' my-2  flex-wrap flex text-light items-center justify-center gap-2 border-y border-slate-700 py-2'}
                                        >
                                            <p>Enter MoMo Phone number</p>
                                            <div className="flex flex-col">
                                                <div className="flex flex-wrap justify-center gap-2">
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        maxLength={12}
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}
                                                        className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            <Button
                                type="submit"
                                variant="btn-danger"
                                className="mt-6 w-full"
                                isLoading={loadingCreate}
                            >
                                Pause Subscription
                            </Button>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                title="Subscription Details"
                size="w-11/12 max-w-[500px]"
                isOpen={openView.isOpen}
                onClose={openView.onClose}
            >
                {lsb ? <p className={'text-light text-center w-full'}>Please wait...</p> :
                    <div className="grid grid-cols-3 md:grid-cols-2 gap-4">
                        {statsCardsSingle.map(({ title, amount }, index) => (
                            <DashCard
                                label={title}
                                key={index}
                                background={'bg-blue-600/70'}
                                count={format.currenyWithAmount(amount ?? 0)}
                                icon={<CreditCardIcon className="icon-xl" />}
                                isLoading={loading}
                            />
                        ))}
                    </div>}
            </Modal>
        </div>
    );
};
