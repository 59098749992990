import { UserType } from '~/app/types';
import { Modal } from '~/components/elements';
import { DashFrontDeskDisplayUserStats } from './DashUserStats';

type DashUpdateCardProps = {
    isOpen: boolean;
    onClose: () => void;
    selectedUser: UserType | undefined;
};

export const FrontDeskDisplayUser = (props: DashUpdateCardProps) => {
    const { isOpen, onClose, selectedUser } = props;

    return (
        <Modal
            title={`${selectedUser?.firstname} ${selectedUser?.lastname}`}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div>
                <DashFrontDeskDisplayUserStats
                    user_id={selectedUser?.id ?? ''}
                />
            </div>
        </Modal>
    );
};
