import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
    ChevronDownIcon,
    LogoutIcon,
    RefreshIcon,
} from '@heroicons/react/outline';
import { storage } from '~/core/utils';

import UserImage from '~/assets/images/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '~/app/types/redux';
import { removeUser } from '~/core/redux/slices/user/userAccountSlice';
import { removeCard } from '~/core/redux/slices/card/cardSlice';

export const Profile = () => {
    const { userAccount } = useSelector((state: StoreType) => state.user);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleLogout = () => {
        storage.removeToken();

        dispatch(removeUser());
        dispatch(removeCard());

        return navigate('/');
    };

    return (
        <Menu as="div" className="relative inline-block">
            <Menu.Button className="flex items-center gap-3">
                <div>
                    <img
                        alt="Honore"
                        src={UserImage}
                        className="h-12 w-12 rounded-full"
                    />
                </div>

                <div className="hidden text-left sm:block">
                    <p className="font-semibold capitalize leading-5 text-dark">{`${userAccount?.firstname} ${userAccount?.lastname}`}</p>
                    <p className="leading-5  text-gray">{`${userAccount?.email}`}</p>
                </div>

                <div className="hidden sm:block">
                    <ChevronDownIcon className="icon-md text-gray" />
                </div>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="divide-gray-100 absolute right-0 mt-5 flex w-48 origin-top-left flex-col rounded-md bg-white ring-1 ring-black ring-opacity-5">
                    <Menu.Item
                        as="button"
                        className="hover flex w-full gap-1 rounded-md bg-slate-50 p-3 text-dark hover:bg-slate-200"
                        onClick={handleLogout}
                    >
                        <LogoutIcon className="icon-md" />
                        <span className="text-lg font-semibold">Log Out</span>
                    </Menu.Item>
                    <Link to={'/change-password'}>
                        <Menu.Item
                            as="button"
                            className="hover flex w-full gap-1 rounded-md bg-slate-50 p-3 text-dark hover:bg-slate-200"
                        >
                            <RefreshIcon className="icon-md" />
                            <span className="text-lg font-semibold">
                                Change Password
                            </span>
                        </Menu.Item>
                    </Link>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
