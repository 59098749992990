import { CardPayload, CardType } from '~/app/types';
import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { paginationString } from '~/core/utils/pagination.str';
import { PRIVATE_API } from '../axios';

export const create_card = async (
    cardPayload: CardPayload
): Promise<CardType | any> => {
    try {
        const request = await PRIVATE_API.post('/cards', cardPayload);
        return request.data as CardType;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_cards = async (
    pageNumber?: number,
    pageSize?: number
): Promise<CardType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/subscription?${paginationString(pageNumber, pageSize)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_card = async (
    pageNumber?: number,
    pageSize?: number
): Promise<CardType[] | any> => {
    try {
        const request = await PRIVATE_API.get(
            `/cards?${paginationString(pageNumber, pageSize)}`
        );
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const update_card = async (
    cardPayload: CardPayload,
    cardId: string
): Promise<CardType | any> => {
    try {
        const request = await PRIVATE_API.patch(
            `/cards/${cardId}/update`,
            cardPayload
        );
        return request.data as CardType;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_card = async (cardId: string): Promise<CardType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/cards/${cardId}`);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
