import React from 'react';

export default function TermsAndConditions() {
    return (
        <div className="text-sm">
            <h1>TERMS OF SERVICE</h1>
            <br />
            <h2 className="underline">OVERVIEW</h2>
            <br />

            <p>
                This website is operated by WAKA. Throughout the site, the terms
                “we”, “us” and “our” refer to WAKA. WAKA offers this website,
                including all information, tools and services available from
                this site to you, the user, conditioned upon your acceptance of
                all terms, conditions, policies and notices stated here.
            </p>
            <br />

            <p>
                By visiting our site and/ or purchasing something from us, you
                engage in our “Service” and agree to be bound by the following
                terms and conditions (“Terms of Service”, “Terms”), including
                those additional terms and conditions and policies referenced
                herein and/or available by hyperlink. These Terms of Service
                apply to all users of the site, including without limitation
                users who are browsers, vendors, customers, merchants, and/ or
                contributors of content.
            </p>
            <br />

            <p>
                Please read these Terms of Service carefully before accessing or
                using our website. By accessing or using any part of the site,
                you agree to be bound by these Terms of Service. If you do not
                agree to all the terms and conditions of this agreement, then
                you may not access the website or use any services. If these
                Terms of Service are considered an offer, acceptance is
                expressly limited to these Terms of Service
            </p>
            <br />

            <p>
                Any new features or tools which are added to the current store
                shall also be subject to the Terms of Service. You can review
                the most current version of the Terms of Service at any time on
                this page. We reserve the right to update, change or replace any
                part of these Terms of Service by posting updates and/or changes
                to our website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes
                acceptance of those changes.
            </p>
            <br />

            <p>
                Our store is hosted on Shopify Inc. They provide us with the
                online e-commerce platform that allows us to sell our products
                and services to you.
            </p>
            <br />

            <h2 className="mt-3 mb-2 underline">
                SECTION 1 - ONLINE STORE TERMS
            </h2>
            <br />
            <p>
                On selecting the pay monthly membership options, members will be
                required to pay the associated registration fee (if applicable)
                and the 1st-month membership fee in advance. Members are
                required to agree to these terms and conditions.
            </p>
            <br />

            <p>
                This is a recurring monthly charge. Membership Fees will be
                debited from the member's account, using their given bank
                debit/credit card, on or around their join date each month, for
                the minimum 1-month term from the term start date. Members who
                have chosen the 12 MONTH FIXED TERM payment option may{' '}
                <b className="underline">
                    NOT be cancelled until after the 12th month
                </b>
                , after that time they will have the option to cancel at any
                time. Any joining fee /initial payment is due from you to WAKA,
                is payable immediately and is not refundable. The membership may
                be frozen because of personal illness/injury by evidence of a
                doctor's note or may be transferred to another person provided
                that the direct debits shall continue to be taken from the
                original member's account.
            </p>
            <br />

            <p>
                Members are required to keep their account in funds to meet
                these payments. In the event of the failure to pay the monthly
                membership fee, missed payments will be re-submitted for payment
                by WAKA. WAKA reserves the right to cancel membership if payment
                is not received. Access to the facility will be denied until
                said payment is received and if payment is not received, and
                cancellation occurs, the registration fee becomes applicable
                again on re-joining.
            </p>
            <br />

            <p>
                These arrears of fees must be paid by Credit or Debit card IN
                STORE AT waka. Failure to meet direct debit fee - In the event
                of the failure to pay the monthly direct debit fee, you will
                first be notified that your membership has been temporarily
                stopped until the balance is paid
            </p>
            <br />

            <p>
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your place of residence, or
                that you are the age of majority in your state or province of
                residence and you have given us your consent to allow any of
                your minor dependents to use this site.
            </p>
            <br />

            <p>
                You may not use our products for any illegal or unauthorized
                purpose nor may you, in the use of the Service, violate any laws
                in your jurisdiction (including but not limited to copyright
                laws).
            </p>
            <br />

            <p>
                You must not transmit any worms or viruses or any code of a
                destructive nature.
            </p>
            <br />

            <p>
                A breach or violation of any of the Terms will result in an
                immediate termination of your Services.
            </p>
            <br />

            <h2 className="underline">SECTION 2 - GENERAL CONDITIONS</h2>
            <br />

            <p>
                We reserve the right to refuse service to anyone for any reason
                at any time.
            </p>
            <br />

            <p>
                You understand that your content (not including credit card
                information), may be transferred unencrypted and involve (a)
                transmissions over various networks; and (b) changes to conform
                and adapt to technical requirements of connecting networks or
                devices. Credit card information is always encrypted during
                transfer over networks.
            </p>
            <br />

            <p>
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service or any contact on the website through
                which the service is provided, without express written
                permission by us.
            </p>
            <br />

            <p>
                The headings used in this agreement are included for convenience
                only and will not limit or otherwise affect these Terms.
            </p>
            <br />

            <h2 className="underline">
                SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
            </h2>
            <br />

            <p>
                We are not responsible if information made available on this
                site is not accurate, complete or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. Any reliance on the material on
                this site is at your own risk.
            </p>
            <br />

            <p>
                This site may contain certain historical information. Historical
                information, necessarily, is not current and is provided for
                your reference only. We reserve the right to modify the contents
                of this site at any time, but we have no obligation to update
                any information on our site. You agree that it is your
                responsibility to monitor changes to our site.
            </p>
            <br />

            <h2 className="underline">
                SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
            </h2>
            <br />

            <p>Prices for our products are subject to change without notice.</p>
            <br />

            <p>
                We reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice at any
                time.
            </p>
            <br />

            <p>
                We shall not be liable to you or to any third-party for any
                modification, price change, suspension or discontinuance of the
                Service.
            </p>
            <br />

            <h2 className="underline">
                SECTION 5 - PRODUCTS OR SERVICES (if applicable)
            </h2>
            <br />

            <p>
                Certain products or services may be available exclusively online
                through the website.
            </p>
            <br />

            <p>
                These products or services may have limited quantities and are
                subject to return or exchange only according to our Return
                Policy.
            </p>
            <br />

            <p>
                We have made every effort to display as accurately as possible
                the colors and images of our products that appear at the store.
                We cannot guarantee that your computer monitor's display of any
                color will be accurate.
            </p>
            <br />

            <p>
                We reserve the right, but are not obligated, to limit the sales
                of our products or Services to any person, geographic region or
                jurisdiction. We may exercise this right on a case-by-case
                basis. We reserve the right to limit the quantities of any
                products or services that we offer. All descriptions of products
                or product pricing are subject to change at any time without
                notice, at the sole discretion of us. We reserve the right to
                discontinue any product at any time. Any offer for any product
                or service made on this site is void where prohibited.
            </p>
            <br />

            <p>
                We do not warrant that the quality of any products, services,
                information, or other material purchased or obtained by you will
                meet your expectations, or that any errors in the Service will
                be corrected.
            </p>
            <br />

            <p>
                We reserve the right to refuse any order you place with us. We
                may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. These
                restrictions may include orders placed by or under the same
                customer account, the same credit card, and/or orders that use
                the same billing and/or shipping address. In the event that we
                make a change to or cancel an order, we may attempt to notify
                you by contacting the e-mail and/or billing address/phone number
                provided at the time the order was made. We reserve the right to
                limit or prohibit orders that, in our sole judgment, appear to
                be placed by dealers, resellers or distributors.
            </p>
            <br />

            <p>
                You agree to provide current, complete and accurate purchase and
                account information for all purchases made at our store. You
                agree to promptly update your account and other information,
                including your email address and credit card numbers and
                expiration dates, so that we can complete your transactions and
                contact you as needed.
            </p>
            <br />

            <p>For more detail, please review our Returns Policy.</p>
            <br />

            <h2 className="underline">SECTION 7 - OPTIONAL TOOLS</h2>
            <br />

            <p>
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input.
            </p>
            <br />

            <p>
                You acknowledge and agree that we provide access to such tools
                "as is" and "as available" without any warranties,
                representations or conditions of any kind and without any
                endorsement. We shall have no liability whatsoever arising from
                or relating to your use of optional third-party tools.
            </p>
            <br />

            <p>
                Any use by you of optional tools offered through the site is
                entirely at your own risk and discretion and you should ensure
                that you are familiar with and approve of the terms on which
                tools are provided by the relevant third-party provider(s).
            </p>
            <br />

            <p>
                We may also, in the future, offer new services and/or features
                through the website (including, the release of new tools and
                resources). Such new features and/or services shall also be
                subject to these Terms of Service.
            </p>
            <br />

            <h2 className="underline">SECTION 8 - THIRD-PARTY LINKS</h2>
            <br />

            <h2 className="underline">SECTION 8 - THIRD-PARTY LINKS</h2>
            <br />

            <p>
                Certain content, products and services available via our Service
                may include materials from third-parties.
            </p>
            <br />

            <p>
                Third-party links on this site may direct you to third-party
                websites that are not affiliated with us. We are not responsible
                for examining or evaluating the content or accuracy and we do
                not warrant and will not have any liability or responsibility
                for any third-party materials or websites, or for any other
                materials, products, or services of third-parties.
            </p>
            <br />

            <p>
                We are not liable for any harm or damages related to the
                purchase or use of goods, services, resources, content, or any
                other transactions made in connection with any third-party
                websites. Please review carefully the third-party's policies and
                practices and make sure you understand them before you engage in
                any transaction. Complaints, claims, concerns, or questions
                regarding third-party products should be directed to the
                third-party.
            </p>
            <br />

            <h2 className="underline">
                SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
            </h2>
            <br />

            <p>
                If, at our request, you send certain specific submissions (for
                example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other
                materials, whether online, by email, by postal mail, or
                otherwise (collectively, 'comments'), you agree that we may, at
                any time, without restriction, edit, copy, publish, distribute,
                translate and otherwise use in any medium any comments that you
                forward to us.
            </p>
            <br />

            <p>
                We are and shall be under no obligation (1) to maintain any
                comments in confidence; (2) to pay compensation for any
                comments; or (3) to respond to any comments.
            </p>
            <br />

            <p>
                We may, but have no obligation to, monitor, edit or remove
                content that we determine in our sole discretion are unlawful,
                offensive, threatening, libelous, defamatory, pornographic,
                obscene or otherwise objectionable or violates any party’s
                intellectual property or these Terms of Service.
            </p>
            <br />

            <p>
                You agree that your comments will not violate any right of any
                third-party, including copyright, trademark, privacy,
                personality or other personal or proprietary right. You further
                agree that your comments will not contain libelous or otherwise
                unlawful, abusive or obscene material, or contain any computer
                virus or other malware that could in any way affect the
                operation of the Service or any related website. You may not use
                a false e-mail address, pretend to be someone other than
                yourself, or otherwise mislead us or third-parties as to the
                origin of any comments. You are solely responsible for any
            </p>
            <br />

            <h2 className="underline">SECTION 10 - PERSONAL INFORMATION</h2>
            <br />
            <p>
                Your submission of personal information through the store is
                governed by our Privacy Policy. To view our Privacy Policy.
            </p>
            <br />

            <h2 className="underline">
                SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS
            </h2>
            <br />
            <p>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and
                availability.
            </p>
            <br />
            <p>
                We reserve the right to correct any errors, inaccuracies or
                omissions, and to change or update information or cancel orders
                if any information in the Service or on any related website is
                inaccurate at any time without prior notice (including after you
                have submitted your order).
            </p>
            <br />
            <p>
                We undertake no obligation to update, amend or clarify
                information in the Service or on any related website, including
                without limitation, pricing information, except as required by
                law. No specified update or refresh date applied in the Service
                or on any related website, should be taken to indicate that all
                information in the Service or on any related website has been
                modified or updated.
            </p>
            <br />

            <h2 className="underline">SECTION 12 - PROHIBITED USES</h2>
            <br />
            <p>
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                (a) for any unlawful purpose; (b) to solicit others to perform
                or participate in any unlawful acts; (c) to violate any
                international, federal, provincial or state regulations, rules,
                laws, or local ordinances; (d) to infringe upon or violate our
                intellectual property rights or the intellectual property rights
                of others;
            </p>
            <br />
            <p>
                (e) to harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability;
                (f) to submit false or misleading information; (g) to upload or
                transmit viruses or any other type of malicious code that will
                or may be used in any way that will affect
            </p>
            <br />
            <h2 className="underline">SECTION 12 - PROHIBITED USES</h2>
            <br />
            <p>
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                (a) for any unlawful purpose; (b) to solicit others to perform
                or participate in any unlawful acts; (c) to violate any
                international, federal, provincial or state regulations, rules,
                laws, or local ordinances; (d) to infringe upon or violate our
                intellectual property rights or the intellectual property rights
                of others;
            </p>
            <br />
            <p>
                (e) to harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability;
                (f) to submit false or misleading information; (g) to upload or
                transmit viruses or any other type of malicious code that will
                or may be used in any way that will affect the functionality or
                operation of the Service or of any related website, other
                websites, or the Internet;
            </p>
            <br />
            <p>
                (h) to collect or track the personal information of others; (i)
                to spam, phish, pharm, pretext, spider, crawl, or scrape; (j)
                for any obscene or immoral purpose; or (k) to interfere with or
                circumvent the security features of the Service or any related
                website, other websites, or the Internet. We reserve the right
                to terminate your use of the Service or any related website for
                violating any of the prohibited uses.
            </p>
            <br />

            <h2 className="underline">
                SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
            </h2>
            <br />
            <p>
                We do not guarantee, represent or warrant that your use of our
                service will be uninterrupted, timely, secure or error-free.
            </p>
            <br />
            <p>
                We do not warrant that the results that may be obtained from the
                use of the service will be accurate or reliable.
            </p>
            <br />
            <p>
                You agree that from time to time we may remove the service for
                indefinite periods of time or cancel the service at any time,
                without notice to you.
            </p>
            <br />
            <p>
                You expressly agree that your use of, or inability to use, the
                service is at your sole risk.
            </p>
            <br />
            <p>
                The service and all products and services delivered to you
                through the service are (except as expressly stated by us)
                provided 'as is' and 'as available' for your use, without any
                representation, warranties or conditions of any kind, either
                express or implied, including all implied warranties or
                conditions of merchantability, merchantable quality, fitness for
                a particular purpose, durability, title, and non-infringement.
            </p>
            <br />
            <p>
                In no case shall WAKA, our directors, officers, employees,
                affiliates, agents, contractors, interns, suppliers, service
                providers or licensors be liable for any injury, loss, claim, or
                any direct, indirect, incidental, punitive, special, or
                consequential damages of any kind, including, without limitation
                lost profits, lost revenue, lost savings, loss of data,
                replacement costs, or any similar damages, whether based in
                contract, tort (including negligence), strict liability or
                otherwise, arising from your use of any of the service or any
                products procured using the service, or for any other claim
                related in any way to your use of the service or any product,
                including, but not limited to, any errors or omissions in any
                content, or any loss or damage of any kind incurred as a result
                of the use of the service or any content (or product) posted,
                transmitted, or otherwise made available via the service, even
                if advised of their possibility. Because some states or
                jurisdictions do not allow the exclusion or the limitation of
                liability for consequential or
            </p>
            <br />
            <h2 className="underline">SECTION 14 - INDEMNIFICATION</h2>
            <br />
            <p>
                You agree to indemnify, defend and hold harmless WAKA and our
                parent, subsidiaries, affiliates, partners, officers, directors,
                agents, contractors, licensors, service providers,
                subcontractors, suppliers, interns and employees, harmless from
                any claim or demand, including reasonable attorneys’ fees, made
                by any third-party due to or arising out of your breach of these
                Terms of Service or the documents they incorporate by reference,
                or your violation of any law or the rights of a third-party.
            </p>
            <br />

            <h2 className="underline">SECTION 15 - SEVERABILITY</h2>
            <br />
            <p>
                In the event that any provision of these Terms of Service is
                determined to be unlawful, void or unenforceable, such provision
                shall nonetheless be enforceable to the fullest extent permitted
                by applicable law, and the unenforceable portion shall be deemed
                to be severed from these Terms of Service, such determination
                shall not affect the validity and enforceability of any other
                remaining provisions.
            </p>
            <br />

            <h2 className="underline">SECTION 16 - TERMINATION</h2>
            <br />
            <p>
                The obligations and liabilities of the parties incurred prior to
                the termination date shall survive the termination of this
                agreement for all purposes.
            </p>
            <br />
            <p>
                These Terms of Service are effective unless and until terminated
                by either you or us.
            </p>
            <br />
            <p>
                You may terminate these Terms of Service at any time by
                notifying us that you no longer wish to use our Services, or
                when you cease using our site.
            </p>
            <br />
            <p>
                If in our sole judgment you fail, or we suspect that you have
                failed, to comply with any term or provision of these Terms of
                Service, we also may terminate this agreement at any time
                without notice and you will remain liable for all amounts due up
                to and including the date of termination; and/or accordingly may
                deny you access to our Services (or any part thereof).
            </p>
            <br />
            <h2 className="underline">SECTION 17 - ENTIRE AGREEMENT</h2>
            <br />
            <p>
                The failure of us to exercise or enforce any right or provision
                of these Terms of Service shall not constitute a waiver of such
                right or provision.
            </p>
            <br />
            <p>
                These Terms of Service and any policies or operating rules
                posted by us on this site or in respect to The Service
                constitutes the entire agreement and understanding between you
                and us and govern your use of the Service, superseding any prior
                or contemporaneous agreements, communications and proposals,
                whether oral or written, between you and us (including, but not
                limited to, any prior versions of the Terms of Service).
            </p>
            <br />
            <p>
                Any ambiguities in the interpretation of these Terms of Service
                shall not be construed against the drafting party.
            </p>
            <br />

            <h2 className="underline">SECTION 18 - GOVERNING LAW</h2>
            <br />
            <p>
                These Terms of Service and any separate agreements whereby we
                provide you Services shall be governed by and construed in
                accordance with the laws of Rwanda.
            </p>
            <br />

            <h2 className="underline">
                SECTION 19 - CHANGES TO TERMS OF SERVICE
            </h2>
            <br />
            <p>
                You can review the most current version of the Terms of Service
                at any time at this page.
            </p>
            <br />
            <p>
                We reserve the right, at our sole discretion, to update, change
                or replace any part of these Terms of Service by posting updates
                and changes to our website. It is your responsibility to check
                our website periodically for changes. Your continued use of or
                access to our website or the Service following the posting of
                any changes to these Terms of Service constitutes acceptance of
                those changes.
            </p>
            <br />

            <h2 className="underline">SECTION 20 - CONTACT INFORMATION</h2>
            <br />
            <p>
                Questions about the Terms of Service should be sent to us at
                info@wakafitness.com.
            </p>
            <br />

            <h2 className="underline">WAKA Privacy Policy</h2>
            <br />
            <p>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from (the “Site”).
            </p>
            <br />
            <p>PERSONAL INFORMATION WE COLLECT</p>
            <br />
            <p>
                When you visit the Site, we automatically collect certain
                information about your device, including information about your
                web browser, IP address, time zone, and some of the cookies that
                are installed on your device. Additionally, as you browse the
                Site, we collect information about the individual web pages or
                products that you view, what websites or search terms referred
                you to the Site, and information about how you interact with the
                Site. We refer to this automatically-collected information as
                “Device Information.”
            </p>
            <br />
            <p>
                We collect Device Information using the following technologies:
            </p>
            <br />
            <ul className=" my-5 mt-0 flex list-disc flex-col pl-5">
                <li className="mt-1">
                    “Cookies” are data files that are placed on your device or
                    computer and often include an anonymous unique identifier.
                    For more information about cookies, and how to disable
                    cookies, visit{' '}
                    <a
                        className="text-blue-600"
                        href="http://www.allaboutcookies.org"
                    >
                        http://www.allaboutcookies.org
                    </a>
                    .
                </li>
                <li className="mt-1">
                    “Log files” track actions occurring on the Site, and collect
                    data including your IP address, browser type, Internet
                    service provider, referring/exit pages, and date/time
                    stamps.
                </li>
                <li className="mt-1">
                    “Web beacons,” “tags,” and “pixels” are electronic files
                    used to record information about how you browse the Site.
                </li>
            </ul>
            <br />
            <p>
                Additionally when you make a purchase or attempt to make a
                purchase through the Site, we collect certain information from
                you, including your name, billing address, shipping address,
                payment information (including credit card numbers, email
                address, and phone number. We refer to this information as
                “Order Information.”
            </p>
            <br />
            <p>
                When we talk about “Personal Information” in this Privacy
                Policy, we are talking both about Device Information and Order
                Information.
            </p>
            <br />
            <p>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
            <br />
            <p>
                We use the Order Information that we collect generally to
                fulfill any orders placed through the Site (including processing
                your payment information, arranging for shipping, and providing
                you with invoices and/or order confirmations). Additionally, we
                use this Order Information to:
            </p>
            <br />
            <ul className="list-disk flex flex-col pl-3">
                <li className="mt-1">Communicate with you;</li>
                <li className="mt-1">
                    Screen our orders for potential risk or fraud;
                </li>
                <li className="mt-1">
                    When in line with the preferences you have shared with us,
                    provide you with information or advertising relating to our
                    products or services;
                </li>
                <li className="mt-1">
                    To provide you with our Services, such as providing you
                    access to your membership account and sending you
                    administrative information relating to our terms and
                    policies;
                </li>
                <li className="mt-1">
                    To periodically send you newsletters, e-mails or similar
                    communications featuring sales and promotions, news about
                    new Planet Fitness products and services, requests for your
                    feedback, or other information we think may be of interest
                    to you, in accordance with local law;
                </li>
                <li className="mt-1">
                    To respond to any customer service inquiries or requests;
                </li>
                <li className="mt-1">
                    We may use workout and machine usage data collected through
                    our Services to provide you with tailored products or
                    service offerings, to conduct member usage and market
                    analysis, and to compile aggregated data sets for use in our
                    marketing and business objectives;
                </li>
                <li className="mt-1">
                    To promote and administer corporate partnerships in
                    connection with the Services;
                </li>
                <li className="mt-1">
                    We may share your data with our partners so that they may
                    provide you with tailored products or service offerings.
                </li>
            </ul>
            <br />
            <p>
                We use the Device Information that we collect to help us screen
                for potential risk and fraud (in particular, your IP address),
                and more generally to improve and optimize our Site (for
                example, by generating analytics about how our customers browse
                and interact with the Site, and to assess the success of our
                marketing and advertising campaigns).
            </p>
            <br />
            <p>
                We share your Personal Information with third parties to help us
                use your Personal Information, as described above. We also use
                Google Analytics to help us understand how our customers use the
                Site--you can read more about how Google uses your Personal
                Information here:{' '}
                <a
                    className="text-blue-600"
                    href="https://www.google.com/intl/en/policies/privacy/"
                >
                    https://www.google.com/intl/en/policies/privacy/
                </a>
                . You can also opt-out of Google Analytics here:{' '}
                <a
                    className="text-blue-600"
                    href="https://tools.google.com/dlpage/gaoptout"
                >
                    https://tools.google.com/dlpage/gaoptout
                </a>
                .
            </p>
            <br />
            <p>
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
            </p>
            <br />
            <p>
                <strong>BEHAVIOURAL ADVERTISING</strong>
            </p>
            <br />
            <p>
                As described above, we use your Personal Information to provide
                you with targeted advertisements or marketing communications we
                believe may be of interest to you. For more information about
                how targeted advertising works, you can visit the Network
                Advertising Initiative’s (“NAI”) educational page at{' '}
                <a
                    className="text-blue-600"
                    href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                >
                    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                </a>
                .
            </p>
            <br />
            <p>You can opt out of targeted advertising by:</p>
            <br />
            <ul>
                <li>
                    Facebook -{' '}
                    <a href="https://www.facebook.com/settings/?tab=ads">
                        https://www.facebook.com/settings/?tab=ads
                    </a>
                </li>
                <li>
                    Google -{' '}
                    <a href="https://www.google.com/settings/ads/anonymous">
                        https://www.google.com/settings/ads/anonymous
                    </a>
                </li>
                <li>
                    Bing -{' '}
                    <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                        https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                    </a>
                </li>
            </ul>
            <br />
            <p>
                Additionally, you can opt out of some of these services by
                visiting the Digital Advertising Alliance’s opt-out portal at:{' '}
                <a href="http://optout.aboutads.info/">
                    http://optout.aboutads.info/
                </a>
                .
            </p>
            <br />
            <p>
                <strong>DO NOT TRACK</strong>
            </p>
            <br />
            <p>
                Please note that we do not alter our Site’s data collection and
                use practices when we see a Do Not Track signal from your
                browser.
            </p>
            <br />
            <p>
                <strong>YOUR RIGHTS</strong>
            </p>
            <br />
            <p>
                If you are a European resident, you have the right to access
                personal information we hold about you and to ask that your
                personal information be corrected, updated, or deleted. If you
                would like to exercise this right, please contact us through the
                contact information below.
            </p>
            <br />
            <p>
                Additionally, if you are a European resident we note that we are
                processing your information in order to fulfill contracts we
                might have with you (for example if you make an order through
                the Site), or otherwise to pursue our legitimate business
                interests listed above. Additionally, please note that your
                information will be transferred outside of Europe, including to
                Canada and the United States.
            </p>
            <br />
            <p>
                <strong>DATA RETENTION</strong>
            </p>
            <br />
            <p>
                When you place an order through the Site, we will maintain your
                Order Information for our records unless and until you ask us to
                delete this information.
            </p>
            <br />
            <p>
                <strong>MINORS</strong>
            </p>
            <br />
            <p>
                The Site is not intended for individuals under the age of
                eighteen (18) years of age and minors wishing to join WAKA
                should have the explicit permission of their parents/guardians.
            </p>
            <br />
            <p>
                <strong>CHANGES</strong>
            </p>
            <br />
            <p>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
            </p>
            <br />
            <p>
                <strong>CONTACT US</strong>
            </p>
            <br />
            <p>
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by e-mail at info@wakafitness.com or by mail using
                the details provided below:
            </p>
            <br />
            <address>KG 674 St 18, Kigali, Rwanda</address>
        </div>
    );
}
