import { LocationType } from '~/app/types/location';
import { exportToCSV, format } from '~/core/utils';

export const exportLocation = (userData: LocationType[]): string =>
    exportToCSV<LocationType>([
        {
            label: 'Location Name',
            selector: row => row.name,
        },
        {
            label: 'District',
            selector: row => row.district,
        },
        {
            label: 'Address',
            selector: row => row.address,
        },
        {
            label: 'Street',
            selector: row => row.street,
        },
        {
            label: 'Contact',
            selector: row => row.contact,
        },
        {
            label: 'Created at',
            selector: row => format.humanDate(row.created_at),
        },
        {
            label: 'Updated at',
            selector: row => row.updated_at,
        }
    ], userData
    );