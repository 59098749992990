import {
    Button,
    DataTable,
    Modal,
    Spinner,
    TableColumn,
} from '~/components/elements';
import { format } from '~/core/utils';
import UserImage from '~/assets/images/user.png';
import { TransactionFilterPayload, TransactionType } from '~/app/types';
import { toast } from 'react-toastify';
import { PaginationType } from '~/app/types/pagination';
import React, { useContext, useEffect, useState } from 'react';
import { get_transactions } from '~/api';
import { exportTransaction } from '~/core/helper/csv/transaction';
import { ExportContext, ExportContextType } from './ExportContextProvider';
import { useLocation } from 'react-router-dom';
import {
    DashFilterComponent,
    handleExportExcel,
} from '../common/DashAdminFilter';
import { z } from 'zod';
import RetryComponent from '../common/RetryComponent';
import { useDisclosure } from '~/core/hooks';
import { DocumentReportIcon } from '@heroicons/react/outline';

export type TransactionSuccessReturnType = {
    currentPage: number;
    lastPage: number;
    list: TransactionType[];
    nextPage: number;
    previousPage: number;
    status: 'success' | 'failure';
    total: number;
};

export const DashAdminTransaction = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filters, setFilters] = useState<TransactionFilterPayload>({
        location_id: '',
        package_id: '',
        user_id: '',
        from: '',
        to: '',
        status: '',
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selected, setSelected] = useState({ id: '' });

    const location = useLocation();

    const query = location.search;

    const { setExportData } = useContext<ExportContextType>(ExportContext);

    const [transactions, setTransactions] =
        useState<PaginationType<TransactionType> | null>();
    const [keyword, setKeyword] = useState('');

    const handleGetTransactions = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<TransactionType> =
                await get_transactions(keyword, query);
            setTransactions(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumn<TransactionType>[] = [
        {
            title: 'Date Time',
            cell: row =>
                format.humanDateTime(
                    row.updated_at
                ),
        },
        {
            title: 'Member',
            cell: row => (
                <>
                    <p className="capitalize">
                        {row.user &&
                            `${row.user.firstname} ${row.user.lastname}`}
                    </p>
                    <p>{row.user && row.user.email}</p>
                </>
            ),
        },
        {
            title: 'Telephone',
            cell: row => (
                <>
                    <p>{row.user && row.user.phone}</p>
                </>
            ),
        },
        {
            title: 'Package',
            cell: row => (
                <p className="capitalize">{`${row.subscription?.package?.name ?? 'N/A'}`}</p>
            ),
        },
        {
            title: 'Payment Method',
            cell: row => (
                <p className="text-center capitalize">{row.payment_mode}</p>
            ),
        },
        {
            title: 'Amount',
            cell: row => format.currenyWithAmount(row.amount),
        },
        {
            title: 'Cron Date',
            cell: row => (<>
                <div className="">{row.cron_date ? format.humanDateTime(row.cron_date) : (
                    <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                )}</div>
            </>),
        },
        {
            title: 'Status',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' && (
                        <span className="block w-full rounded-full bg-green-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                    {row.status.toLowerCase() !== 'success' && (
                        <span className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                            {row.status}
                        </span>
                    )}
                </>
            ),
        },
        {
            title: 'ACTION',
            cell: row => (
                <>
                    {row.status.toLowerCase() === 'success' &&
                        row.status.toLowerCase() === 'success' && (
                            <span className="block w-full rounded-full bg-slate-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white">
                                N/A
                            </span>
                        )}
                    {row.status.toLowerCase() !== 'success' && (
                        <button
                            onClick={() => {
                                onOpen();
                                setSelected({
                                    id: row.id,
                                });
                            }}
                            className="block w-full rounded-full bg-red-600 py-0.5 px-2 text-center text-[12px] font-semibold uppercase text-white"
                        >
                            Retry
                        </button>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        handleGetTransactions();
    }, [query, keyword]);

    useEffect(() => {
        if (transactions?.list) {
            setExportData(exportTransaction(transactions?.list ?? []));
        }
    }, [transactions]);

    return (
        <>
            <div className="float-right flex flex-wrap justify-between  gap-4 whitespace-nowrap px-6 py-4">
                <DashFilterComponent
                    filters={filters}
                    onFilter={(payload: TransactionFilterPayload) => {
                        setFilters(payload);
                    }}
                />
                <Button
                    variant="btn-secondary"
                    className="px-3"
                    title="Export to excel"
                    onClick={() =>
                        handleExportExcel(
                            transactions?.list.map(item => {
                                return {
                                    Member: `${item.user?.firstname} ${item.user?.lastname}`,
                                    DateTime: item.created_at,
                                    Telephone: item.user?.phone,
                                    Package: item.subscription ? item.subscription.package.name : null,
                                    PaymentMethod: item.payment_mode.toString(),
                                    Amount: item.amount,
                                    Status: item.status,
                                };
                            }) ?? []
                        )
                    }
                >
                    <DocumentReportIcon className="icon-md" />
                </Button>
                <input
                    type="search"
                    onChange={e => setKeyword(e.target.value)}
                    placeholder="Search..."
                    // value={keyword}
                    className="rounded-md border-b border-slate-700 bg-transparent p-2 text-light focus:border-slate-700 focus:outline-none focus:ring-0"
                />
            </div>
            <DataTable
                columns={columns}
                data={transactions?.list ?? []}
                isLoading={isLoading}
                total={transactions?.total ?? 0}
                nextPage={transactions?.nextPage ?? 0}
                lastPage={transactions?.lastPage ?? 0}
                currentPage={transactions?.currentPage ?? 0}
                previousPage={transactions?.previousPage ?? 0}
            />
            <div className="">
                <Modal
                    title="Retry"
                    size="text-light md:w-1/2 max-w-[500px] w-11/12 mt-20"
                    onClose={onClose}
                    isOpen={isOpen}
                >
                    <RetryComponent id={selected.id} />
                </Modal>
            </div>
        </>
    );
};
