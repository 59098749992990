import { CardType } from '~/app/types';

type DashMemberCardProps = {
    card: CardType;
    loading: boolean;
};

export const DashMemberCard: React.FC<DashMemberCardProps> = ({
    card,
    loading,
}) => {
    return (
        <>
            {loading && (
                <div className="h-52 w-[430px] rounded-md bg-light/10"></div>
            )}
            {!loading && (
                <div className="hover flex h-44 w-full flex-col justify-between rounded-xl bg-light/10 p-4 shadow-lg md:h-52 md:p-8">
                    <div className="flex items-center justify-between text-xl font-medium text-white md:text-2xl">
                        <h2 className="capitalize">
                            {card?.data.sourceOfFunds?.provided.card.nameOnCard}
                        </h2>
                        <h2 className="uppercase italic">
                            {card?.data.sourceOfFunds?.provided.card.brand}
                        </h2>
                    </div>

                    <div className="flex items-end justify-between">
                        <div className="text-lg text-light">
                            <div className="flex gap-4">
                                <p>
                                    {`${card.data.sourceOfFunds?.provided.card.fundingMethod} CARD`}
                                </p>

                                <p>
                                    {card.data.sourceOfFunds?.provided.card.expiry.slice(
                                        0,
                                        2
                                    )}
                                    {' / '}
                                    {card.data.sourceOfFunds?.provided.card.expiry.slice(
                                        -2
                                    )}
                                </p>
                            </div>

                            <div className="mt-2 flex items-center gap-2 md:gap-3">
                                <p>
                                    {card.data.sourceOfFunds?.provided.card.number.slice(
                                        0,
                                        6
                                    )}
                                </p>

                                {[1, 2].map((_, key1) => (
                                    <div key={key1} className="flex gap-1">
                                        {[1, 2, 3].map((__, key2) => (
                                            <span
                                                key={key2}
                                                className="block h-2.5 w-2.5 rounded-full bg-light md:h-3 md:w-3"
                                            ></span>
                                        ))}
                                    </div>
                                ))}

                                <p>
                                    {card.data.sourceOfFunds?.provided.card.number.slice(
                                        -4
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="relative right-[-20px] h-10 w-10 rounded-full border-2 border-white bg-white md:right-[-24px] md:h-12 md:w-12"></div>
                            <div className="h-10 w-10 rounded-full border-2 border-white md:h-12 md:w-12"></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
