import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage } from '~/core/utils';

type UseAuthenticationProps = {
    isAuth?: boolean;
};

export const useAuthentication = (props: UseAuthenticationProps) => {
    const { isAuth = false } = props;

    const token = storage.getToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth && !token) return navigate('/');
        if (!isAuth && token) return navigate('/account');
    }, [token, isAuth, navigate]);
};
