import { AxiosErrorHandler, CustomError } from '~/core/utils/response';
import { PRIVATE_API } from '../axios';

export const reset_password = async (email: string) => {
    try {
        const request = await PRIVATE_API.post('/users/reset-password', {
            email,
        });
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
