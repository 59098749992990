import { useState } from 'react';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { retry_transaction, retry_transaction_pos } from '~/api';
import { Spinner } from '~/components/elements';
import mtn from '../../../../assets/images/mtn.png';

export default function RetryComponent(props: { id: string }) {
    const { id } = props;
    const [openMomo, setOpenMomo] = useState(false);
    const [openPos, setOpenPos] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [retryTransactionLoading, setRetryTransactionLoading] = useState<{
        loading: boolean;
        type: 'card' | 'momo' | 'pos';
    }>({
        loading: false,
        type: 'card',
    });
    const [phone, setPhone] = useState('2507');
    const [pos, setPos] = useState('');
    const inputSchema = z.string().refine(value => {
        return value.startsWith('2507') && value.length === 12;
    });
    const retryTransaction = async (
        id: string,
        paymentMode: 'momo' | 'card' | 'pos',
        phoneNumber?: string
    ) => {
        if (paymentMode === 'momo') {
            const validationResult = inputSchema.safeParse(phoneNumber);
            if (!validationResult.success) {
                setError('Phone number must be in form of 2507xxxxxx');
                return;
            }
        }
        try {
            setRetryTransactionLoading({
                loading: true,
                type: paymentMode,
            });
            await retry_transaction({ id, phoneNumber, paymentMode });
            toast.success('Transaction successfully retried');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setRetryTransactionLoading({
                loading: false,
                type: paymentMode,
            });
        }
    };
    const retryTransaction_pos = async (
        id: string,
        paymentMode: 'pos',
        pos_reference_id: string
    ) => {
        try {
            setRetryTransactionLoading({
                loading: true,
                type: paymentMode,
            });
            await retry_transaction_pos({ id, paymentMode, pos_reference_id });
            toast.success('Transaction successfully retried');
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setRetryTransactionLoading({
                loading: false,
                type: paymentMode,
            });
        }
    };
    return (
        <div className="flex flex-col gap-2">
            <button
                onClick={() => setOpenMomo(prev => !prev)}
                className="flex items-center justify-center gap-2 bg-yellow-400 px-2 py-1 text-dark"
            >
                {/* <img src={mtn} className="w-10" alt="" /> */}
                MoMo
            </button>

            <div
                className={`${
                    openMomo ? 'flex' : 'hidden'
                } my-2  flex-wrap items-center justify-center gap-2 border-y border-slate-700 py-2`}
            >
                <p>Enter MoMo Phone number</p>
                <div className="flex flex-col">
                    <div className="flex flex-wrap justify-center gap-2">
                        <input
                            type="text"
                            id="phone"
                            maxLength={12}
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                        />
                        <button
                            onClick={() => retryTransaction(id, 'momo', phone)}
                            className=" rounded-md bg-red-600 px-2 py-1"
                        >
                            {retryTransactionLoading.loading &&
                            retryTransactionLoading.type === 'momo' ? (
                                <Spinner size="sm" />
                            ) : (
                                'Retry'
                            )}
                        </button>
                    </div>
                    {error ? (
                        <p className="w-full py-[5px] text-center text-sm text-red-600">
                            {error}
                        </p>
                    ) : null}
                </div>
            </div>
            <button
                onClick={() => {
                    retryTransaction(id, 'card');
                }}
                className="flex items-center justify-center bg-blue-400 px-2 py-1 text-dark"
            >
                {retryTransactionLoading.loading === true &&
                retryTransactionLoading.type === 'card' ? (
                    <Spinner size="sm" />
                ) : (
                    'Card'
                )}
            </button>
            <button
                onClick={() => setOpenPos(prev => !prev)}
                className="bg-green-400 px-2 py-1 text-dark"
            >
                POS
            </button>

            <div
                className={`${
                    openPos ? 'flex' : 'hidden'
                } my-2  flex-wrap items-center justify-center gap-2 border-y border-slate-700 py-2`}
            >
                <p>Reference Number :</p>
                <div className="flex flex-col">
                    <div className="flex flex-wrap justify-center gap-2">
                        <input
                            type="text"
                            id="phone"
                            maxLength={12}
                            value={pos}
                            onChange={e => setPos(e.target.value)}
                            className="border-b border-slate-600 bg-transparent py-1 px-2 focus:outline-none "
                        />
                        <button
                            onClick={() => retryTransaction_pos(id, 'pos', pos)}
                            className=" rounded-md bg-red-600 px-2 py-1"
                        >
                            {retryTransactionLoading.loading === true &&
                            retryTransactionLoading.type === 'pos' ? (
                                <Spinner size="sm" />
                            ) : (
                                'Retry'
                            )}
                        </button>
                    </div>
                    {error ? (
                        <p className="w-full py-[5px] text-center text-sm text-red-600">
                            {error}
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
