import { LockClosedIcon, PhoneIcon } from '@heroicons/react/outline';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { getPaymentDetails, pay } from '~/api/payment';
import { SubscriptionType } from '~/app/types/subscription';
import { Button, Form } from '~/components/elements';
import { format } from '~/core/utils';

const schema = z.object({
    phoneNumber: z
        .string()
        .refine(
            value => {
                return value.startsWith('2507');
            },
            { message: 'Phone number must start with 2507' }
        )
        .refine(
            value => {
                return value.length === 12;
            },
            { message: 'Phone number must be 12 characters long' }
        ),
});

type PaymentPayload = {
    phoneNumber: string;
};

type Payment = {
    data: {
        order: {
            amount: number;
            status: string;
            currency: string;
        };
    } | null;
    subscription: SubscriptionType;
    amount: number;
};

const useGetData = () => {
    const [data, setData] = useState<Payment | undefined>();
    const [loading, setLoading] = useState(false);
    const handleGetData = async (id: string) => {
        try {
            setLoading(true);
            const details = await getPaymentDetails(id);
            console.log(details);
            setData(details);
        } catch (error: any) {
            toast.error(error.message || 'Error');
        } finally {
            setLoading(false);
        }
    };

    return {
        data,
        handleGetData,
        loading,
    };
};

const usePay = () => {
    const [isloading, setLoading] = useState(false);
    const handlePay = async (id: string, phoneNumber: string) => {
        try {
            setLoading(true);
            await pay(id, phoneNumber).then(() => {
                toast.success('Payment Initiated.');
            });
        } catch (error: any) {
            toast.error(error.message || 'Error');
        } finally {
            setLoading(false);
        }
    };

    return {
        handlePay,
        isloading,
    };
};

export default function Payment() {
    const { id } = useParams<string>();
    const [paid, setPaid] = useState(false);
    const { handlePay, isloading } = usePay();
    const { loading, handleGetData, data } = useGetData();
    useEffect(() => {
        handleGetData(id || '');
    }, [id]);
    const makePayment = (e: any) => {
        console.log('first');
        handlePay(id || '', e).then(() => {
            setPaid(true);
        });
    };
    return (
        <div className="mb-10 flex flex-col items-end justify-center px-3 pt-10 text-light md:px-10">
            <Link
                to="/"
                className="mb-5 flex w-28 flex-row items-center justify-center"
            >
                <ArrowCircleLeftIcon className="mr-1 w-5" />
                Return
            </Link>
            {loading ? (
                <p className=" w-full max-w-[1440px] p-2 text-center">
                    One moment Please.
                </p>
            ) : (
                <div className="flex w-full max-w-[1440px] flex-col items-center justify-center overflow-hidden rounded-md border border-slate-700 p-5">
                    {/* <div className="absolute -mt-40 h-20 w-20 rounded-full bg-light"></div> */}
                    <div className=" py-2 text-lg font-bold capitalize md:text-3xl">
                        SECURE PAYMENT
                    </div>
                    <div className="my-2 flex w-full flex-col gap-2 border-y border-slate-700 py-3">
                        <div className="w-full border border-slate-800 p-2">
                            <h1 className="my-3 text-2xl font-semibold">
                                Location
                            </h1>
                            <div className="">
                                <p>
                                    {data?.subscription.location.name.toUpperCase()}{' '}
                                </p>
                                <p>
                                    {data?.subscription.location.district}
                                    {', '}
                                    {data?.subscription.location.address}
                                </p>
                                <p className="">
                                    {data?.subscription.location.contact}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 sm:flex-row">
                            <div className="w-full border border-slate-800 p-2">
                                <h1 className="my-3 text-2xl font-semibold">
                                    Package
                                </h1>
                                <div className="flex flex-col gap-1">
                                    <div className="flex gap-2">
                                        <p className="font-bold">
                                            Package Name:
                                        </p>{' '}
                                        {data?.subscription.package.name}
                                    </div>
                                    <div className="flex gap-2">
                                        <p className="font-bold">Price:</p>{' '}
                                        {data?.subscription.package.price &&
                                            format.currenyWithAmount(
                                                data?.subscription.package.price
                                            )}
                                    </div>
                                    <div className="flex gap-2">
                                        <p className="font-bold">
                                            Joining Fee:
                                        </p>{' '}
                                        {data?.subscription.package
                                            .joining_fee &&
                                            format.currenyWithAmount(
                                                data?.subscription.package
                                                    .joining_fee
                                            )}
                                    </div>
                                    <div className="flex gap-2">
                                        <p className="font-bold">
                                            Number of months:
                                        </p>{' '}
                                        {data?.subscription.package
                                            .recurringStatus === 'true'
                                            ? `${
                                                  data.subscription.package
                                                      .numberOfMonths
                                              } Month${
                                                  data.subscription.package
                                                      .numberOfMonths &&
                                                  data.subscription.package
                                                      .numberOfMonths > 1
                                                      ? 's'
                                                      : ''
                                              }`
                                            : 'Not Recurring'}
                                    </div>
                                </div>
                            </div>
                            {data?.data && (
                                <div className="w-full border border-slate-800 p-2">
                                    <h1 className="my-3 text-2xl font-semibold">
                                        Order
                                    </h1>
                                    <div className="flex flex-col gap-1">
                                        <div className="flex gap-2">
                                            <p className="font-bold">Amount:</p>{' '}
                                            {data?.data &&
                                                data?.data.order!.amount}{' '}
                                            {data?.data &&
                                                data?.data.order!.currency}
                                        </div>
                                        <div className="flex gap-2">
                                            <p className="font-bold">Status:</p>{' '}
                                            {data?.data &&
                                                data?.data.order!.status}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {paid ? (
                        <div className="py-3">
                            <div className="flex max-w-[300px] flex-col justify-center border border-slate-700 p-3 text-lg capitalize">
                                Transaction initiated, use{' '}
                                <p className="text-yellow-600">*182*7*1#</p> to
                                complete transaction
                            </div>
                        </div>
                    ) : (
                        <div className=" w-full justify-end">
                            <Form<PaymentPayload, typeof schema>
                                schema={schema}
                                onSubmit={makePayment}
                            >
                                {({ register, formState }) => (
                                    <div className="flex w-full flex-col items-center justify-start rounded-md border border-slate-800 p-3 py-3">
                                        <label
                                            htmlFor="phone"
                                            className="w-full py-2 text-left"
                                        >
                                            Phone number:
                                        </label>
                                        <input
                                            placeholder="Enter Your Phone Number"
                                            {...register('phoneNumber')}
                                            className="h-12 w-full rounded border border-slate-700 bg-transparent p-2 focus:outline-none"
                                            disabled={false}
                                        />
                                        {formState.errors.phoneNumber && (
                                            <p className="mt-1 w-full text-left text-sm text-red-600">
                                                {
                                                    formState.errors.phoneNumber
                                                        .message
                                                }
                                            </p>
                                        )}
                                        <Button
                                            isLoading={isloading}
                                            className="my-2 mt-5"
                                            type="submit"
                                        >
                                            <LockClosedIcon className="mr-1 w-5" />{' '}
                                            PAY NOW
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
