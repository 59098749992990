import { EyeIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get_all_users_search } from '~/api';
import { UserType } from '~/app/types';
import { PaginationType } from '~/app/types/pagination';
import { StoreType } from '~/app/types/redux';
import UserImage from '~/assets/images/user.png';
import { FrontDeskDisplayUser } from '~/components/pages/account/frontdesk/modals';

export default function Search() {
    const { role } = useSelector((state: StoreType) => state.user.userAccount);
    if (role === 'member') {
        return null;
    }

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [users, setUsers] = useState<PaginationType<UserType> | null>();

    const [selectedUser, setSelectedUser] = useState<UserType>();
    const [keyword, setKeyword] = useState('');
    const [filteredData, setFilteredData] = useState<UserType[]>([]);
    const [total, setTotal] = useState<number>();
    const [isDisplayUserModalOpen, setIsDisplayUserModalOpen] =
        useState<boolean>(false);

    const handleGetUsers = async () => {
        try {
            setIsLoading(true);
            const data: PaginationType<UserType> = await get_all_users_search(
                `?pageNumber=1&pageSize=${total ? total : 0}`,
                ''
            );
            setUsers(data);
        } catch (error) {
            // toast.error('Error getting users');
        } finally {
            setIsLoading(false);
        }
    };

    const filterData = () => {
        const filtered = users?.list.filter(user =>
            Object.values(user).some(field => {
                if (typeof field === 'string') {
                    return field.toLowerCase().includes(keyword.toLowerCase());
                }
                return false;
            })
        );
        setFilteredData(filtered || []);
    };

    useEffect(() => {
        handleGetUsers();
    }, []);

    return (
        <div className="relative inline-block w-full text-light md:w-3/5">
            <input
                type="search"
                onChange={e => {
                    setKeyword(e.target.value);
                    filterData();
                    setTotal(users?.total);
                }}
                placeholder="Search users..."
                className={`w-full rounded-md border-b ${
                    keyword !== '' ? 'rounded-b-none' : ''
                }${
                    filteredData.length === 0 ? 'rounded-b' : ''
                } border-slate-700 bg-transparent p-2 focus:border-slate-700 focus:outline-none focus:ring-0`}
            />
            {keyword != '' ? (
                <div className="border-gray-300 scrollbar-hidden absolute top-full left-0 max-h-80 w-full overflow-y-auto rounded-md rounded-t-none border border-slate-700 bg-slate-900 ">
                    <div className="flex w-full flex-col px-2">
                        {filteredData.map((user, index) => (
                            <div
                                key={index}
                                className="mb-3 flex w-full flex-row items-center justify-between border-b border-slate-700 p-2"
                            >
                                <div className="w-[10%]">
                                    <img
                                        src={UserImage}
                                        alt="profile"
                                        className="h-10 w-10 rounded-full"
                                    ></img>
                                </div>
                                <div className="flex w-[80%] flex-col justify-center px-2">
                                    <p>
                                        {user.firstname} {user.lastname}
                                    </p>
                                    <p className="text-sm font-light">
                                        {user.email}
                                    </p>
                                </div>
                                <div className="flex w-[10%] items-center justify-center">
                                    <button
                                        onClick={() => {
                                            setSelectedUser(user);
                                            setIsDisplayUserModalOpen(true);
                                        }}
                                        className="flex w-2/5 items-center justify-center"
                                    >
                                        <EyeIcon />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            <FrontDeskDisplayUser
                isOpen={isDisplayUserModalOpen}
                onClose={() => setIsDisplayUserModalOpen(false)}
                selectedUser={selectedUser}
            />
        </div>
    );
}
