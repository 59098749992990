/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as z from 'zod';
import { AuthRegisterPayload, CardPayload, CardType } from '~/app/types';
import { SubscriptionType } from '~/app/types/subscription';
import {
    Button,
    Form,
    InputField,
    Modal,
    Spinner,
} from '~/components/elements';
import config from '~/config';
import { CommonContext, CommonContextType } from '~/core/context';
import { format, storage } from '~/core/utils';
import { create_subscription } from '~/api';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { CustomError } from '~/core/utils/response';
import { useDisclosure } from '~/core/hooks';
import TermsAndConditions from '~/core/utils/terms and contions';
import { MoMoPayload } from '~/app/types/momo';
import mtn from '../../../assets/images/mtn.png';
import { CreditCardIcon } from '@heroicons/react/outline';
import TermsAndConditionsSpecial from '~/core/utils/terms_and_conditions_special';

type PaymentContainerProps = {
    payload: AuthRegisterPayload;
};

const schema = z.object({
    name_on_card: z.string().min(1, 'Name on card is required'),
    card_number: z.string().min(1, 'Card number is required'),
    expiry_month: z.string().min(1, 'Required'),
    expiry_year: z.string().min(1, 'Required'),
    security_code: z.string().min(1, 'Required'),
});

const momo_schema = z.object({
    phoneNumber: z.string().refine(
        value => {
            return value.startsWith('2507') && value.length === 12;
        },
        { message: 'Phone number must start with 2507' }
    ),
});

export const PaymentContainer = (props: PaymentContainerProps) => {
    const { payload } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [terms_agreed, setTerms_agreed] = useState(false);

    const { packages, locations, cards } =
        useContext<CommonContextType>(CommonContext);

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const [totalJ, setTotalJ] = useState();
    const [ld, setLd] = useState(false);
    const [isJoining, setIsJoining] = useState(true);

    const getTotal = async () => {
        try {
            setLd(true);
            const val = await axios.get(`${config.BASE_URI}/api/v1/subscription/joining/${payload.package?.id}`, {
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                },
            });
            setTotalJ(val.data.joining_fee);
            setIsJoining(val.data.hasJoiningFee);
        } catch (error: any) {
            throw new CustomError(error);
        } finally {
            setLd(false);
        }
    };

    useEffect(() => {
        getTotal();
    }, []);

    const createCard = async (card: CardPayload): Promise<CardType | any> => {
        try {
            return await axios.post(`${config.BASE_URI}/api/v1/cards`, card, {
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                },
            });
        } catch (error: any) {
            throw new CustomError(error);
        }
    };

    const createSubscription = async (
        card_id: string
    ): Promise<SubscriptionType | any> => {
        try {
            return await axios.post(
                `${config.BASE_URI}/api/v1/subscription`,
                {
                    card_id,
                    package_id: payload.package?.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${storage.getToken()}`,
                    },
                }
            );
        } catch (error: any) {
            throw new CustomError(error);
        }
    };

    const createMomoSubscription = async (
        phoneNumber: string
    ): Promise<SubscriptionType | any> => {
        try {
            return await axios.post(
                `${config.BASE_URI}/api/v1/subscription`,
                {
                    phoneNumber,
                    payment_method: 'momo',
                    location_id: payload.location,
                    package_id: payload.package,
                },
                {
                    headers: {
                        Authorization: `Bearer ${storage.getToken()}`,
                    },
                }
            );
        } catch (error: any) {
            throw new CustomError(error);
        }
    };

    const handleSubmit = async (payload: CardPayload) => {
        if (terms_agreed) {
            try {
                setLoading(true);
                console.log(payload);
                const requestCreateCard = await createCard(payload);
                const createdCardData = await requestCreateCard.data.card;
                await createSubscription(await createdCardData.id);
                toast.success('Subscription successfully initiated');
                window.location.reload();
            } catch (error: any) {
                toast.error(error.response.response.data.message);
            } finally {
                setLoading(false);
            }
        } else {
            toast.error('Terms not agreed.');
        }
    };

    const handleMomoSubmit = async (e: { phoneNumber: string }) => {
        if (terms_agreed) {
            try {
                setLoading(true);
                await createMomoSubscription(e.phoneNumber);
                toast.success('Subscription successfully initiated');
                // window.location.reload();
            } catch (error: any) {
                toast.error(error.response.response.data.message);
            } finally {
                setLoading(false);
            }
        } else {
            toast.error('Terms not agreed.');
        }
    };

    const handleCreateSubscription = async () => {
        try {
            setLoading(true);
            const package_id = payload.package?.id ?? '';
            const location_id = payload.location;
            const card_id = cards[0].id;
            await create_subscription({
                package_id,
                location_id,
                card_id,
            });
            toast.success('Subscription successfully initiated');
            navigate('/account/member/subscriptions');
        } catch (error: any) {
            toast.error(error.response.error);
        } finally {
            setLoading(false);
        }
    };

    const joiningFee = packages.find(
        p => p.id === payload.package?.id
    )?.joining_fee;

    const pricingFee = payload.package?.price;

    const isRecurring = payload.package?.recurringStatus;

    const label = payload.package?.name;

    const location = locations.find(l => l.id === payload.location)?.name;

    const [tab, setTab] = useState(1);

    return (
        <div>
            <p className="text-center text-2xl font-bold uppercase text-white">
                Payment Details
            </p>

            <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="bg-dark-light p-4 md:p-8">
                    <p className="text-xl font-medium text-white">
                        12 Month Commitment | Due Today
                    </p>

                    <table className="mt-4">
                        <tbody>
                            <tr>
                                <td className="py-2 text-lg font-medium text-white">
                                    Package
                                </td>
                                <td className="py-2 px-4 text-lg font-normal text-gray">
                                    {label}
                                </td>
                            </tr>

                            {isJoining ? <tr>
                                <td className="py-2 text-lg font-medium text-white">
                                    Joining Fee
                                </td>

                                <td className="py-2 px-4 text-lg font-normal text-gray">
                                    {format.currenyWithAmount(joiningFee ?? 0)}{' '}
                                    / Just Once
                                </td>
                            </tr> : null}

                            <tr>
                                <td className="py-2 text-lg font-medium text-white">
                                    Monthly Fee
                                </td>

                                <td className="py-2 px-4 text-lg font-normal text-gray">
                                    {format.currenyWithAmount(pricingFee ?? 0)}{' '}
                                    {isRecurring == 'true' ? '/ Month' : ''}
                                </td>
                            </tr>

                            <tr>
                                <td className="py-2 text-lg font-medium text-white">
                                    First Due Date
                                </td>

                                <td className="py-2 px-4 text-lg font-normal text-gray">
                                    {format.humanDate(new Date().toString())}
                                </td>
                            </tr>

                            <tr>
                                <td className="py-2 text-lg font-medium text-white">
                                    Total Due Today
                                </td>

                                <td className="py-2 px-4 text-lg font-normal text-gray">
                                    {ld ? <Spinner variant="light" /> : format.currenyWithAmount(
                                        totalJ ??  (pricingFee ?? 0)
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* {userAccount.email && cards.length > 0 && (
                    <div className="bg-dark-light p-4 md:p-8">
                        <Swiper slidesPerView={1}>
                            {cards.map(card => (
                                <SwiperSlide key={card.id}>
                                    <DashMemberCard
                                        card={card}
                                        loading={false}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="mt-4 flex items-center justify-center">
                            <span className="flex h-4 w-4 items-center rounded-full bg-white p-[2px]">
                                <span className="h-full w-full rounded-full bg-red-500"></span>
                            </span>
                        </div>
                        <Button
                            variant="btn-danger"
                            className="mt-8 w-full font-semibold"
                            isLoading={loading}
                            onClick={handleCreateSubscription}
                        >
                            {loading ? (
                                <Spinner size="sm" />
                            ) : (
                                'Continue To Pay'
                            )}
                        </Button>
                    </div>
                )} */}
                <div className="bg-slate-800">
                    {/*<div className="flex w-full flex-row justify-between border-b border-slate-600 text-light">*/}
                    {/*    <button*/}
                    {/*        onClick={() => setTab(1)}*/}
                    {/*        className={` ${*/}
                    {/*            tab === 1 ? 'bg-blue-600' : 'hover:bg-slate-700'*/}
                    {/*        } w-1/2 p-2 text-center `}*/}
                    {/*    >*/}
                    {/*        Card*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className="">
                        {tab === 0 && (
                            <div className="text-light">
                                <Form<MoMoPayload, typeof momo_schema>
                                    schema={momo_schema}
                                    onSubmit={handleMomoSubmit}
                                    className="flex min-h-[490px] flex-col justify-start bg-dark-light p-4 pt-20 md:p-8"
                                >
                                    {({ register, formState }) => (
                                        <>
                                            <div className="flex flex-col gap-4">
                                                <InputField
                                                    type="text"
                                                    label="Phone Number"
                                                    placeholder="2507"
                                                    error={
                                                        formState.errors
                                                            .phoneNumber
                                                    }
                                                    registration={register(
                                                        'phoneNumber'
                                                    )}
                                                    className="h-12"
                                                    isLoading={loading}
                                                />
                                                <div className="flex flex-row items-center justify-center gap-4 border-y border-slate-700 py-3 text-light">
                                                    <input
                                                        onChange={e =>
                                                            setTerms_agreed(
                                                                e.target.checked
                                                            )
                                                        }
                                                        className="focus:ring-0 active:outline-none"
                                                        type="checkbox"
                                                    />
                                                    <p className="flex gap-2 text-sm">
                                                        I agree to these
                                                        <span
                                                            onClick={onOpen}
                                                            className="cursor-pointer text-blue-600 underline"
                                                        >
                                                            Terms and Conditions
                                                        </span>
                                                        <Modal
                                                            title="Terms and Conditions"
                                                            size="w-[800px] text-white m-2"
                                                            isOpen={isOpen}
                                                            onClose={onClose}
                                                        >
                                                            <div className="scrollbar-hidden max-h-[400px] overflow-scroll border-y border-slate-700 py-5">
                                                                {/*<TermsAndConditions />*/}
                                                                {/*<TermsAndConditionsSpecial />*/}
                                                            </div>
                                                        </Modal>
                                                    </p>
                                                </div>
                                            </div>

                                            <button
                                                type="submit"
                                                className="mt-8 flex w-full items-center justify-center rounded-md bg-yellow-500 py-2 font-semibold text-dark"
                                            >
                                                <img
                                                    src={mtn}
                                                    alt="."
                                                    className="  w-16"
                                                />
                                                {loading ? (
                                                    <Spinner size="sm" />
                                                ) : (
                                                    'Continue To Pay'
                                                )}
                                            </button>
                                        </>
                                    )}
                                </Form>
                            </div>
                        )}
                        {tab === 1 && (
                            <div className="">
                                <Form<CardPayload, typeof schema>
                                    schema={schema}
                                    onSubmit={handleSubmit}
                                    className="bg-dark-light p-4 md:p-8"
                                >
                                    {({ register, formState }) => (
                                        <>
                                            <div className="flex flex-col gap-4">
                                                <InputField
                                                    label="Card Holder Name"
                                                    placeholder="Name on Card"
                                                    error={
                                                        formState.errors
                                                            .name_on_card
                                                    }
                                                    registration={register(
                                                        'name_on_card'
                                                    )}
                                                    className="h-12"
                                                    isLoading={loading}
                                                />

                                                <InputField
                                                    type="number"
                                                    label="Card Number"
                                                    placeholder="1234-1234-1234-1234"
                                                    error={
                                                        formState.errors
                                                            .card_number
                                                    }
                                                    registration={register(
                                                        'card_number'
                                                    )}
                                                    className="h-12"
                                                    isLoading={loading}
                                                />

                                                <div className="flex flex-col items-center gap-4 text-sm md:flex-row">
                                                    <InputField
                                                        type="number"
                                                        label="Expire Month"
                                                        placeholder="01"
                                                        error={
                                                            formState.errors
                                                                .expiry_month
                                                        }
                                                        registration={register(
                                                            'expiry_month'
                                                        )}
                                                        className="h-12"
                                                        isLoading={loading}
                                                    />

                                                    <InputField
                                                        type="number"
                                                        label="Expire Year"
                                                        placeholder="22"
                                                        error={
                                                            formState.errors
                                                                .expiry_year
                                                        }
                                                        registration={register(
                                                            'expiry_year'
                                                        )}
                                                        className="h-12"
                                                        isLoading={loading}
                                                    />

                                                    <InputField
                                                        type="number"
                                                        label="Security Code"
                                                        placeholder="123"
                                                        error={
                                                            formState.errors
                                                                .security_code
                                                        }
                                                        registration={register(
                                                            'security_code'
                                                        )}
                                                        className="h-12"
                                                        isLoading={loading}
                                                    />
                                                </div>
                                                <div className="flex flex-row items-center justify-center gap-4 border-y border-slate-700 py-3 text-light">
                                                    <input
                                                        onChange={e =>
                                                            setTerms_agreed(
                                                                e.target.checked
                                                            )
                                                        }
                                                        className="focus:ring-0 active:outline-none"
                                                        type="checkbox"
                                                    />
                                                    <p className="flex gap-2 text-sm">
                                                        I agree to these
                                                        <span
                                                            onClick={onOpen}
                                                            className="cursor-pointer text-blue-600 underline"
                                                        >
                                                            Terms and Conditions
                                                        </span>
                                                        <Modal
                                                            title="Terms and Conditions"
                                                            size="w-[600px] text-white m-2"
                                                            isOpen={isOpen}
                                                            onClose={onClose}
                                                        >
                                                            <div className="scrollbar-hidden max-h-[400px] overflow-scroll border-y border-slate-700 py-5">
                                                                {payload.package?.id === '44636dbf-aac9-4a50-8401-2f7aacf1d163' ?
                                                                <TermsAndConditionsSpecial /> :
                                                                <TermsAndConditions />

                                                                }

                                                            </div>
                                                        </Modal>
                                                    </p>
                                                </div>
                                            </div>

                                            <Button
                                                type="submit"
                                                variant="btn-danger"
                                                className="mt-8 w-full p-2 font-semibold"
                                                isLoading={loading}
                                            >
                                                <CreditCardIcon className=" w-14" />
                                                {loading ? (
                                                    <Spinner size="sm" />
                                                ) : (
                                                    'Continue To Pay'
                                                )}
                                            </Button>
                                        </>
                                    )}
                                </Form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
